import React from 'react'

import MessageList from '../../Messages/MessageList'
import { TRADES } from '../../../constants/chatRooms'
import { setPageTitle } from '../../../utils/utilities'

const TradeHistory = () => {
  setPageTitle('Trade History')

  return (
    <div className="flex h-full w-full flex-col">
      <MessageList channel={TRADES} modView />
    </div>
  )
}

export default TradeHistory
