import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons'
import { putUser } from '../../../utils/APIs/users'
import { AuthenticationContext } from '../../../context/authenticationContext'

const RenameForm = ({ user, turnstile }) => {
  const { updateUser } = useContext(AuthenticationContext)
  const [displayName, setDisplayName] = useState(
    user.userService.profile.name || ''
  )
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError(null)
    const response = await putUser(
      user._id,
      'userService.profile.name',
      displayName.trim()
    )
    if (response && response.status === 200) {
      updateUser(response.data)
    } else {
      if (response?.data?.errors[0]?.message) {
        setError(response.data.errors[0].message)
      }
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      className="flex flex-col gap-2 shadow-sm"
    >
      {turnstile ? (
        <p className="text-sm mb-1">
          Your display name for the chat room will be unique to you. It must be
          between 3 and 16 characters long.
        </p>
      ) : (
        <>
          <p>
            Your display name for the chat room will be unique to you. It must
            be between 3 and 16 characters long. We recommend starting with your
            first name and last initial. If you have a common name, try adding
            numbers.
          </p>
          <p>Example display names for “John Smith”:</p>
          <ul className="list-disc pl-6">
            <li>JohnS</li>
            <li>JSmith</li>
            <li>John123</li>
          </ul>
        </>
      )}
      <div className="relative flex flex-grow items-stretch focus-within:z-10 gap-2">
        <span className="sr-only">Set Display Name</span>
        <input
          aria-label="Rename Form"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
          className="input block w-full"
          placeholder="Display Name"
          required
        />
        <button
          type="submit"
          aria-label="Submit message"
          className="primary-button relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0"
        >
          Set Name
        </button>
      </div>
      {error && (
        <div className="flex items-center bg-red-500/20 border border-red-500 px-4 mt-2 mr-auto rounded py-2 font-medium text-red-500 transition-all text-sm">
          <FontAwesomeIcon
            className="mr-3 h-5 cursor-pointer"
            icon={faExclamation}
          />
          <div>{error}</div>
        </div>
      )}
    </form>
  )
}

RenameForm.propTypes = {
  user: PropTypes.object.isRequired
}

export default RenameForm
