import axios from 'axios'
import { API } from '../ENV'

export const postPoll = async (prompt, choices, endTime) => {
  try {
    const response = await axios.post(`${API}/poll`, {
      prompt,
      choices,
      endTime
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const postPollVote = async ({ pollId, choiceId, userId }) => {
  try {
    const response = await axios.post(`${API}/poll/vote`, {
      pollId,
      choiceId,
      userId
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPolls = async () => {
  try {
    const response = await axios.get(`${API}/poll`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPoll = async (id) => {
  try {
    const response = await axios.get(`${API}/poll/${id}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getActivePoll = async () => {
  try {
    const response = await axios.get(`${API}/poll/active`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const putPoll = async (id, isOpen, isArchived) => {
  try {
    const response = await axios.put(`${API}/poll`, {
      id,
      isOpen,
      isArchived
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const deletePoll = async (id) => {
  try {
    const response = await axios.delete(`${API}/poll/${id}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
