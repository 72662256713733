import React from 'react'
import PropTypes from 'prop-types'
import ForgotPasswordLink from '../../Utilities/ForgotPasswordLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons'

const Error = ({ message, extras, passwordReset, phoneNumbers }) => {
  return (
    <div className="mb-2 flex flex-col">
      <div className="p-1.5 bg-red-50 dark:bg-red-950 rounded-lg shadow-md border border-red-500">
        <p className="text-red-800 dark:text-red-100 font-medium text-sm">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="text-red-500 mr-2"
          />
          {message}{' '}
          {extras?.forgot && (
            <ForgotPasswordLink passwordReset={passwordReset} />
          )}{' '}
          {extras?.support && (
            <CustomerServiceNumbers
              support01={phoneNumbers.support01}
              support02={phoneNumbers.support02}
            />
          )}{' '}
          {extras?.sales && (
            <SalesTeamNumbers
              sales01={phoneNumbers.sales01}
              sales02={phoneNumbers.sales02}
            />
          )}{' '}
        </p>
      </div>
    </div>
  )
}

Error.propTypes = {
  message: PropTypes.string,
  extras: PropTypes.object,
  passwordReset: PropTypes.string,
  phoneNumbers: PropTypes.object
}

const CustomerServiceNumbers = ({ support01, support02 }) => {
  return (
    <>
      Please try again, and if you continue to see this message give us a ring
      at{' '}
      <a className="link" href={`tel:${scrubPhoneNumber(support01)}`}>
        {support01}
      </a>
      {support02 !== '' && (
        <>
          {' '}
          or{' '}
          <a className="link" href={`tel:${scrubPhoneNumber(support02)}`}>
            {support02}
          </a>
        </>
      )}
      .
    </>
  )
}

CustomerServiceNumbers.propTypes = {
  support01: PropTypes.string,
  support02: PropTypes.string
}

const SalesTeamNumbers = ({ sales01, sales02 }) => {
  return (
    <>
      If you would like to learn more, please give our VIP Member Services Team
      a call at{' '}
      <a className="link" href={`tel:${scrubPhoneNumber(sales01)}`}>
        {sales01}
      </a>
      {sales02 !== '' && (
        <>
          {' '}
          or{' '}
          <a className="link" href={`tel:${scrubPhoneNumber(sales02)}`}>
            {sales02}
          </a>
        </>
      )}
      .
    </>
  )
}

SalesTeamNumbers.propTypes = {
  sales01: PropTypes.string,
  sales02: PropTypes.string
}

const scrubPhoneNumber = (number) => {
  return number.replace(/\D/g, '')
}

export default Error
