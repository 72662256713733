import React, { useContext, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import './styles.css'
import { toast } from 'react-toastify'
import { setPageTitle } from '../../../../utils/utilities'

const AudioHelpGuide = () => {
  setPageTitle('Content | Audio Help Guide')
  const { [SETTINGS.AUDIO_HELP_GUIDE.key]: theGuide, updateSetting } =
    useContext(SiteConfigContext)
  const [tmpGuide, setTmpGuide] = useState(theGuide)

  const handleSubmit = async (e) => {
    e.preventDefault()
    await updateSetting(SETTINGS.AUDIO_HELP_GUIDE, tmpGuide)
    toast.success('Audio Help Guide content saved!')
  }

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      className="flex flex-col gap-2"
    >
      <ReactQuill
        id="terms"
        name="terms"
        style={{ height: '420px', width: '100%' }}
        aria-label="Audio Help Guide content"
        placeholder="Audio Help Guide content"
        defaultValue={theGuide}
        onChange={setTmpGuide}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean']
          ]
        }}
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'link'
        ]}
      />
      <button
        type="submit"
        aria-label="Submit message"
        className="primary-button w-fit"
      >
        Save
      </button>
    </form>
  )
}

export default AudioHelpGuide
