import React from 'react'
import { Switch } from '@headlessui/react'
import PropTypes from 'prop-types'

const ToggleSwitchLocal = ({ setting, value, label, color, setter }) => {
  const changeSetting = (setting, value) => {
    localStorage.setItem(setting, JSON.stringify(value))
    setter(value)
  }

  return (
    <Switch.Group>
      <div className="flex items-center">
        <Switch.Label className="mr-4 dark:text-neutral-100">
          {label}
        </Switch.Label>
        <Switch
          checked={value}
          onChange={() => changeSetting(setting, !value)}
          className={`${value ? `bg-${color}-500` : 'bg-neutral-500'}
          relative ml-auto inline-flex h-2 w-11 items-center rounded-full`}
        >
          <span className="sr-only">{label}</span>
          <span
            className={`${value ? 'translate-x-6' : 'translate-x-1'}
          inline-flex h-4 w-4 rounded-full border border-neutral-900/40 dark:border-none bg-neutral-100 transition-all justify-center items-center`}
          >
            <span
              className={`${
                value ? `bg-${color}-500` : 'bg-neutral-500'
              } h-2.5 w-2.5 rounded-full z-10`}
            />
          </span>
        </Switch>
      </div>
    </Switch.Group>
  )
}

ToggleSwitchLocal.propTypes = {
  setting: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  setter: PropTypes.func.isRequired
}

export default ToggleSwitchLocal
