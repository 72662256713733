import React, { useContext, Fragment, useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  Transition
} from '@headlessui/react'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronDown,
  faEllipsis,
  faBuilding,
  faGear,
  faChartColumn,
  faEye,
  faRightFromBracket
} from '@fortawesome/pro-solid-svg-icons'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import * as SETTINGS from '../../../constants/settings'
import * as FEATURES from '../../../constants/features'
import * as PRODUCTS from '../../../constants/products'

const NavMenu = () => {
  const { user, logout } = useContext(AuthenticationContext)
  const {
    freeEnabled,
    productsService,
    [SETTINGS.FEATURES.key]: enabledFeatures
  } = useContext(SiteConfigContext)
  const tradesEnabled = useMemo(
    () => enabledFeatures && enabledFeatures[FEATURES.TRADES],
    [enabledFeatures]
  )

  const hubURL = productsService[PRODUCTS.HUB].urls.website

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="">
        <MenuButton className="header-button navmenu-button">
          <span className="hidden md:block">Menu</span>
          <FontAwesomeIcon
            icon={faEllipsis}
            className="md:hidden m-0 md:mr-1 md:ml-2 h-5 w-5"
            aria-hidden="true"
          />
          <FontAwesomeIcon
            icon={faChevronDown}
            className="hidden md:block m-0 md:mr-1 md:ml-2 h-5 w-5"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/20 dark:ring-black/50 focus:outline-none dark:bg-neutral-900 ">
          <div className="py-1">
            <MenuItem>
              {({ focus }) => (
                <Link to="/">
                  <button
                    className={`block w-full p-2 text-sm transition-all ${
                      focus
                        ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100'
                        : 'text-neutral-700 dark:text-neutral-100'
                    }`}
                  >
                    Livestream
                    <FontAwesomeIcon icon={faEye} className="ml-2 h-3 w-3" />
                  </button>
                </Link>
              )}
            </MenuItem>
            <MenuItem>
              {({ focus }) => (
                <Link to="/hq" target="_blank" rel="noopener noreferrer">
                  <button
                    className={`block w-full p-2 text-sm transition-all ${
                      focus
                        ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100'
                        : 'text-neutral-700 dark:text-neutral-100'
                    }`}
                  >
                    Headquarters
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="ml-2 h-3 w-3"
                    />
                  </button>
                </Link>
              )}
            </MenuItem>
            {tradesEnabled && (
              <MenuItem>
                {({ focus }) => (
                  <Link
                    to="/portfolio"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className={`block w-full p-2 text-sm transition-all ${
                        focus
                          ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100'
                          : 'text-neutral-700 dark:text-neutral-100'
                      }`}
                    >
                      Portfolio
                      <FontAwesomeIcon
                        icon={faChartColumn}
                        className="ml-2 h-3 w-3"
                      />
                    </button>
                  </Link>
                )}
              </MenuItem>
            )}
            {hasStaffPrivilege(user) && (
              <MenuItem>
                {({ focus }) => (
                  <Link
                    to="/mod/settings"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className={`block w-full p-2 text-sm transition-all ${
                        focus
                          ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100'
                          : 'text-neutral-700 dark:text-neutral-100'
                      }`}
                    >
                      Admin Dashboard
                      <FontAwesomeIcon icon={faGear} className="ml-2 h-3 w-3" />
                    </button>
                  </Link>
                )}
              </MenuItem>
            )}
            {freeEnabled && (
              <Fragment>
                <MenuItem>
                  {({ focus }) => (
                    <Link
                      to={`${hubURL}/privacy-policy`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className={`block w-full p-2 text-sm transition-all ${
                          focus
                            ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100'
                            : 'text-neutral-700 dark:text-neutral-100'
                        }`}
                      >
                        Privacy Policy
                      </button>
                    </Link>
                  )}
                </MenuItem>
                <MenuItem>
                  {({ focus }) => (
                    <Link
                      to={`${hubURL}/privacy-policy`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button
                        className={`block w-full p-2 text-sm transition-all ${
                          focus
                            ? 'bg-neutral-100 text-neutral-900 dark:bg-neutral-800 dark:text-neutral-100'
                            : 'text-neutral-700 dark:text-neutral-100'
                        }`}
                      >
                        Do Not Sell or Share My Personal Information
                      </button>
                    </Link>
                  )}
                </MenuItem>
              </Fragment>
            )}
            {user && (
              <MenuItem>
                {({ focus }) => (
                  <button
                    className={`block w-full p-2 text-sm transition-all ${
                      focus
                        ? 'bg-neutral-100 text-red-900 dark:bg-neutral-800 dark:text-red-500'
                        : 'text-red-700 dark:text-red-500'
                    }`}
                    onClick={() => logout()}
                  >
                    Sign Out
                    <FontAwesomeIcon
                      icon={faRightFromBracket}
                      className="ml-2 h-3 w-3"
                    />
                  </button>
                )}
              </MenuItem>
            )}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default NavMenu
