import React from 'react'
import PropTypes from 'prop-types'
import Trade from '../Trade'
import { toast } from 'react-toastify'
import { alertTrade } from '../../../utils/APIs/trades'
import Modal from '../../Utilities/Modal'

const ResendModal = ({ open, setOpen, message }) => {
  const handleResend = async () => {
    const response = await alertTrade({ id: message._id })
    if (response && response.status === 201) {
      toast.success('Trade notification resent.')
    }
    toast.success('Trade notification resent.')
    setOpen(false)
  }

  return (
    <Modal
      title="Resend Trade Notification"
      open={open}
      setOpen={setOpen}
      confirmFunction={handleResend}
    >
      <Trade message={message} />
    </Modal>
  )
}

ResendModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired
}

export default ResendModal
