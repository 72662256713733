import React, { useContext } from 'react'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SocketContext } from '../../../context/socketContext'
import { Link, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons'
import NavMenu from '../NavMenu'
import Logo from '../../../graphics/Logo'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import ViewerCount from './ViewerCount'
import SuiteButton from './SuiteButton'
import CustomButton from './CustomButton'
import AnnoucementFeed from './AnnouncementFeed/AnnouncementFeed'

const Header = () => {
  const { socketError } = useContext(SocketContext)
  const { user } = useContext(AuthenticationContext)
  const history = useLocation()

  return (
    <div id="header" className="flex flex-col">
      {socketError && (
        <div className="absolute z-50 w-full pointer-events-none">
          <div className="flex justify start md:justify-center">
            <div className="w-fit ml-0 md:mx-auto p-2 border-r border-b md:border-x shadow-md bg-red-400 border-red-500  shadow-red-600 dark:bg-red-700 dark:border-red-800 dark:shadow-red-900">
              <p>
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  className="mr-2"
                  aria-hidden="true"
                />
                {socketError}
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="flex w-full h-14 items-center px-2.5">
        <div className="h-8 md:h-11 my-auto mr-auto w-fit max-w-56">
          {history.pathname !== '/' ? (
            <Link to="/">
              <Logo />
            </Link>
          ) : (
            <Logo />
          )}
        </div>

        <div className="flex items-center gap-2 my-auto ml-auto">
          {hasStaffPrivilege(user) && <ViewerCount />}
          <AnnoucementFeed />
          <SuiteButton />
          <CustomButton />
          <NavMenu />
        </div>
      </div>
    </div>
  )
}

export default Header
