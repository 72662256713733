import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faThumbTack } from '@fortawesome/pro-solid-svg-icons'
import Message from '../../Messages/Message'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { hasStaffPrivilege } from '../../../utils/RequireRole'

const PinnedMessage = ({ message, unPin, toggle }) => {
  const { user } = useContext(AuthenticationContext)

  return (
    <div className="flex flex-col bg-white dark:bg-neutral-900">
      <div className="pb-1 text-sm bg-amber-100 dark:bg-amber-900">
        <div className="mb-1 flex items-center justify-between">
          {hasStaffPrivilege(user) && (
            <button onClick={() => unPin()} className="flex items-center">
              <div className="flex items-center gap-1 py-0.5 px-1">
                <FontAwesomeIcon
                  className="mt-1 mx-1 h-4 transition-all hover:text-red-600 active:text-red-800 dark:text-white -rotate-45"
                  icon={faThumbTack}
                />
                <p className="text-sm italic">Pinned Message</p>
              </div>
            </button>
          )}
          {!hasStaffPrivilege(user) && (
            <div className="flex items-center gap-1 py-0.5 px-1">
              <FontAwesomeIcon
                className="mt-1 mx-1 h-4 transition-all dark:text-white -rotate-45"
                icon={faThumbTack}
              />
              <p className="text-sm italic">Pinned Message</p>
            </div>
          )}
          <button onClick={() => toggle(false)} className="flex items-center">
            <FontAwesomeIcon
              className="h-4 mr-1 transition-all hover:text-red-600 active:text-red-800 dark:text-white"
              icon={faTimesCircle}
            />
          </button>
        </div>
        <Message message={message} emphasize={false} />
      </div>
    </div>
  )
}

PinnedMessage.propTypes = {
  message: PropTypes.object.isRequired,
  unPin: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired
}

export default PinnedMessage
