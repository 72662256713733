import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { AuthenticationContext } from '../../../context/authenticationContext'
import LogoIcon from '../../../graphics/LogoIcon'
import PostDate from '../../Utilities/PostDate'
import TextWrapper from '../../Utilities/TextWrapper'

export default function PrivateMessage({ modView, message }) {
  const { user } = useContext(AuthenticationContext)

  const loggedInUserIsSender =
    message.user.userService._id === user.userService._id

  const hasToUser = message.toUser !== undefined

  let messageFloat = 'float-left'

  if (modView) {
    if (hasToUser) {
      messageFloat = 'float-right'
    }
  } else {
    if (loggedInUserIsSender) {
      messageFloat = 'float-right'
    }
  }

  return (
    <div className="block w-full">
      <div className={`m-2 w-3/4 ${messageFloat}`}>
        <div className="flex flex-row">
          {!message.toUser ? (
            <strong>
              <p
                className={`text-${
                  !modView
                    ? user.userService.profile.color
                    : message.user.userService.profile.color
                } mr-2`}
              >
                {!modView
                  ? user.userService.profile.name
                  : message.user.userService.profile.name}
              </p>
            </strong>
          ) : (
            <strong>
              <p className={`text-neutral-700 dark:text-neutral-300 mr-2`}>
                Moderator ({message.user.userService.profile.name}){' '}
                {modView && (
                  <span
                    className={`text-${message.user.userService.profile.color} mr-2`}
                  >
                    ({message.user.userService.profile.name})
                  </span>
                )}
              </p>
            </strong>
          )}
        </div>
        <div
          className={`p-2 rounded-md ${
            messageFloat === 'float-left'
              ? 'border border-brand-primary-200 dark:border-brand-primary-900 bg-brand-primary-50 dark:bg-brand-primary-900/15'
              : 'border border-brand-secondary-200 dark:border-brand-secondary-900 bg-brand-secondary-50 dark:bg-brand-secondary-900/15'
          }`}
        >
          <TextWrapper
            text={message.message}
            renderLinks={hasToUser}
            element="p"
          />
        </div>
        <span className="text-xs text-gray-700 dark:text-gray-300">
          {message.itemNumber ? (
            <>
              From:{' '}
              <LogoIcon product={message.itemNumber} size="sm" displayName />
              {user.userService.settings.showTimestamps && (
                <>
                  {' at '}
                  <PostDate date={message.postDate} source="privateMessage" />
                </>
              )}
            </>
          ) : (
            <PostDate date={message.postDate} source="privateMessage" />
          )}
        </span>
      </div>
    </div>
  )
}

// Define PropTypes for the component
PrivateMessage.propTypes = {
  modView: PropTypes.bool, // modView should be a boolean
  message: PropTypes.shape({
    user: PropTypes.shape({
      userService: PropTypes.shape({
        _id: PropTypes.string, // Assuming _id is a string
        profile: PropTypes.shape({
          color: PropTypes.string, // Assuming color is a string
          name: PropTypes.string // Assuming name is a string
        })
      })
    }),
    toUser: PropTypes.object, // toUser should be a boolean
    postDate: PropTypes.string, // Assuming postDate is a Date
    message: PropTypes.string, // message should be a string
    itemNumber: PropTypes.string // itemNumber should be a string
  })
}
