import React, { useContext, useState } from 'react'
import CustomBadge from '../../../graphics/Badge/CustomBadge'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { AuthenticationContext } from '../../../context/authenticationContext'

export default function CustomBadges({ user }) {
  const { getSubType } = useContext(AuthenticationContext)
  const { [SETTINGS.MEMBERSHIPS.key]: memberships } =
    useContext(SiteConfigContext)
  const [subType] = useState(getSubType(user))

  return (
    <>
      {memberships.main.map((bdg, index) => {
        return (
          subType === bdg.subType &&
          bdg.badge && <CustomBadge key={index} badge={bdg.badge} />
        )
      })}
    </>
  )
}
