import React from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan, faPencil } from '@fortawesome/pro-solid-svg-icons'
import { toast } from 'react-toastify'

const CurrentStreamers = ({
  streamers,
  handleActivate,
  handleEdit,
  handleDelete
}) => {
  return (
    <div className="flex flex-col mb-2">
      {streamers.map((strmr, index) => {
        return (
          <div key={index}>
            <div className="flex mb-2">
              <ActiveButton
                index={index}
                active={strmr.active}
                handleActivate={handleActivate}
              >
                <div className="mr-2 md:mr-4 shrink-0">
                  <img
                    className="streamer-pic border-neutral-600"
                    src={strmr.photo}
                    alt={strmr.name}
                  />
                </div>
                <div className="flex flex-col justify-center gap-2">
                  <h2 className="flex gap-2">{strmr.name}</h2>
                  <div className="flex gap-2">
                    {handleEdit && (
                      <EditButton handleEdit={() => handleEdit(index)} />
                    )}
                    {handleDelete && (
                      <DeleteButton handleDelete={() => handleDelete(index)} />
                    )}
                  </div>
                </div>
              </ActiveButton>
            </div>
          </div>
        )
      })}
    </div>
  )
}

const DeleteButton = ({ handleDelete }) => {
  return (
    <button
      className="flex items-center justify-center rounded-full bg-red-500 dark:bg-red-700 text-white w-6 h-6 md:w-8 md:h-8"
      type="button"
      onClick={handleDelete}
    >
      <FontAwesomeIcon icon={faTrashCan} className="w-3 h-3 md:w-4 md:h-4" />
    </button>
  )
}

DeleteButton.propTypes = {
  handleDelete: PropTypes.func
}

const EditButton = ({ handleEdit }) => {
  return (
    <button
      className="flex items-center justify-center rounded-full bg-blue-500 dark:bg-blue-700 text-white w-6 h-6 md:w-8 md:h-8"
      type="button"
      onClick={handleEdit}
    >
      <FontAwesomeIcon icon={faPencil} className="w-3 h-3 md:w-4 md:h-4" />
    </button>
  )
}

EditButton.propTypes = {
  handleEdit: PropTypes.func
}

const handleSelect = (index, handleActivate) => {
  handleActivate(index)
  toast.success('Active Streamer updated.')
}

const ActiveButton = ({ children, index, active, handleActivate }) => {
  if (handleActivate) {
    return (
      <button
        className={`flex w-full ${
          active ? 'streamer-button' : 'neutral-button'
        }`}
        type="button"
        onClick={() => handleSelect(index, handleActivate)}
      >
        {children}
      </button>
    )
  } else {
    return <>{children}</>
  }
}

ActiveButton.propTypes = {
  children: PropTypes.any.isRequired,
  index: PropTypes.number,
  active: PropTypes.bool,
  handleActivate: PropTypes.func
}

CurrentStreamers.propTypes = {
  streamers: PropTypes.array,
  handleActivate: PropTypes.func,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func
}

export default CurrentStreamers
