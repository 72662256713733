import React, { useContext } from 'react'
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition
} from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell, faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import UnreadCountBadge from '../../../Utilities/UnreadCountBadge/UnreadCountBadge'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import * as SETTINGS from '../../../../constants/settings'
import { LatestAnnouncement } from '../../../LatestAnnouncement'
import AnnouncementsList from '../../../Utilities/AnnouncementsList/AnnouncementsList'

export default function AnnoucementFeed() {
  const { [SETTINGS.SUITE_BUTTON.key]: suiteButton } =
    useContext(SiteConfigContext)

  const isSingleApp = process.env.REACT_APP_IS_SINGLE_APP || false

  return (
    <LatestAnnouncement>
      {({ latestAnnouncement, markLatestAnnouncementRead }) => {
        return (
          <Popover className="relative">
            <PopoverButton
              className="header-button custom-button"
              aria-label="Announcements"
              disabled={!latestAnnouncement}
              onClick={markLatestAnnouncementRead}
            >
              <FontAwesomeIcon icon={faBell} className="w-5 h-5" />
              <UnreadCountBadge />
            </PopoverButton>

            {latestAnnouncement && (
              <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
              >
                <PopoverPanel className="fixed right-0 w-full max-w-[720px] z-50 overflow-auto mt-2 md:mr-1 rounded-md origin-top-right bg-white shadow-lg ring-1 ring-black/20 dark:ring-black/50 focus:outline-none dark:bg-neutral-900">
                  <div className="w-full rounded-xl">
                    <div className="flex flex-col gap-2 p-2 w-full">
                      <h2 className="text-center">Latest Announcement</h2>
                      <AnnouncementsList announcements={[latestAnnouncement]} />
                      {isSingleApp === 'true' ? null : (
                        <a
                          className="neutral-button w-full flex justify-center items-center gap-2"
                          href={`${suiteButton.link}announcements`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View Announcement Feed
                          <FontAwesomeIcon
                            icon={faExternalLink}
                            className="w-3 h-3 text-black text-opacity-50 dark:text-white dark:text-opacity-50"
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </PopoverPanel>
              </Transition>
            )}
          </Popover>
        )
      }}
    </LatestAnnouncement>
  )
}
