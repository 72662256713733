import React, { useContext, useEffect, useState } from 'react'
import { setPageTitle } from '../../../../utils/utilities'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import OpenHouse from './OpenHouse'
import Free from './Free'
import { PRODUCT } from '../../../../constants/products'

const Product = () => {
  setPageTitle('Configuration | Product')
  const { productsService } = useContext(SiteConfigContext)
  const [current, setCurrent] = useState()

  useEffect(() => {
    if (productsService) {
      setCurrent(productsService[PRODUCT])
    }
  }, [productsService])

  const updateState = (data) => {
    setCurrent(data)
  }

  if (!current) {
    return null
  }

  return (
    <div className="flex flex-col gap-2 w-fit">
      <h2>Product</h2>
      <OpenHouse product={current} updateState={updateState} />
      <Free product={current} updateState={updateState} />
    </div>
  )
}

export default Product
