import React, { useContext } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'

const OneSignalAppID = () => {
  const { [SETTINGS.ONESIGNAL_APP_ID.key]: id, updateSetting } =
    useContext(SiteConfigContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = formData.get('id')
    await updateSetting(SETTINGS.ONESIGNAL_APP_ID, data)
    toast.success('OneSignal App ID saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>One Signal App ID</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <input
          id="id"
          name="id"
          defaultValue={id}
          className="input block w-full"
          placeholder="One Signal App ID"
        />
        <button type="submit" className="primary-button w-fit">
          Save
        </button>
      </form>
    </div>
  )
}

export default OneSignalAppID
