import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply, faThumbTack } from '@fortawesome/pro-solid-svg-icons'
import { MEMBERS } from '../../../constants/chatRooms'

import * as SETTINGS from '../../../constants/settings'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import { SiteConfigContext } from '../../../context/siteConfigContext'

const ActionPanel = ({ message, channel, handleReply }) => {
  const { updateSetting } = useContext(SiteConfigContext)
  const { user } = useContext(AuthenticationContext)

  const handlePin = async () => {
    await updateSetting(SETTINGS.PINNED_MESSAGE, message)
  }

  return (
    <div className="absolute right-2 top-0 divide-x divide-neutral-400 dark:divide-neutral-900">
      {user && channel === MEMBERS && (
        <button
          onClick={handleReply}
          className="rounded bg-neutral-300 px-1.5 text-sm font-bold text-neutral-800 transition-all hover:bg-neutral-400 dark:bg-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800"
        >
          <FontAwesomeIcon className="fa-fw" icon={faReply} />
        </button>
      )}
      {channel === MEMBERS && hasStaffPrivilege(user) && (
        <button
          onClick={handlePin}
          className="rounded bg-neutral-300 px-1.5 text-sm font-bold text-neutral-800 transition-all hover:bg-neutral-400 dark:bg-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800"
        >
          <FontAwesomeIcon className="-rotate-45 fa-fw" icon={faThumbTack} />
        </button>
      )}
    </div>
  )
}

ActionPanel.propTypes = {
  handleReply: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired
}

export default ActionPanel
