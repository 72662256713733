import axios from 'axios'
import { API } from '../ENV'

export const getBadges = async () => {
  try {
    const response = await axios.get(`${API}/badge`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const postBadge = async (badge) => {
  try {
    const response = await axios.post(`${API}/badge`, badge)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const putBadge = async (badge) => {
  try {
    const response = await axios.put(`${API}/badge`, badge)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const deleteBadge = async (id) => {
  try {
    const response = await axios.delete(`${API}/badge/${id}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
