import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons'
import { MODERATORS } from '../../../constants/chatRooms'

const LoadedAll = ({ channel }) => {
  return (
    <div>
      {channel === MODERATORS && (
        <div className="relative m-1 p-2 rounded bg-amber-400 dark:bg-amber-700 shadow">
          <p>
            <FontAwesomeIcon icon={faInfoCircle} className="mr-1.5" />
            <span className="font-medium">Welcome to the Mod Chat!</span>
          </p>
          <p>Reach out with any questions...</p>
        </div>
      )}
      <div className="mb-2 flex content-center justify-center bg-neutral-100 py-2 shadow dark:bg-neutral-800">
        <p>There are no previous messages.</p>
      </div>
    </div>
  )
}

LoadedAll.propTypes = {
  channel: PropTypes.string
}

export default LoadedAll
