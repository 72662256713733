export const initialState = {
  announcements: [],
  loading: true,
  error: null,
  loadMore: null,
  hasMore: true
}

export function reducer(state, action) {
  switch (action.type) {
    case 'LOADING':
      return {
        ...state,
        loading: true,
        error: null
      }
    case 'ERROR':
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case 'FETCH_ANNOUNCEMENT_EVENTS':
      return {
        ...state,
        loading: false,
        error: null,
        announcements: [...state.announcements, ...action.payload],
        loadMore:
          action.payload.length > 0
            ? action.payload[action.payload.length - 1]?.createdAt
            : null,
        hasMore: action.payload.length === 0 ? false : action.payload.length > 0
      }
    case 'ANNOUNCEMENT_POST':
      return {
        ...state,
        announcements: [action.payload, ...state.announcements]
      }
    case 'ANNOUNCEMENT_PUT':
      return {
        ...state,
        announcements: state.announcements.map((announcement) =>
          announcement._id === action.payload._id
            ? action.payload
            : announcement
        )
      }
    case 'ANNOUNCEMENT_DELETE':
      return {
        ...state,
        announcements: state.announcements.filter(
          (announcement) => announcement._id !== action.payload._id
        )
      }
    case 'ALL_READ':
      return {
        ...state,
        announcements: state.announcements.map((announcement) => ({
          ...announcement,
          read: new Date()
        }))
      }
    default:
      return state
  }
}
