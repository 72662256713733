import axios from 'axios'
import { API, OXFORCE } from '../ENV'

// Portfolio
export const getPortfolio = async (id) => {
  try {
    const response = await axios.get(`${API}/portfolio/${id}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

// Views
export const getViewCounts = async (type, start, end) => {
  try {
    const response = await axios.get(`${API}/views/${type}/${start}/${end}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

// Oxforce
export const postOxforce = async ({ data }) => {
  const formattedData = JSON.stringify({
    email: data.email,
    nickname: data.name,
    comment: data.body,
    pub: data.itemNumber,
    phone: data.phone
  })

  try {
    const token = await axios.get(`${OXFORCE}/csrf-token`)
    if (token) {
      const response = await axios
        .post(`${OXFORCE}/api/moalLead`, formattedData, {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': token.csrf_token
          }
        })
        .then(async function (response) {
          return response
        })
        .catch(function (error) {
          console.log('postOxforce', error.response.statusText)
          return error.response
        })

      return response
    }
  } catch (error) {
    console.log(error.response)
  }
}

// Font Awesome Icons
export const getCustomIcons = async (kit) => {
  try {
    const response = await axios.get(
      `${API}/serverFunctions/fontawesome/get-custom-icons/${kit}`
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
