import React, { useEffect, useState } from 'react'
import 'chartjs-adapter-moment'
import moment from 'moment'
import {
  Chart as ChartJS,
  TimeScale,
  TimeSeriesScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { getViewCounts } from '../../../../utils/APIs/miscellaneous'
import DateRangePicker from '../DateRangePicker'
import styles from '../Stats.module.css'

ChartJS.register(
  TimeScale,
  TimeSeriesScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
)

const ViewsPerHour = () => {
  const [views, setViews] = useState([])
  const [labels, setLabels] = useState([])
  const [data, setData] = useState(null)
  const [startDate, setStartDate] = useState(
    moment().subtract(2, 'days').toDate()
  )
  const [endDate, setEndDate] = useState(moment().toDate())
  const [scaleType, setScaleType] = useState('timeseries')

  const options = {
    responsive: true,
    elements: {
      line: {
        tension: 0.2 // smooth lines
      }
    },
    plugins: {
      legend: {
        position: 'top'
      },
      tooltip: {
        titleFont: {
          size: 18
        },
        bodyFont: {
          size: 24
        },
        padding: 12
      }
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'hour',
          tooltipFormat: 'M/D hA',
          displayFormats: {
            hour: 'M/D hA'
          }
        },
        ticks: {
          stepSize: 1
        }
      },
      y: {
        ticks: {
          stepSize: 100
        }
      }
    }
  }

  useEffect(() => {
    loadViews()
  }, [startDate, endDate])

  useEffect(() => {
    if (views.length > 0) {
      handleLabels()
    }
  }, [views])

  useEffect(() => {
    if (labels.length > 0) {
      handleData()
    }
  }, [labels, startDate, endDate])

  const loadViews = async () => {
    const response = await getViewCounts(
      'HOURLY',
      moment(startDate).toISOString(),
      moment(endDate).toISOString()
    )
    if (response && response.status === 200) {
      setViews(response.data)
    }
  }

  const handleLabels = () => {
    const newLabels = views.map((each) => each._id)
    setLabels(newLabels)
  }

  const handleData = () => {
    setData({
      labels,
      datasets: [
        {
          label: 'Live',
          data: views
            .filter((x) => x.isLive === true)
            .map((each) => {
              return {
                x: each._id,
                y: Math.round(each.maxCount)
              }
            }),
          borderColor: 'rgba(219, 0, 88, 1)',
          backgroundColor: 'rgba(219, 0, 88, 1)',
          pointStyle: false,
          fill: true
        },
        {
          label: 'Offline',
          data: views
            .filter((x) => x.isLive === false)
            .map((each) => {
              return {
                x: each._id,
                y: Math.round(each.maxCount)
              }
            }),
          borderColor: 'rgba(0, 126, 204, 1)',
          backgroundColor: 'rgba(0, 126, 204, 1)',
          pointStyle: false,
          fill: true
        }
      ]
    })
  }

  if (labels.length <= 0 || data === null) {
    return <div>Loading...</div>
  }

  return (
    <div className={styles.wrapper}>
      <h2>Viewers Per Hour</h2>

      <Line options={options} data={data} className="p-2" />
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        toggleScaleType={false}
        scaleType={scaleType}
        setScaleType={setScaleType}
        rangeType="datetime-local"
      />
    </div>
  )
}

export default ViewsPerHour
