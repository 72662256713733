module.exports = Object.freeze({
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  RESET: 'RESET',
  CHANGE_CHANNEL: 'CHANGE_CHANNEL',
  INITIAL_LOAD: 'INITIAL_LOAD',
  LOAD_MORE: 'LOAD_MORE',
  ADD_MESSAGE: 'ADD_MESSAGE',
  EDIT_MESSAGE: 'EDIT_MESSAGE',
  DELETE_MESSAGE: 'DELETE_MESSAGE'
})
