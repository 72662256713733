import React, { useEffect, useContext, useState } from 'react'
import StreamInfoPanel from '../../components/Layout/StreamInfoPanel'
import ChatSection from '../../components/Layout/ChatSection'
import Header from '../../components/Layout/Header'
import VideoSection from '../../components/Layout/VideoSection'
import { runOneSignal, setPageTitleDefault } from '../../utils/utilities'
import AdBanner from '../../components/Utilities/AdBanner'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { AuthenticationContext } from '../../context/authenticationContext'

const Chatroom = () => {
  setPageTitleDefault()
  const { user } = useContext(AuthenticationContext)
  const {
    [SETTINGS.ONESIGNAL_APP_ID.key]: oneSignalAppId,
    [SETTINGS.DISPLAY_BANNER.key]: displayBanner
  } = useContext(SiteConfigContext)
  const [showAd, setShowAd] = useState(true)

  useEffect(() => {
    setShowAd(displayBanner)
  }, [displayBanner])

  useEffect(() => {
    if (user && oneSignalAppId) {
      handleOneSignal()
    }
  }, [user, oneSignalAppId])

  const handleOneSignal = async () => {
    const allowLocal = process.env.NODE_ENV === 'development'
    runOneSignal(oneSignalAppId, user.userService.email, allowLocal)
  }

  const calculateVideoOffset = () => {
    const header = document.getElementById('header')
    const topBar = document.getElementById('top_bar')
    return (header?.offsetHeight || 0) + (topBar?.offsetHeight || 0)
  }

  const applyMaxHeight = (element) => {
    if (element) {
      const offset = calculateVideoOffset()
      const maxHeight = `calc(100vh - ${offset}px)`
      element.style.maxHeight = maxHeight
    }
  }

  const setVideoMaxHeight = () => {
    applyMaxHeight(document.querySelector('.resize-helper'))
  }

  useEffect(() => {
    setVideoMaxHeight()
    window.addEventListener('resize', setVideoMaxHeight)
    return () => window.removeEventListener('resize', setVideoMaxHeight)
  }, [showAd])

  return (
    <main className="ios-safari-hack flex h-screen max-h-screen flex-col overflow-hidden bg-custom-gradient">
      <Header />
      <div className="flex flex-1 flex-col overflow-auto md:flex-row md:overflow-y-hidden">
        <div className="flex flex-1 flex-col md:overflow-y-auto scrollbar-hide">
          <div id="top_bar" className="flex bg-black">
            <div className="mx-auto w-full">
              {showAd && <AdBanner toggleAd={setShowAd} />}
            </div>
          </div>
          <div className="bg-black">
            <VideoSection />
          </div>
          <StreamInfoPanel />
        </div>
        <ChatSection />
      </div>
    </main>
  )
}

export default Chatroom
