import React, { useContext } from 'react'
import Logo from '../../graphics/Logo'
import Footer from '../../components/Layout/Footer'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { Link, useNavigate } from 'react-router-dom'
import styles from './AudioVideoTroubleshooting.module.css'
import { setPageTitle } from '../../utils/utilities'

const AudioVideoTroubleshooting = () => {
  setPageTitle('Audio & Video Troubleshooting')

  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate('/', { replace: true })
  }

  return (
    <main className="ios-safari-hack bg-custom-gradient flex flex-col h-screen overflow-y-auto">
      <div className="mx-auto my-auto">
        <div className="h-8 md:h-12 my-4">
          <Link to="/">
            <Logo />
          </Link>
        </div>
      </div>
      <div className="my-auto">
        <div className="container sm:rounded-xl bg-white dark:bg-neutral-900 w-full mx-auto p-4">
          <div className="flex flex-col justify-between items-center gap-4">
            <h1 className="mt-2 mb-4 text-center font-bold tracking-wide">
              Audio / Video Troubleshooting
            </h1>
            <div className={styles.wrapper}>
              <AudioHelpGuideText />
            </div>
            <button className="primary-button" onClick={handleGoBack}>
              ← Go Back
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

const AudioHelpGuideText = () => {
  const { [SETTINGS.AUDIO_HELP_GUIDE.key]: theGuide } =
    useContext(SiteConfigContext)

  return (
    <div
      className="flex flex-col gap-2"
      dangerouslySetInnerHTML={{ __html: theGuide }}
    />
  )
}

export default AudioVideoTroubleshooting
