import React, { useContext, useEffect, useState } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { setPageTitle } from '../../../../utils/utilities'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { AuthenticationContext } from '../../../../context/authenticationContext'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FEATURES } from '../../../../constants/settings'
import { putProduct } from '../../../../utils/APIs/products'
import ToggleSwitchBasic from '../../../Forms/common/ToggleSwitchBasic'

const OpenHouse = ({ product, updateState }) => {
  const handleToggle = async () => {
    const data = {
      ...product,
      access: {
        ...product.access,
        openHouse: {
          ...product.access.openHouse,
          enabled: !product.access.openHouse.enabled
        }
      }
    }

    await putProduct(data)
    updateState(data)
    toast.success('Open House toggled.')
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 justify-start">
        <h2>Open House</h2>
        <ToggleSwitchBasic
          value={product.access.openHouse.enabled}
          label=""
          color="green"
          onChange={handleToggle}
        />
      </div>
      {product.access.openHouse.enabled && (
        <>
          <Subtype product={product} updateState={updateState} />
          <Dates product={product} updateState={updateState} />
        </>
      )}
    </div>
  )
}

const Subtype = ({ product, updateState }) => {
  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      ...product,
      access: {
        ...product.access,
        openHouse: {
          ...product.access.openHouse,
          itemNumber: formData.get('itemNumber')
        }
      }
    }

    await putProduct(data)
    updateState(data)
    toast.success('Open House Item Number saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h3>Open House Item Number (Pub Code)</h3>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <input
          id="itemNumber"
          name="itemNumber"
          defaultValue={product.access.openHouse.itemNumber}
          className="input block w-full"
          placeholder="Open House Item Number"
        />
        <button type="submit" className="primary-button w-fit">
          Save
        </button>
      </form>
    </div>
  )
}

const Dates = ({ product, updateState }) => {
  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      ...product,
      access: {
        ...product.access,
        openHouse: {
          ...product.access.openHouse,
          startDate: formData.get('startDate'),
          endDate: formData.get('endDate')
        }
      }
    }

    await putProduct(data)
    updateState(data)
    toast.success('Open House Date Range saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h3>Open House Date Range</h3>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <label className="block w-full" htmlFor="start">
          <h4>Start</h4>
          <input
            id="startDate"
            name="startDate"
            type="date"
            defaultValue={product.access.openHouse.startDate}
            className="input block w-full"
          />
        </label>
        <label className="block w-full" htmlFor="end">
          <h4>End</h4>
          <input
            id="endDate"
            name="endDate"
            type="date"
            defaultValue={product.access.openHouse.endDate}
            className="input block w-full"
          />
        </label>
        <button type="submit" className="primary-button w-fit mt-auto">
          Save
        </button>
      </form>
    </div>
  )
}

export default OpenHouse
