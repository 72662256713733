import OneSignal from 'react-onesignal'
import * as ROLES from '../constants/roles'

export const delay = (ms) =>
  new Promise(function (resolve) {
    setTimeout(resolve, ms)
  })

export const setPageTitle = (title) => {
  document.title = title
}

export const setPageTitleCounter = (count, defaultTitle = null) => {
  if (!document.hasFocus() && count > 0) {
    const currentTitle = document.title.includes('|')
      ? document.title.split('|')[1]
      : document.title

    let newTitle =
      currentTitle.includes('(') && currentTitle.includes(')')
        ? currentTitle.replace(/\((.+?)\)/g, `(${count})`)
        : `(${count}) ${currentTitle}`

    setPageTitle(newTitle)
  } else if (defaultTitle) {
    setPageTitle(defaultTitle)
  } else {
    setPageTitleDefault()
  }
}

export const setPageTitleDefault = () => {
  document.title = process.env.REACT_APP_PRODUCT_NAME
}

export const getAdditionalSubs = (
  subscriptions,
  openHouseItemNumber = null
) => {
  const productCode = process.env.REACT_APP_PRODUCT_CODE

  return subscriptions
    .filter(
      (x) =>
        x.itemNumber !== productCode && x.itemNumber !== openHouseItemNumber
    )
    .map((obj) => obj.itemNumber)
}

export const hasFeature = (subFeatures, feature) => {
  const index = subFeatures.findIndex((sub) => {
    return sub.label === feature
  })
  return index !== -1
}

export const runOneSignal = async (theID, userID, allowLocal) => {
  if (theID) {
    await OneSignal.init({
      appId: theID,
      allowLocalhostAsSecureOrigin: allowLocal
    })
    OneSignal.showSlidedownPrompt()
    OneSignal.setExternalUserId(userID)
  }
}

export const modNameChanger = (message) => {
  return message.user.role.role === ROLES.MODERATOR
    ? `Moderator (${message.user.userService.profile.name})`
    : message.user.userService.profile.name
}

export const sanitizeText = (richText) => {
  const emptyParagraphsRegex = /<p><br><\/p>/g
  const nonBreakingSpaceRegex = /&nbsp;/g

  const scrubbedHTML = richText
    .replace(emptyParagraphsRegex, '')
    .replace(nonBreakingSpaceRegex, ' ')

  return scrubbedHTML
}
