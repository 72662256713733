import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/pro-solid-svg-icons'
import SearchButton from '../../Buttons/SearchButton'
import PropTypes from 'prop-types'

const TradeSearch = ({ handleSearch }) => {
  const theInput = useRef()
  const [showGuide, setShowGuide] = useState(false)
  const [text, setText] = useState('')

  const toggleGuide = (e) => {
    e.preventDefault()
    setShowGuide(!showGuide)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    handleSearch(text)
    setShowGuide(false)
  }

  const handleClick = (type, option = '') => {
    let regex
    switch (type) {
      case 'type':
        regex = /\bt(ype)?:("(.*?)"|'(.*?)'|\S+|)/i
        break
      case 'action':
        regex = /\ba(ction)?:("(.*?)"|'(.*?)'|\S+|)/i
        break
      case 'option':
        regex = /\bo(ption)?:("(.*?)"|'(.*?)'|\S+|)/i
        break
      case 'notes':
        regex = /\bn(otes)?:("(.*?)"|'(.*?)'|\S+|)/i
        break
      case 'start':
        regex = /\bs(tart)?:("(.*?)"|'(.*?)'|\S+|)/i
        option = 'YYYY-MM-DD'
        break
      case 'end':
        regex = /\be(nd)?:("(.*?)"|'(.*?)'|\S+|)/i
        option = 'YYYY-MM-DD'
        break
    }

    const match = text.match(regex)
    if (match && type === 'type') {
      const newText = text.replace(regex, `${type}:${option}`)
      setText(newText)
      theInput.current.focus()
    } else if (!match) {
      if (text.length > 0) {
        setText(`${text} ${type}:${option}`)
      } else {
        setText(`${type}:${option}`)
      }
      theInput.current.focus()
    }
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className="relative flex items-stretch z-20 gap-2"
      >
        <div className="flex flex-col w-full">
          <div
            className={`flex flex-row gap-1 border-t border-white dark:border-neutral-900 p-1 items-end w-full ${
              showGuide
                ? 'bg-neutral-200 dark:bg-neutral-700'
                : 'bg-white dark:bg-neutral-900'
            }`}
          >
            <input
              ref={theInput}
              aria-label="Search trades"
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="grow input pr-9"
              placeholder="Search trades..."
            />
            <div
              className="py-2 px-1 -ml-9 mr-2 text-neutral-400 cursor-pointer"
              onClick={toggleGuide}
            >
              <FontAwesomeIcon icon={faFilter} />
            </div>
            <SearchButton />
          </div>
        </div>
      </form>
      {showGuide && <TradeSearchGuide handleClick={handleClick} />}
    </div>
  )
}

TradeSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired
}

const TradeSearchGuide = ({ handleClick }) => {
  const searchs = ['start', 'end']

  return (
    <div className="flex flex-col items-center bg-neutral-200 dark:bg-neutral-700 transition-all border-b border-white dark:border-neutral-900">
      <div className="w-full pt-0 px-2 pb-1">
        <div className="flex justify-between text-white dark:text-neutral-200">
          <p className="font-bold">Search filters:</p>
        </div>
        <div className="flex flex-wrap gap-1">
          <p
            onClick={() => handleClick('type', 'open')}
            className="w-fit px-1 font-bold rounded border border-brand-primary-500 hover:bg-brand-primary-500 cursor-pointer bg-brand-primary-400 dark:bg-brand-primary-700"
          >
            open
          </p>
          <p
            onClick={() => handleClick('type', 'update')}
            className="w-fit px-1 font-bold rounded border border-cyan-500 hover:bg-cyan-500 cursor-pointer bg-cyan-400 dark:bg-cyan-700"
          >
            update
          </p>
          <p
            onClick={() => handleClick('type', 'close')}
            className="w-fit px-1 font-bold rounded border border-red-500 hover:bg-red-500 cursor-pointer bg-red-400 dark:bg-red-700"
          >
            close
          </p>

          {searchs.map((each) => {
            return (
              <p
                onClick={() => handleClick(each)}
                key={each}
                className="w-fit px-1 font-bold rounded border border-neutral-500 hover:bg-neutral-500 cursor-pointer"
              >
                {each}:
              </p>
            )
          })}
        </div>
      </div>
    </div>
  )
}

TradeSearchGuide.propTypes = {
  handleClick: PropTypes.func.isRequired
}

export default TradeSearch
