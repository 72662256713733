import React, { useContext } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'

const PasswordReset = () => {
  const { [SETTINGS.PASSWORD_RESET.key]: link, updateSetting } =
    useContext(SiteConfigContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = formData.get('link')
    await updateSetting(SETTINGS.PASSWORD_RESET, data)
    toast.success('Password Reset Link saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Password Reset Link</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <input
          id="link"
          name="link"
          defaultValue={link}
          className="input block w-full"
          placeholder="Password Reset Link"
          required
        />
        <button
          type="submit"
          aria-label="Save Password Reset Link"
          className="primary-button w-fit"
        >
          Save
        </button>
      </form>
    </div>
  )
}

export default PasswordReset
