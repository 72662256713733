module.exports = Object.freeze({
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  RESET: 'RESET',
  SHOW_CONTROLS: 'SHOW_CONTROLS',
  PAUSE: 'PAUSE',
  PLAY: 'PLAY',
  VOLUME: 'VOLUME',
  MUTE: 'MUTE',
  LIVE: 'LIVE',
  LIVE_URL: 'LIVE_URL',
  RECONNECT: 'RECONNECT',
  QUALITY: 'QUALITY'
})
