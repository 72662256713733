import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { faAdd, faSpinner, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'
import { setPageTitle } from '../../../../utils/utilities'
import * as SETTINGS from '../../../../constants/settings'
import { DAYS } from '../../../../constants/general'

const MESSAGE_MAX_LENGTH = 100

const Schedule = () => {
  setPageTitle('Content | Schedule')
  const { isLoading, [SETTINGS.SCHEDULE.key]: schedule } =
    useContext(SiteConfigContext)

  if (isLoading) {
    return (
      <h1 className="flex items-center">
        Loading...{' '}
        <FontAwesomeIcon
          icon={faSpinner}
          className="h-4 w-4 ml-2 animate-spin"
        />
      </h1>
    )
  }

  return (
    <div className="flex flex-col h-full w-full">
      {DAYS.map((day) => {
        return (
          <AddScheduleForm key={day} day={day} currentSchedule={schedule} />
        )
      })}
    </div>
  )
}

const AddScheduleForm = ({ currentSchedule, day }) => {
  const { updateSetting } = useContext(SiteConfigContext)
  const [text, setText] = useState('')

  const handleAdd = async () => {
    if (text !== '') {
      const schedule = currentSchedule
      schedule[day].push(text)
      await updateSetting(SETTINGS.SCHEDULE, schedule)
      setText('')
      toast.success('Schedule item added!')
    }
  }

  const handleDelete = async (choice) => {
    const schedule = currentSchedule
    schedule[day] = schedule[day].filter((each) => each !== choice)
    await updateSetting(SETTINGS.SCHEDULE, schedule)
    setText('')
    toast.success('Schedule item deleted!')
  }

  return (
    <div className="h-fit mb-4">
      <h2 className="mb-1">{day.toUpperCase()}</h2>
      <div className="flex flex-col gap-2 border-l-2 border-blue-600">
        {currentSchedule[day].map((choice, index) => (
          <div className="flex items-center pl-4" key={index}>
            <div className="grow input">{choice}</div>
            <button
              className="flex items-center p-2 rounded-full bg-red-500 dark:bg-red-700 text-white w-8 h-8 ml-2"
              type="button"
              onClick={() => handleDelete(choice)}
            >
              <FontAwesomeIcon icon={faTrashCan} className="w-4 h-4" />
            </button>
          </div>
        ))}
        <div className="flex items-center pl-4">
          <input
            value={text}
            onChange={(e) => setText(e.target.value)}
            className="input w-full"
            placeholder="Enter a value..."
            maxLength={MESSAGE_MAX_LENGTH}
          />
          <button
            className="flex items-center p-2 rounded-full bg-green-500 dark:bg-green-700 text-white w-8 h-8 ml-2"
            type="button"
            onClick={handleAdd}
          >
            <FontAwesomeIcon icon={faAdd} className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  )
}

AddScheduleForm.propTypes = {
  currentSchedule: PropTypes.object.isRequired,
  day: PropTypes.string.isRequired
}

export default Schedule
