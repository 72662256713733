import React from 'react'
import PropTypes from 'prop-types'
import { ColorPicker } from '../../../utils/colors'
import { putUser } from '../../../utils/APIs/users'

const ChangeColorForm = ({ user, callbackFunction }) => {
  const handleSetColor = async (value) => {
    const response = await putUser(user, 'userService.profile.color', value)
    if (response && response.status === 200) {
      if (callbackFunction) {
        callbackFunction(response.data)
      }
    }
  }

  return (
    <div className="mb-auto flex w-fill">
      <div className="flex flex-col gap-2 justify-start items-start">
        <ColorPicker handleSelect={handleSetColor} />
      </div>
    </div>
  )
}

ChangeColorForm.propTypes = {
  user: PropTypes.string.isRequired
}

export default ChangeColorForm
