import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import ActionPanel from '../ActionPanel'
import { AuthenticationContext } from '../../../context/authenticationContext'
import PostDate from '../../Utilities/PostDate'
import TextWrapper from '../../Utilities/TextWrapper'
import * as ROLES from '../../../constants/roles'
import MessageEdited from './MessageEdited'
import ReplyTo from './ReplyTo'
import UserName from './UserName'
import { SiteConfigContext } from '../../../context/siteConfigContext'

const Message = ({
  message,
  channel,
  expandedUserInfoWidget,
  setExpandedUserInfoWidget,
  modView,
  emphasize = true
}) => {
  const { user } = useContext(AuthenticationContext)
  const { setReplyTo } = useContext(SiteConfigContext)
  const [showActionPanel, setShowActionPanel] = useState(false)

  const handleReply = () => {
    setReplyTo(message)
  }

  const roleClassesMap = {
    [ROLES.ADMIN]:
      'border-blue-200 dark:border-blue-900 from-blue-100 dark:from-blue-900/20 border-y py-1 bg-gradient-to-tl to-transparent',
    [ROLES.HOST]:
      'border-blue-200 dark:border-blue-900 from-blue-100 dark:from-blue-900/20 border-y py-1 bg-gradient-to-tl to-transparent',
    [ROLES.MODERATOR]:
      'border-orange-200 dark:border-orange-900 from-orange-100 dark:from-orange-900/20 border-y py-1 bg-gradient-to-tl to-transparent',
    default: ''
  }

  let roleClasses = emphasize
    ? roleClassesMap[message.user.role.role]
    : roleClassesMap.default
  if (user) {
    if (user.userService.settings.emphasizeStaffMessages) {
      roleClasses = roleClassesMap[message.user.role.role]
    } else {
      roleClasses = roleClassesMap.default
    }
  }

  return (
    <div className="w-full animate-pop-in">
      {message.replyTo && <ReplyTo user={user} message={message.replyTo} />}
      <div
        className={`${roleClasses} relative break-words px-2`}
        key={message._id}
        onMouseEnter={() => setShowActionPanel(true)}
        onMouseLeave={() => setShowActionPanel(false)}
      >
        {user && user.userService.settings.showTimestamps && (
          <PostDate date={message.postdate} />
        )}
        <UserName
          message={message}
          user={user}
          expandedUserInfoWidget={expandedUserInfoWidget}
          setExpandedUserInfoWidget={setExpandedUserInfoWidget}
          modView={modView}
        />
        <span className="dark:text-neutral-50">
          <TextWrapper fromUser={message.user} text={message.message} />
          {message.edited && <MessageEdited />}
        </span>
        {channel && showActionPanel && (
          <ActionPanel
            channel={channel}
            message={message}
            handleReply={handleReply}
          />
        )}
      </div>
    </div>
  )
}

Message.propTypes = {
  message: PropTypes.object.isRequired,
  channel: PropTypes.string,
  expandedUserInfoWidget: PropTypes.string,
  setExpandedUserInfoWidget: PropTypes.func,
  modView: PropTypes.bool
}

export default Message
