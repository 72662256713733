module.exports = Object.freeze({
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  RESET: 'RESET',
  SET_USERS: 'SET_USERS',
  HAS_MORE: 'HAS_MORE',
  SEARCH: 'SEARCH',
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  DELETE_USER: 'DELETE_USER'
})
