import {
  ERROR,
  LOADING,
  RESET,
  PAGE_LOAD,
  LOGGED_OUT,
  USER
} from '../constants/actions/authenticationActions'

export const initialState = {
  error: null,
  loading: true,
  pageLoad: true,
  loggedOut: '',
  user: null
}

export function authenticationReducer(state, action) {
  switch (action.type) {
    case RESET:
      return initialState

    case ERROR:
      return {
        ...state,
        error: action.payload
      }

    case LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case PAGE_LOAD:
      return {
        ...state,
        pageLoad: action.payload
      }

    case LOGGED_OUT:
      return {
        ...state,
        loggedOut: action.payload,
        user: null
      }

    case USER:
      return {
        ...state,
        user: action.payload
      }

    default:
      return state
  }
}
