import React, { useReducer, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from 'react-infinite-scroll-component'
import { getPrivateMessagesUsers } from '../../../../utils/APIs/privateMessages'
import { UserRow, ErrorRow, LoadingRow, LoadedAllRow } from '../UserRows'
import {
  allUsersReducer,
  initialState
} from '../../../../reducers/allUsersReducer'

import {
  LOADING,
  ERROR,
  SET_USERS,
  HAS_MORE
} from '../../../../constants/actions/allUsersActions'

const AllUsers = ({ fetchUserCounts, handlePinnedUserClick, searchId }) => {
  const navigate = useNavigate()
  const [state, dispatch] = useReducer(allUsersReducer, initialState)
  const [search, setSearch] = useState(searchId || '')

  useEffect(() => {
    if (search) {
      loadUsers(true, search)
    } else {
      loadUsers()
    }
  }, [search])

  const loadUsers = async (reset = false, search = null) => {
    const response = await getPrivateMessagesUsers({
      loadMore:
        state.users.length > 0 && !reset
          ? `${state.users[state.users.length - 1].lowerName}`
          : '',
      search: search
    })
    if (response && response.status === 200) {
      fetchUserCounts()
      if (response.data.length > 0) {
        if (search && response.data.length === 1) {
          navigate(
            `us/${response.data[0].userService._id}/pm/${response.data[0]._id}`,
            {
              replace: true
            }
          )
        }

        if (reset) {
          dispatch({ type: SET_USERS, payload: response.data })
        } else {
          dispatch({
            type: SET_USERS,
            payload: [...state.users, ...response.data]
          })
        }
      } else {
        dispatch({ type: HAS_MORE, payload: false })
      }
    } else {
      dispatch({ type: ERROR, payload: true })
    }
    dispatch({ type: LOADING, payload: false })
  }

  const togglePinnedUser = (pmUserId) => {
    const user = state.users.find((user) => user._id === pmUserId)
    handlePinnedUserClick(user, () => {
      loadUsers(true)
    })
  }

  if (state.loading) {
    return <LoadingRow />
  }
  if (state.error) {
    return <ErrorRow />
  }

  return (
    <div className="flex flex-col overflow-y-hidden w-full h-full">
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="input block w-full mb-1 rounded-none"
        placeholder="Search"
      />
      <div id="userList" className="overflow-y-auto w-full h-full ">
        <InfiniteScroll
          dataLength={state.users.length}
          next={loadUsers}
          className="flex flex-col overflow-y-auto gap-1 w-full h-full"
          scrollableTarget="userList"
          loader={<LoadingRow />}
          endMessage={<LoadedAllRow />}
          hasMore={state.hasMore}
        >
          {state.users.map((user) => {
            return (
              <UserRow
                key={user._id}
                user={user}
                togglePinned={togglePinnedUser}
                allUsers
              />
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default AllUsers
