import React, { useContext, useMemo, useRef, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import Delta from 'quill-delta'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { uploadToS3 } from '../../../utils/S3ImageUploads'

const ReactQuillEditor = ({ initialHtml, onChange }) => {
  const { user } = useContext(AuthenticationContext)
  const editorRef = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const [image, setImage] = useState('')
  const [altText, setAltText] = useState('')
  const [isUploading, setIsUploading] = useState(false)

  /*
  Handlers
  */
  const handleImageUpload = async (e) => {
    setIsUploading(true)
    const file = e.target.files[0]
    const response = await uploadToS3({ file, user })
    if (response) {
      setImage(response)
      setIsUploading(false)
    }
  }

  const handleSubmit = () => {
    // Handle the form submission here
    if (!isUploading && image.length > 0) {
      editorRef.current.focus()
      const editor = editorRef.current.getEditor()
      const range = editor.getSelection()

      if (editor && range) {
        const index = range.index || 0
        editor.updateContents(
          new Delta()
            .retain(index)
            .insert({ image }, { alt: altText, class: 'w-full inserted' })
        )
        editor.setSelection(index + 1)
        setAltText('')
        setImage('')
        setIsOpen(false)
      }
    }
  }

  const handleOnEditorChange = (html) => {
    onChange(html)
  }

  /*
  Custom Image Block
  */
  const ATTRIBUTES = ['class', 'src', 'alt']
  class CustomImage extends Quill.import('formats/image') {
    static formats(domNode) {
      const formats = ATTRIBUTES.reduce(function (formats, attribute) {
        if (domNode.hasAttribute(attribute)) {
          formats[attribute] = domNode.getAttribute(attribute)
        }
        return formats
      }, {})
      return formats
    }

    format(name, value) {
      if (ATTRIBUTES.indexOf(name) > -1) {
        if (value) {
          this.domNode.setAttribute(name, value)
        } else {
          this.domNode.removeAttribute(name)
        }
      } else {
        super.format(name, value)
      }
    }
  }
  CustomImage.blotName = 'image'
  CustomImage.tagName = 'IMG'
  CustomImage.allowedAttributes = ATTRIBUTES
  Quill.register(CustomImage, true)

  /*
  Custom Link Module
  */
  const Link = Quill.import('formats/link')
  class CustomLink extends Link {
    static create(value) {
      if (!/^https?:\/\//i.test(value)) {
        value = 'http://' + value
      }
      const node = super.create(value)
      node.setAttribute('target', '_blank')
      return node
    }
  }
  Quill.register(CustomLink, true)

  const handleBlur = () => {
    const editor = editorRef.current.getEditor()
    const links = editor.root.querySelectorAll('a')

    links.forEach((link) => {
      let href = link.getAttribute('href')
      if (!/^https?:\/\//i.test(href)) {
        href = 'http://' + href
        link.setAttribute('href', href)
      }
    })
  }

  /*
  Quill Modules to attach to editor
  */
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ['bold', 'italic', 'underline', 'strike'],
          ['link'],
          ['clean']
        ],
        handlers: {
          image: () => setIsOpen(true)
        }
      }
    }),
    []
  )

  /*
  Allowed formats
  */
  const formats = ['bold', 'italic', 'underline', 'strike', 'link']

  return (
    <>
      {isOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            {/* Header Section */}
            <div className="border-b pb-2 mb-4">
              <h3 className="text-lg leading-6 font-semibold text-gray-900 flex items-center justify-center space-x-2">
                <FontAwesomeIcon icon={faImage} className="text-blue-500" />
                <span>Insert Image</span>
              </h3>
            </div>

            {/* Body Section */}
            <div className="mt-3 text-center">
              {/* Image Upload */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Upload Image
                </label>
                {isUploading && <FontAwesomeIcon icon={faSpinner} spin />}
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="my-2 p-2 border rounded w-full"
                />
              </div>

              {/* Separator */}
              <div className="my-4 border-t" />

              {/* Image URL Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Image URL
                </label>
                <input
                  type="text"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                  placeholder="Enter image URL"
                  className="my-2 p-2 border rounded w-full"
                />
              </div>

              {/* Alt Text Input */}
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Image Alt Text
                </label>
                <input
                  type="text"
                  value={altText}
                  onChange={(e) => setAltText(e.target.value)}
                  placeholder="Enter alt text"
                  className="my-2 p-2 border rounded w-full"
                />
              </div>

              {/* Insert Image Button */}
              <button
                type="button"
                disabled={isUploading || !image.length}
                onClick={handleSubmit}
                className="mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 w-full transition duration-150 ease-in-out"
              >
                Insert Image
              </button>
            </div>

            {/* Close Button */}
            <button
              className="mt-4 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded w-full transition duration-150 ease-in-out"
              onClick={() => setIsOpen(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <ReactQuill
        ref={editorRef}
        id="body"
        name="body"
        style={{ height: '420px', width: '100%' }}
        aria-label="Body"
        placeholder="Body"
        defaultValue={initialHtml}
        onChange={handleOnEditorChange}
        onBlur={handleBlur}
        modules={modules}
        formats={formats}
      />
    </>
  )
}

export default ReactQuillEditor
