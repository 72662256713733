import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons'
import { SOCKET_EVENTS } from '../../../constants/sockets'
import { SocketContext } from '../../../context/socketContext'

const ViewerCount = () => {
  const { socket } = useContext(SocketContext)
  const [viewerCount, setViewerCount] = useState(0)

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.VIEW_COUNT, function (data) {
        setViewerCount(data)
      })
    }
    return () => {
      if (socket) {
        socket.off(SOCKET_EVENTS.VIEW_COUNT)
      }
    }
  }, [socket])

  function formattedViewerCount(viewerCount) {
    return Math.abs(viewerCount) > 999
      ? Math.sign(viewerCount) * (Math.abs(viewerCount) / 1000).toFixed(1) + 'K'
      : Math.sign(viewerCount) * Math.abs(viewerCount)
  }

  return (
    <div className="mx-2 w-fit flex items-center justify-between text-xs font-medium text-black/80 dark:text-white/80">
      <span className="tabular-nums">{formattedViewerCount(viewerCount)}</span>
      <FontAwesomeIcon
        icon={faUser}
        className="ml-2 h-3 w-3"
        aria-hidden="true"
      />
    </div>
  )
}

export default ViewerCount
