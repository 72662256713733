import React from 'react'
import moment from 'moment'
import Badge from '../../../graphics/Badge'
import CustomBadges from '../CustomBadges'
import * as ROLES from '../../../constants/roles'

export default function UserBadges({ role, user, registrationDate }) {
  const start = moment(registrationDate)
  const end = moment(registrationDate).add(7, 'days')
  const between = moment().isBetween(start, end)

  return (
    <>
      {between && <Badge badge="new" />}
      {role === ROLES.ADMIN && <Badge badge={ROLES.ADMIN} />}
      {role === ROLES.MODERATOR && <Badge badge={ROLES.MODERATOR} />}
      {role === ROLES.HOST && <Badge badge={ROLES.HOST} />}
      {role === ROLES.VIP && <Badge badge={ROLES.VIP} />}
      {role === ROLES.BOT && <Badge badge={ROLES.BOT} />}
      {role === ROLES.SUBSCRIBER && <CustomBadges user={user} />}
    </>
  )
}
