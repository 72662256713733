import React from 'react'
import styles from './NotificationBadge.module.css'

const NotificationBadge = ({ count }) => {
  const show = count > 0

  if (show) {
    return (
      <div className={styles.badge}>
        <div className={styles.circle} data="animate" />
        <div className={styles.circle} />
      </div>
    )
  }
  return null
}

export default NotificationBadge
