import {
  ERROR,
  LOADING,
  RESET,
  LIVE,
  RECONNECT
} from '../constants/actions/videoPlayerActions'

export const initialState = {
  error: false,
  loading: true,
  live: false,
  reconnecting: false,
  reconnectAttempts: 0
}

export function videoPlayerReducer(state, action) {
  switch (action.type) {
    case RESET:
      return {
        ...initialState,
        reconnecting: true,
        reconnectAttempts: 1
      }

    case ERROR:
      return {
        ...state,
        error: action.payload
      }

    case LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case RECONNECT:
      return {
        ...state,
        loading: action.payload.reconnecting,
        reconnecting: action.payload.reconnecting,
        reconnectAttempts: action.payload.reset
          ? 1
          : state.reconnectAttempts + 1
      }

    case LIVE:
      return {
        ...state,
        live: action.payload
      }

    default:
      return state
  }
}
