import React, { useEffect, useState, useContext } from 'react'
import Trade from '../../Messages/Trade'
import PropTypes from 'prop-types'
import moment from 'moment'
import { AuthenticationContext } from '../../../context/authenticationContext'

const defaultValues = {
  type: 'open',
  headline: '',
  option: '',
  body: '',
  images: [],
  foundBySAM: false
}

const PostTradeFormPreview = ({ trade }) => {
  const { user } = useContext(AuthenticationContext)
  const [message, setMessage] = useState({
    _id: 'preview-id',
    type: trade.type || defaultValues.type,
    headline: trade.headline || defaultValues.headline,
    body: trade.body || defaultValues.body,
    extra: [
      {
        key: 'option',
        type: 'plain-text',
        value: trade.option || defaultValues.option
      },
      {
        key: 'images',
        type: 'images',
        value: trade.images || defaultValues.images
      },
      {
        key: 'foundBySAM',
        type: 'boolean',
        value: trade.foundBySAM || defaultValues.foundBySAM
      }
    ],
    user,
    edited: false,
    createdAt: moment().format('YYYY-MM-DDTHH:MM:ss')
  })

  useEffect(() => {
    setMessage((message) => ({
      ...message,
      ...{ type: trade.type || defaultValues.type },
      ...{ headline: trade.headline || defaultValues.headline },
      ...{ body: trade.body || defaultValues.body },
      extra: [
        {
          key: 'option',
          type: 'plain-text',
          value: trade.option || defaultValues.option
        },
        {
          key: 'images',
          type: 'images',
          value: trade.images || defaultValues.images
        },
        {
          key: 'foundBySAM',
          type: 'boolean',
          value: trade.foundBySAM || defaultValues.foundBySAM
        }
      ]
    }))
  }, [trade])

  return (
    <div className="flex w-full flex-col">
      <h2 className="my-4 md:mt-0">New Trade Preview</h2>
      <Trade message={message} truncate />
    </div>
  )
}

PostTradeFormPreview.propTypes = {
  trade: PropTypes.object
}

export default PostTradeFormPreview
