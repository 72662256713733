import React from 'react'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const LoadingMore = () => {
  return (
    <div className="mx-auto mb-2 flex w-32 content-center justify-center rounded py-2 text-neutral-900 dark:text-neutral-100">
      <span className="my-auto font-bold">
        Loading...
        <FontAwesomeIcon className="animate-spin ml-2" icon={faSpinner} />
      </span>
    </div>
  )
}
export default LoadingMore
