import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as SETTINGS from '../../../constants/settings'

const CustomButton = () => {
  const { [SETTINGS.CUSTOM_BUTTON.key]: customButton } =
    useContext(SiteConfigContext)

  if (!customButton.link || !customButton.text) {
    return null
  }

  return (
    <Link to={customButton.link} target="_blank" rel="noopener noreferrer">
      <button className="header-button custom-button">
        <span className="hidden md:block">{customButton.text}</span>
        {customButton.icon && (
          <FontAwesomeIcon
            icon={customButton.icon}
            className="header-button-icon"
            aria-hidden="true"
          />
        )}
      </button>
    </Link>
  )
}

export default CustomButton
