import React, { useContext } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'

const WelcomeVideo = () => {
  const { [SETTINGS.WELCOME_VIDEO.key]: welcomeVideo, updateSetting } =
    useContext(SiteConfigContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      ...welcomeVideo,
      wistiaId: formData.get('welcomeVideoWistiaId'),
      blurb: formData.get('welcomeVideoBlurb')
    }
    await updateSetting(SETTINGS.WELCOME_VIDEO, data)
    toast.success('Welcome Video saved!')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Welcome Video</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex flex-col justify-start gap-2"
      >
        <label className="flex gap-2 justify-between items-center">
          <h3 className="w-fit flex-shrink-0">Wistia ID</h3>
          <input
            aria-label="Welcome Video Wistia ID"
            id="welcomeVideoWistiaId"
            name="welcomeVideoWistiaId"
            defaultValue={welcomeVideo.wistiaId}
            className="input block w-full"
            placeholder="Welcome Video Wistia ID"
          />
        </label>
        <label className="flex gap-2 justify-between items-center">
          <h3 className="w-fit flex-shrink-0">Blurb</h3>
          <input
            aria-label="Welcome Video Blurb"
            id="welcomeVideoBlurb"
            name="welcomeVideoBlurb"
            defaultValue={welcomeVideo.blurb}
            className="input block w-full"
            placeholder="Welcome Video Blurb"
          />
          <button
            type="submit"
            aria-label="Save Welcome Video"
            className="primary-button w-fit"
          >
            Save
          </button>
        </label>
      </form>
    </div>
  )
}

export default WelcomeVideo
