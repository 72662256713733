import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { AuthenticationContext } from '../context/authenticationContext'
import { LoadingScreen } from '../pages'
import { SiteConfigContext } from '../context/siteConfigContext'
import * as SETTINGS from '../constants/settings'

const RequireAuth = ({ children }) => {
  const { pageLoad, user } = useContext(AuthenticationContext)
  const {
    isLoading,
    freeEnabled,
    [SETTINGS.WELCOME_VIDEO.key]: welcomeVideo
  } = useContext(SiteConfigContext)

  const history = useLocation()
  const location = useLocation()

  if (pageLoad || isLoading) {
    return <LoadingScreen />
  }

  if (!freeEnabled) {
    if (!user) {
      return <Navigate to="/login" state={{ from: location }} />
    }

    if (!user.onboarding.terms) {
      if (history.pathname !== '/terms-and-conditions') {
        return <Navigate to="/terms-and-conditions" />
      }
    } else if (!user.onboarding.welcome && welcomeVideo.wistiaId) {
      if (history.pathname !== '/welcome') {
        return <Navigate to="/welcome" />
      }
    } else if (!user.userService.profile.name) {
      if (history.pathname !== '/register') {
        return <Navigate to="/register" />
      }
    }
  }

  return children
}

RequireAuth.propTypes = {
  children: PropTypes.object
}

export default RequireAuth
