import React, { useContext } from 'react'
import moment from 'moment'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'

const Copywrite = () => {
  const { [SETTINGS.COPYRIGHT.key]: copywrite } = useContext(SiteConfigContext)

  return (
    <p className="text-xs">
      &copy; {moment().format('YYYY')} {copywrite}
    </p>
  )
}

export default Copywrite
