import React from 'react'
import { setPageTitle } from '../../../utils/utilities'
import AnnouncementProvider from '../../AnnouncementProvider/AnnouncementProvider'

const AnnouncementHistory = () => {
  setPageTitle('Announcement History')

  return (
    <div className="flex h-full w-full flex-col">
      <AnnouncementProvider limit={5}>
        {({ announcements, loading, error, hasMore, fetchAnnouncements }) => {
          if (loading) return <h1>Loading...</h1>
          if (error) return <h1>{error}</h1>
          return (
            <>
              <h1>{announcements.length}</h1>
              {announcements.map((announcement) => (
                <div key={announcement.id}>
                  <p>{announcement.title}</p>
                  <p>{announcement.message}</p>
                </div>
              ))}
              <button
                disabled={loading || !hasMore}
                onClick={fetchAnnouncements}
              >
                <p>Click to load more!</p>
              </button>
            </>
          )
        }}
      </AnnouncementProvider>
    </div>
  )
}

export default AnnouncementHistory
