import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'

const Modal = ({
  children,
  title,
  open,
  setOpen,
  confirmFunction,
  cancelText,
  size
}) => {
  const cancelButtonRef = useRef(null)

  let modalSize
  switch (size) {
    case 'sm':
      modalSize = 'lg:w-96'
      break
    case 'md':
      modalSize = 'lg:w-[640px]'
      break
    default:
      modalSize = ''
      break
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-neutral-500/75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={`modal-dialogue-panel ${modalSize}`}>
                <div className="modal-dialogue-panel-inner-wrapper">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-4 w-full text-center sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3">{title}</Dialog.Title>
                      <div className="mt-2">{children}</div>
                    </div>
                  </div>
                </div>
                <div className="modal-dialogue-panel-footer">
                  {confirmFunction && (
                    <button
                      type="button"
                      className="modal-confirm-button"
                      onClick={() => confirmFunction()}
                    >
                      Confirm
                    </button>
                  )}
                  <button
                    type="button"
                    className="modal-cancel-button"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    {cancelText ? `${cancelText}` : 'Cancel'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  confirmFunction: PropTypes.func,
  cancelText: PropTypes.string,
  size: PropTypes.string
}

export default Modal
