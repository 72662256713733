import React, { useState, useContext, useEffect } from 'react'
import { AuthenticationContext } from '../../../context/authenticationContext'
import EmailForm from '../EmailForm'
import TermsAndConditionsForm from '../TermsAndConditionsForm'
import RenameForm from '../RenameForm'
import Modal from '../../Utilities/Modal'
import TermsAndConditionsText from '../../Utilities/TermsAndConditionsText'
import { replaceDefaultColor } from '../../../utils/colors'

const SignUpForm = ({ navigate = true }) => {
  const { user, updateUser } = useContext(AuthenticationContext)
  const [turnstileModal, setTurnstileModal] = useState(false)

  const [step, setStep] = useState({
    email: !user,
    terms: user && !user.onboarding.terms,
    name: user && user.onboarding.terms && !user.userService.profile.name
  })

  useEffect(() => {
    setStep({
      email: !user,
      terms: user && !user.onboarding.terms,
      name: user && user.onboarding.terms && !user.userService.profile.name
    })
  }, [user])

  useEffect(() => {
    if (user) {
      replaceDefaultColor(user, updateUser)
    }
  }, [user])

  return (
    <div className={'flex flex-col gap-1.5'}>
      <h2 className="font-bold">Join the Conversation!</h2>
      {step.email && <EmailForm turnstile={true} />}
      {step.terms && (
        // If the form is not in the chat section, don't navigate them
        <TermsAndConditionsForm
          shouldNavigate={navigate}
          turnstile={true}
          turnstileModal={turnstileModal}
          setTurnstileModal={setTurnstileModal}
        />
      )}
      {step.name && <RenameForm user={user} turnstile={true} />}
      {turnstileModal && (
        <TermsAndConditionsModal
          open={turnstileModal}
          setOpen={setTurnstileModal}
        />
      )}
    </div>
  )
}

export default SignUpForm

const TermsAndConditionsModal = ({ open, setOpen }) => {
  return (
    <Modal
      title="Terms and Conditions"
      open={open}
      setOpen={setOpen}
      cancelText={'Close'}
    >
      <div className="h-96 overflow-y-auto mb-2">
        <TermsAndConditionsText />
      </div>
    </Modal>
  )
}
