import AWS from 'aws-sdk'
import { nanoid } from 'nanoid'

AWS.config.update({
  region: 'us-east-1',
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:7c62b4ba-85df-4745-98b5-230670c01d27'
  })
})

const S3 = new AWS.S3({ apiVersion: '2006-03-01' })

export const uploadToS3 = async ({ file, user }) => {
  const contentType = getFileContentType(file.name)
  const extension = getFileExtension(file.name)

  const params = {
    Bucket: 'i.moal.tech',
    Key: `${nanoid()}.${extension}`,
    Body: file,
    ContentType: contentType,
    ACL: 'public-read'
  }

  const options = {
    tags: [{ Key: 'user', Value: user._id || '' }]
  }

  const data = await S3.upload(params, options).promise()

  const { Key } = data
  return `https://i.moal.tech/${Key}`
}

const getFileContentType = (fileName) => {
  const extension = getFileExtension(fileName)
  let contentType = 'image/'

  if (extension === 'jpg') {
    contentType += 'jpeg'
  } else if (extension === 'svg') {
    contentType += 'svg+xml'
  } else {
    contentType += extension
  }

  return contentType
}

const getFileExtension = (fileName) => {
  return fileName.split('.').pop()
}
