import {
  LOADING,
  ERROR,
  RESET,
  SET_USERS,
  HAS_MORE,
  SEARCH,
  ADD_USER,
  EDIT_USER,
  DELETE_USER
} from '../constants/actions/allUsersActions'

export const initialState = {
  loading: true,
  error: false,
  users: [],
  hasMore: true,
  search: ''
}

export function allUsersReducer(state, action) {
  switch (action.type) {
    case RESET:
      return initialState

    case ERROR:
      return {
        ...state,
        error: action.payload
      }

    case LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case SET_USERS:
      if (action.payload.length > 0) {
        return {
          ...state,
          error: false,
          loading: false,
          users: action.payload
        }
      } else {
        return {
          ...state,
          error: false,
          loading: false
        }
      }

    case HAS_MORE:
      return {
        ...state,
        hasMore: action.payload
      }

    case SEARCH:
      return {
        ...state,
        search: action.payload
      }

    case ADD_USER:
      return {
        ...state,
        users: [...state.users, action.payload],
        waitingCount: [...state.users, action.payload].filter(
          (obj) => obj.awaitingReply === true
        ).length
      }

    case EDIT_USER: {
      const newUsers = state.users.map(function (usr) {
        return usr._id === action.payload._id ? action.payload : usr
      })

      return {
        ...state,
        users: newUsers,
        waitingCount: newUsers.filter((obj) => obj.awaitingReply === true)
          .length
      }
    }

    case DELETE_USER:
      return {
        ...state,
        users: [...state.users.filter((usr) => usr._id !== action.payload)],
        waitingCount: state.users.filter((obj) => obj.awaitingReply === true)
          .length
      }

    default:
      return state
  }
}
