import React, { useContext, useState } from 'react'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'
import IconPicker from '../../../Utilities/IconPicker'

const SuiteButton = () => {
  const { [SETTINGS.SUITE_BUTTON.key]: suiteButton, updateSetting } =
    useContext(SiteConfigContext)
  const [icon, setIcon] = useState(suiteButton.icon)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      text: formData.get('text'),
      link: formData.get('link'),
      icon
    }
    await updateSetting(SETTINGS.SUITE_BUTTON, data)
    toast.success('Suite Button saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Suite Button</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="grid grid-cols-1 md:grid-cols-2 gap-2"
      >
        <input
          aria-label="Suite Button Text"
          id="text"
          name="text"
          defaultValue={suiteButton.text}
          className="input block w-full"
          placeholder="Text"
          required
        />
        <input
          aria-label="Suite Button Link"
          id="link"
          name="link"
          defaultValue={suiteButton.link}
          className="input block w-full"
          placeholder="Link"
          required
        />
        <div className="md:col-span-2 flex gap-2">
          <IconPicker
            value={icon}
            title="Suite Button Icon"
            renderSelected
            collapsed
            onChange={(icon) => setIcon(icon)}
          />
          <button
            type="submit"
            aria-label="Save Suite Button Button"
            className="primary-button w-fit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default SuiteButton
