import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons'

const ExternalLinkIcon = () => {
  return (
    <div className="relative">
      <FontAwesomeIcon
        icon={faExternalLink}
        className="w-3 h-3 absolute -top-2 -right-3 text-black text-opacity-50 dark:text-white dark:text-opacity-50"
      />
    </div>
  )
}

export default ExternalLinkIcon
