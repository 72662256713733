import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons'
import TextWrapper from '../../Utilities/TextWrapper'
import { HeadquartersButton } from '../../Buttons/HeadquartersButton'
import Schedule from '../Schedule'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import Footer from '../Footer'

const StreamInfoPanel = () => {
  const {
    [SETTINGS.STREAM_TITLE.key]: streamTitle,
    [SETTINGS.LIVESTREAM_LIVE.key]: livestreamLive,
    activeStreamer
  } = useContext(SiteConfigContext)

  return (
    <div className="flex flex-1 bg-white/50 dark:bg-black/70 pt-1.5 md:pt-4 lg:pt-6 px-1.5 md:px-4 lg:px-6 pb-2">
      <div className="flex flex-col gap-0.5 md:gap-4 container mx-auto">
        <div className="flex items-center gap-4 md:pb-2">
          <div className="shrink-0">
            <img
              className={`streamer-pic ${
                livestreamLive ? 'border-red-600' : 'border-neutral-600'
              }`}
              src={activeStreamer?.photo}
              alt=""
            />
          </div>
          <div className="flex flex-col">
            <h2 className="mb-0 leading-none">
              <TextWrapper text={streamTitle} allGreen />
            </h2>
            <h4 className="mb-0">{activeStreamer?.name}</h4>
          </div>
        </div>
        <Schedule />
        <Headquarters />
        <div className="hidden md:block mt-auto">
          <Footer />
        </div>
      </div>
    </div>
  )
}

const Headquarters = () => {
  const { [SETTINGS.HEADQUARTERS.key]: headquarters } =
    useContext(SiteConfigContext)

  return (
    <div className="hidden md:flex flex-col">
      <div className="col-span-3 mb-2 w-full">
        <h3>
          <FontAwesomeIcon icon={faBuilding} className="mr-3 h-6" />
          Headquarters
        </h3>
      </div>
      <div className="cards max-w-full gap-2 grid grid-cols-3 items-stretch">
        {headquarters.map((HQ, index) => {
          return (
            <HeadquartersButton
              key={index}
              title={HQ.title}
              link={HQ.link}
              content={HQ.content}
              icon={HQ.icon}
              primary={HQ.primary}
            />
          )
        })}
      </div>
    </div>
  )
}

export default StreamInfoPanel
