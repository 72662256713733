import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { setPageTitle } from '../../../utils/utilities'
import { toast } from 'react-toastify'
import { deleteCache, getCache } from '../../../utils/APIs/cache'

const Cache = () => {
  setPageTitle('Admin | Cache')

  return (
    <div className="flex h-full w-full">
      <div className="flex flex-col h-full w-full">
        <div className="shrink">
          <h2 className="mb-1">View Cache</h2>
          <ViewCache />
        </div>
        <div className="shrink">
          <h2 className="mb-1">Bust Cache</h2>
          <BustCacheForm />
        </div>
      </div>
    </div>
  )
}

const BustCacheForm = () => {
  const [loading, setLoading] = useState(false)
  const [setting, setSetting] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const response = await deleteCache(setting)
    if (response && response.status === 200) {
      setLoading(false)
      setSetting('')
      toast.success('Cache busted.')
    }
  }

  return (
    <div className="mb-auto flex gap-2">
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className="relative flex flex-grow items-stretch focus-within:z-10 gap-2"
      >
        <input
          aria-label="Chat form input"
          value={setting}
          onChange={(e) => setSetting(e.target.value)}
          className="grow input"
          placeholder="Setting"
          required
        />
        <button
          type="submit"
          aria-label="Submit message"
          className="primary-button relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0"
        >
          Submit
        </button>
      </form>
      {loading && (
        <div className="flex items-center whitespace-nowrap bg-green-500 px-4 py-2 font-bold text-white transition-all hover:bg-green-600 active:bg-green-700">
          <FontAwesomeIcon
            className="mr-2 h-5 cursor-pointer"
            icon={faSpinner}
            spin
          />
        </div>
      )}
    </div>
  )
}

const ViewCache = () => {
  const [keys, setKeys] = useState([])
  const [theCache, setTheCache] = useState('')

  useEffect(() => {
    getCacheKeys()
  }, [])

  const getCacheKeys = async () => {
    const response = await getCache()
    if (response && response.status === 200) {
      setKeys(response.data)
    }
  }

  const handleClick = async (setting) => {
    const response = await getCache(setting)
    if (response && response.status === 200) {
      setTheCache(JSON.stringify(response.data))
    }
  }

  return (
    <div className="mb-auto flex flex-col gap-2">
      <div className="flex gap-1">
        {keys.map((key) => {
          return (
            <button
              key={key}
              className="primary-button"
              onClick={() => handleClick(key)}
            >
              {key}
            </button>
          )
        })}
      </div>
      {theCache && (
        <textarea
          className="flex h-full w-full"
          value={theCache}
          onChange={(e) => handleClick(e.target.value)}
          disabled
        />
      )}
      <div />
    </div>
  )
}

export default Cache
