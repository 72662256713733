import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import UserBadges from '../../Utilities/UserBadges'
import UserInfoWidget from '../UserInfoWidget'
import { modNameChanger } from '../../../utils/utilities'
import PropTypes from 'prop-types'

const UserName = ({
  message,
  user,
  expandedUserInfoWidget,
  setExpandedUserInfoWidget,
  modView
}) => {
  const navigate = useNavigate()

  const handleOnClick = () =>
    modView
      ? navigate(`/mod/private-messages/user/${user._id}`)
      : setExpandedUserInfoWidget(
          expandedUserInfoWidget === message._id ? null : message._id
        )

  return (
    <Fragment>
      <span
        className={`text-${message.user.userService.profile.color} mr-1 font-bold cursor-pointer`}
        onClick={handleOnClick}
      >
        <UserBadges
          role={message.user.role.role}
          user={message.user}
          registrationDate={message.user.registrationDate}
        />
        {modNameChanger(message)}:
      </span>
      {!modView && expandedUserInfoWidget === message._id && (
        <UserInfoWidget
          user={user}
          message={message}
          setExpandedUserInfoWidget={setExpandedUserInfoWidget}
        />
      )}
    </Fragment>
  )
}

UserName.propTypes = {
  message: PropTypes.object.isRequired,
  user: PropTypes.object,
  expandedUserInfoWidget: PropTypes.string,
  setExpandedUserInfoWidget: PropTypes.func,
  modView: PropTypes.bool
}

export default UserName
