import axios from 'axios'

export const getModChatIsOpen = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/admin/mod-chats/is-open/command`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )

    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const toggleModChatIsOpen = async () => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/admin/mod-chats/is-open/command`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )

    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const postPrivateMessage = async ({
  message,
  itemNumber = null,
  toUser = null
}) => {
  const data = {
    message
  }
  if (toUser) {
    data.toUser = toUser
  }
  if (itemNumber) {
    data.itemNumber = itemNumber
  }
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/private-messages`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPrivateMessageThread = async ({ userId, loadMore, search }) => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_PRIVATE_MESSAGES_API_URL
      }/private-messages/thread?${userId ? `&user=${userId}` : ''}${
        loadMore ? `&loadMore=${loadMore}` : ''
      }${search ? `&search=${search}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPrivateMessagesUsers = async ({
  loadMore,
  search,
  awaitingReply,
  pinned
}) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/users?${
        loadMore ? `&loadMore=${loadMore}` : ''
      }${search ? `&search=${search}` : ''}${
        awaitingReply ? `&awaitingReply=${awaitingReply}` : ''
      }${pinned ? `&pinned=${pinned}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const updatePrivateMessage = async ({ id, message }) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/private-messages/${id}`,
      {
        message
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const updatePrivateMessageUser = async ({ id, data }) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/users/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const deletePrivateMessage = async ({ id }) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/private-messages/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPrivateMessageUserCounts = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/users/counts`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getPrivateMessageHistoricalCounts = async (start, end) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_PRIVATE_MESSAGES_API_URL}/private-messages/counts/${start}/${end}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
