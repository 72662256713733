import axios from 'axios'
import { API } from '../ENV'

export const postRole = async (role) => {
  try {
    const response = await axios.post(`${API}/role/`, {
      role: role.toLowerCase()
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getRoles = async () => {
  try {
    const response = await axios.get(`${API}/role/`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
