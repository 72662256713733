import React, { useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import RegistrationDate from '../../Utilities/RegistrationDate'
import CustomBadge from '../../../graphics/Badge/CustomBadge'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as SETTINGS from '../../../constants/settings'
import * as ROLES from '../../../constants/roles'
import { getAdditionalSubs, modNameChanger } from '../../../utils/utilities'
import { AuthenticationContext } from '../../../context/authenticationContext'
import * as PRODUCTS from '../../../constants/products'

const UserInfoWidget = ({ message, user, setExpandedUserInfoWidget }) => {
  const { getSubType } = useContext(AuthenticationContext)
  const { productsService, [SETTINGS.MEMBERSHIPS.key]: memberships } =
    useContext(SiteConfigContext)
  const [marginTop, setMarginTop] = useState('0px')
  const elementRef = useRef(null)
  const [subType] = useState(getSubType(message.user))

  useEffect(() => {
    const element = elementRef.current
    const rect = element.getBoundingClientRect()
    const windowHeight = window.innerHeight
    const chatForm = document
      .getElementById('chat-form')
      .getBoundingClientRect()
    rect.y + rect.height > windowHeight - chatForm.height &&
      setMarginTop(
        `${windowHeight - (rect.y + rect.height + chatForm.height)}px`
      )
  }, [])

  const renderName = () => {
    const isStaff = hasStaffPrivilege(user)
    const userName = message.user.userService.profile.name

    if (isStaff) {
      return (
        <Link
          className="link"
          to={`/mod/private-messages?id=${message.user.userService._id}`}
        >
          {userName}
        </Link>
      )
    } else {
      return modNameChanger(message)
    }
  }

  return (
    <div
      className="user-info-widget absolute z-10 p-3 bg-custom-gradient w-full rounded-xl shadow-lg shadow-neutral-950/40 dark:shadow-neutral-950/60"
      style={{ marginTop }}
      ref={elementRef}
    >
      <button
        className="absolute top-0.5 right-1.5"
        onClick={() => setExpandedUserInfoWidget(null)}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="h-4 transition-all text-black/50 dark:text-white/50 hover:text-red-500"
        />
      </button>
      <h3 className="flex items-center font-bold">{renderName()}</h3>
      <Titles
        role={message.user.role.role}
        subType={subType}
        memberships={memberships}
      />
      <RegistrationDate message={message} />
      <Memberships
        productsService={productsService}
        userSubscriptions={message.user.userService.subscriptions}
      />
    </div>
  )
}

UserInfoWidget.propTypes = {
  message: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setExpandedUserInfoWidget: PropTypes.func.isRequired
}

const Titles = ({ role, subType, memberships }) => {
  if (role === ROLES.HOST) {
    return <h4>Host</h4>
  }
  if (role === ROLES.MODERATOR || role === ROLES.ADMIN) {
    return <h4>Moderator</h4>
  }

  if (role === ROLES.SUBSCRIBER) {
    return (
      <>
        {memberships.main.map((bdg, index) => {
          return subType === bdg.subType && <h4>{bdg.title}</h4>
        })}
      </>
    )
  }

  return null
}

Titles.propTypes = {
  role: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  memberships: PropTypes.object
}

const Memberships = ({ productsService, userSubscriptions }) => {
  const allMemberSubs = [...getAdditionalSubs(userSubscriptions)]
  const badgesForMember = []

  // Process PRODUCTS.BUNDLE
  badgesForMember.push(
    ...productsService[PRODUCTS.BUNDLE].filter((product) =>
      allMemberSubs.includes(product.itemNumber)
    )
  )

  // Process PRODUCTS.FUNNEL
  productsService[PRODUCTS.FUNNEL].forEach((product) => {
    const matches = product.products
      .map((id) =>
        productsService[PRODUCTS.OTHER_PRODUCTS].find((each) => each._id === id)
      )
      .filter((match) => match && allMemberSubs.includes(match.itemNumber))

    badgesForMember.push(...matches)
  })

  if (badgesForMember.length < 1) {
    return <div />
  }

  return (
    <div className="mt-2 gap-1 flex flex-col">
      <p className="font-bold">Memberships</p>
      <div className="gap-1 flex items-center">
        {badgesForMember.map((product, index) => (
          <div
            key={index}
            className="w-[56px] h-[56px] flex items-center justify-center border border-brand-secondary-300 dark:border-brand-secondary-600 rounded-full shadow-inner dark:shadow-black/60 bg-white dark:bg-neutral-900"
          >
            <img
              className="w-[40px] h-[40px]"
              src={product.graphics.icon}
              title={product.name}
              alt={product.name}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

Memberships.propTypes = {
  userSubscriptions: PropTypes.array.isRequired,
  memberships: PropTypes.object
}

export default UserInfoWidget
