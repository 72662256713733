export const GLOBAL_SOCKET_EVENTS = {
  CONNECTION: 'connection',
  CONNECTED: 'connected',
  CONNECT_ERROR: 'connect_error',
  DISCONNECT: 'disconnect',
  ERROR: 'error',
  UNAUTHORIZED: 'unauthorized'
}

export const ANNOUNCEMENT_EVENTS = {
  POST: 'POST_ANNOUNCEMENT',
  EDIT: 'EDIT_ANNOUNCEMENT',
  DELETE: 'DELETE_ANNOUNCEMENT',
  READ: 'READ_ANNOUNCEMENT',
  READ_ALL: 'READ_ALL_ANNOUNCEMENTS'
}

export const SOCKET_EVENTS = {
  BOT_MESSAGES: 'BOT_MESSAGES',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_POLL: 'UPDATE_POLL',
  PUT_USER: 'PUT_USER',
  VIEW_COUNT: 'VIEW_COUNT'
}

export const TRADE_EVENTS = {
  POST: 'post trade',
  PUT: 'put trade',
  DELETE: 'delete trade'
}

export const PUBLIC_MESSAGE_EVENTS = {
  POST: 'POST_PUBLIC_MESSAGE',
  PUT: 'PUT_PUBLIC_MESSAGE',
  DELETE: 'DELETE_PUBLIC_MESSAGE'
}

export const PRIVATE_MESSAGE_EVENTS = {
  POST: 'POST_PRIVATE_MESSAGE',
  EDIT: 'EDIT_PRIVATE_MESSAGE',
  DELETE: 'DELETE_PRIVATE_MESSAGE',
  TOGGLE_MOD_CHAT: 'TOGGLE_MOD_CHAT'
}
