import React, { useState } from 'react'
import { setPageTitle, sanitizeText } from '../../../utils/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrash } from '@fortawesome/pro-solid-svg-icons'
import PostAnnouncementForm from '../../Forms/PostAnnouncementForm'
import AnnouncementProvider from '../../AnnouncementProvider/AnnouncementProvider'
import { deleteAnnouncement } from '../../../utils/APIs/announcements'
import { toast } from 'react-toastify'

const PostAnnouncement = () => {
  setPageTitle('Post Announcement')
  const [announcementToEdit, setAnnouncementToEdit] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(null)

  const handleDelete = async () => {
    if (!confirmDelete) return
    const response = await deleteAnnouncement(confirmDelete)
    if (response && response.status === 200) {
      setConfirmDelete(null)
      toast.success('Announcement deleted successfully.')
    } else {
      toast.error('Failed to delete announcement.')
    }
  }

  return (
    <div className="flex h-full w-full">
      <div className="flex mr-auto w-full flex-col gap-4">
        <PostAnnouncementForm
          edit={announcementToEdit ? true : false}
          prefill={announcementToEdit}
        />
        <div className="pb-4">
          <AnnouncementProvider limit={5}>
            {({
              announcements,
              loading,
              error,
              hasMore,
              fetchAnnouncements
            }) => {
              if (loading) return <h3>Loading...</h3>
              if (error) return <h3>{error}</h3>
              return (
                <ul className="flex flex-col gap-2 divide-y dark:divide-neutral-700">
                  {announcements.map((announcement) => (
                    <li
                      key={announcement._id}
                      className="flex flex-row gap-2 items-center py-2"
                    >
                      <div className="flex flex-col gap-2 grow">
                        <h3>{announcement.title}</h3>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: sanitizeText(announcement.message)
                          }}
                        ></p>
                        {announcement.cta && (
                          <a
                            href={announcement.cta.url}
                            target="_blank"
                            className="w-fit mr-auto primary-button"
                            rel="noreferrer"
                          >
                            <span className="whitespace-nowrap">
                              {announcement.cta.text || 'Go'}
                            </span>
                          </a>
                        )}
                      </div>
                      <div className="flex gap-2 items-center">
                        <button
                          className="mod-button w-7 h-7 flex items-center justify-center"
                          onClick={() => setAnnouncementToEdit(announcement)}
                          title="Edit Message"
                        >
                          <FontAwesomeIcon
                            className="h-3.5 cursor-pointer text-green-600 dark:text-green-400"
                            icon={faPenToSquare}
                          />
                        </button>
                        <button
                          className={`${
                            confirmDelete === announcement._id
                              ? 'hidden'
                              : 'block'
                          } mod-button w-7 h-7 flex items-center justify-center`}
                          onClick={() => setConfirmDelete(announcement._id)}
                          title="Delete Message"
                        >
                          <FontAwesomeIcon
                            className="h-3.5 cursor-pointer text-red-600 dark:text-red-400"
                            icon={faTrash}
                          />
                        </button>
                        {confirmDelete === announcement._id && (
                          <div className="flex gap-2">
                            <button
                              className="neutral-button w-fit"
                              onClick={() => setConfirmDelete(null)}
                              title="Cancel Delete"
                            >
                              Cancel
                            </button>
                            <button
                              className="primary-button w-fit whitespace-nowrap"
                              onClick={handleDelete}
                              title="Confirm Delete"
                            >
                              Confirm Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </li>
                  ))}
                  <button
                    disabled={loading || !hasMore}
                    onClick={fetchAnnouncements}
                    className="neutral-button w-fit"
                  >
                    {hasMore ? 'Load More' : 'All Data Loaded.'}
                  </button>
                </ul>
              )
            }}
          </AnnouncementProvider>
        </div>
      </div>
    </div>
  )
}

export default PostAnnouncement
