import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as SETTINGS from '../../../constants/settings'

const SuiteButton = () => {
  const { [SETTINGS.SUITE_BUTTON.key]: suiteButton } =
    useContext(SiteConfigContext)

  return (
    <Link to={suiteButton.link} target="_blank" rel="noopener noreferrer">
      <button className="header-button suite-button">
        <span className="hidden md:block">{suiteButton.text}</span>
        {suiteButton.icon && (
          <FontAwesomeIcon
            icon={suiteButton.icon}
            className="header-button-icon"
            aria-hidden="true"
          />
        )}
      </button>
    </Link>
  )
}

export default SuiteButton
