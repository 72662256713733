import React, { useContext, useMemo } from 'react'
import Header from '../../components/Layout/Header'
import Footer from '../../components/Layout/Footer'
import UserSettings from './UserSettings'
import Information from './Information'
import Free from './Free'
import { AuthenticationContext } from '../../context/authenticationContext'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { setPageTitle } from '../../utils/utilities'

const Headquarters = () => {
  setPageTitle('Headquarters')
  const { user } = useContext(AuthenticationContext)
  const { freeEnabled } = useContext(SiteConfigContext)

  const hasSignedUp =
    user && user.onboarding.terms && user.userService.profile.name.length > 0

  return (
    <main className="ios-safari-hack flex h-screen max-h-screen flex-col overflow-hidden bg-custom-gradient">
      <Header />
      <div className="flex flex-col md:flex-row flex-1 overflow-y-auto bg-white/50 dark:bg-black/70 md:gap-4 md:px-4 md:justify-center">
        {user && hasSignedUp && (
          <Column>
            <UserSettings />
          </Column>
        )}
        {freeEnabled && !hasSignedUp && (
          <Column>
            <Free />
          </Column>
        )}
        <Column>
          <Information />
        </Column>
      </div>
      <div className="bg-white/50 dark:bg-black/70 p-2">
        <Footer />
      </div>
    </main>
  )
}

const Column = ({ children }) => {
  return (
    <div className="flex flex-col gap-4 md:mt-4 md:mb-auto p-4 md:p-10 w-full md:w-96 bg-white dark:bg-black rounded md:shadow-lg">
      {children}
    </div>
  )
}

export default Headquarters
