import React, { useContext, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import './styles.css'
import { toast } from 'react-toastify'
import { setPageTitle } from '../../../../utils/utilities'

const TermsAndConditions = () => {
  setPageTitle('Content | Terms & Conditions')
  const {
    [SETTINGS.TERMS_AND_CONDITIONS.key]: terms,
    [SETTINGS.COPYRIGHT.key]: copyright,
    updateSetting
  } = useContext(SiteConfigContext)
  const [tmpTerms, setTmpTerms] = useState(terms)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const copyright = formData.get('copyright')
    await updateSetting(SETTINGS.TERMS_AND_CONDITIONS, tmpTerms)
    toast.success('Terms & Conditions saved!')
    await updateSetting(SETTINGS.COPYRIGHT, copyright)
    toast.success('Copyright saved!')
  }

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      className="flex flex-col gap-2"
    >
      <ReactQuill
        id="terms"
        name="terms"
        style={{ height: '420px', width: '100%' }}
        aria-label="Terms & Conditions"
        placeholder="Terms & Conditions"
        defaultValue={terms}
        onChange={setTmpTerms}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean']
          ]
        }}
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'link'
        ]}
      />
      <div className="flex shadow-sm mt-8 sm:mt-0">
        <span className="inline-flex items-center rounded-l border-r-0 py-2 px-3 text-black dark:text-neutral-100 border border-neutral-300 dark:border-neutral-700 dark:bg-neutral-800">
          ©
        </span>
        <input
          aria-label="Copyright"
          id="copyright"
          name="copyright"
          defaultValue={copyright}
          className="block w-full min-w-0 flex-1 rounded-none rounded-r input"
          placeholder="Copyright"
          required
        />
      </div>
      <button
        type="submit"
        aria-label="Submit message"
        className="primary-button w-fit"
      >
        Save
      </button>
    </form>
  )
}

export default TermsAndConditions
