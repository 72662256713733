import React from 'react'
import CustomButton from './CustomButton.js'
import SuiteButton from './SuiteButton.js'
import WelcomeVideo from './WelcomeVideo.js'
import StreamOffline from './StreamOffline.js'
import PasswordReset from './PasswordReset.js'
import PhoneNumbers from './PhoneNumbers.js'
import { setPageTitle } from '../../../../utils/utilities.js'

const ContentGeneral = () => {
  setPageTitle('Content | General')
  return (
    <div className="flex flex-col gap-2 mb-5">
      <StreamOffline />
      <PasswordReset />
      <PhoneNumbers />
      <SuiteButton />
      <CustomButton />
      <WelcomeVideo />
    </div>
  )
}

export default ContentGeneral
