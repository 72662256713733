import { useEffect, useState } from 'react'

const useIsPortraitReady = () => {
  const [isPortraitReady, setIsPortraitReady] = useState(false)

  useEffect(() => {
    // Update portrait ready
    const onPortraitReady = () => {
      setIsPortraitReady(true)
    }
    // Add listener for portraitReady
    document.addEventListener('portraitReady', onPortraitReady)

    // Clean up listener on unmount
    return () => document.removeEventListener('portraitReady', onPortraitReady)
  }, [])

  return isPortraitReady
}

export default useIsPortraitReady
