import React, { useContext } from 'react'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'

const Logo = () => {
  const { [SETTINGS.IMAGES.key]: images } = useContext(SiteConfigContext)

  return (
    <>
      <img
        className="dark:hidden my-auto h-full object-contain"
        src={images?.logo}
        alt="Logo"
      />
      <img
        className="hidden dark:block my-auto h-full object-contain"
        src={images?.logoDark}
        alt="Dark Logo"
      />
    </>
  )
}

export default Logo
