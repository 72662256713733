import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowTurnDownRight } from '@fortawesome/pro-solid-svg-icons'
import { modNameChanger } from '../../../utils/utilities'
import PropTypes from 'prop-types'

const ReplyTo = ({ user, message }) => {
  const processTagging = (name) =>
    user && user.userService.profile.name === name
      ? 'bg-blue-200 dark:bg-blue-900 text-neutral-800 dark:text-neutral-200'
      : 'text-neutral-600 dark:text-neutral-400'

  return (
    <div
      className={`px-1 py-0.5 mx-2 font-medium text-xs truncate ${processTagging(
        message.user.userService.profile.name
      )}`}
      title={`Replying to ${modNameChanger(message)}: ${message.message}`}
    >
      <FontAwesomeIcon
        className="mr-1 h-2 rotate-90"
        icon={faArrowTurnDownRight}
      />
      {`Replying to ${modNameChanger(message)}: ${message.message}`}
    </div>
  )
}

ReplyTo.propTypes = {
  user: PropTypes.object,
  message: PropTypes.object.isRequired
}

export default ReplyTo
