import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Navigate } from 'react-router-dom'
import { AuthenticationContext } from '../context/authenticationContext'
import * as ROLES from '../constants/roles'

export const hasStaffPrivilege = (user, viporbot = false) => {
  if (!user) {
    return false
  }

  return (
    user.role.role === ROLES.ADMIN ||
    user.role.role === ROLES.HOST ||
    user.role.role === ROLES.MODERATOR ||
    (viporbot && (user.role.role === ROLES.VIP || user.role.role === ROLES.BOT))
  )
}

const RequireRole = ({ role, children }) => {
  const { user } = useContext(AuthenticationContext)

  if (!user || !user.role.role) {
    return <Navigate to="/" />
  }

  switch (role) {
    case ROLES.ADMIN:
      if (user.role.role !== ROLES.ADMIN) {
        return <Navigate to="/" />
      }
      break
    case 'staff':
    default:
      if (!hasStaffPrivilege(user)) {
        return <Navigate to="/" />
      }

      break
  }

  return children
}

RequireRole.propTypes = {
  role: PropTypes.string,
  children: PropTypes.object
}

export default RequireRole
