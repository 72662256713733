import { PORTRAIT_READY } from '../constants/actions/analyticsActions'

export const initialState = {
  portraitIsReady: false,
  portraitInstance: undefined
}

export function analyticsReducer(state, action) {
  switch (action.type) {
    case PORTRAIT_READY:
      return {
        ...state,
        portraitIsReady: action.payload,
        portraitInstance: window.Portrait
      }
    default:
      return state
  }
}
