import React from 'react'
import PropTypes from 'prop-types'

const ProgressBar = ({ progress }) => {
  return (
    <div className="flex w-full justify-end p-1 pb-0">
      <div className="h-4 w-full rounded-full bg-neutral-200 dark:bg-neutral-700">
        <div
          className="h-full rounded-l-full rounded-r-none bg-neutral-500"
          style={{ width: progress + '%' }}
        />
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired
}

export default ProgressBar
