import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import ContentNav from '../ContentNav'

const ContentWrapper = ({ path, defaultPath, navOptions }) => {
  const navigate = useNavigate()

  useEffect(() => {
    window.location.pathname === path && navigate(`${path}/${defaultPath}`)
  }, [])

  return (
    <div className="flex flex-col h-full w-full">
      <ContentNav navOptions={navOptions} />
      <div className="flex flex-col w-full">
        <Outlet />
      </div>
    </div>
  )
}

export default ContentWrapper
