import React, { useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../context/authenticationContext'
import Footer from '../../components/Layout/Footer'
import WistiaPlayer from '../../components/Utilities/WistiaPlayer'
import Logo from '../../graphics/Logo'
import { putUser } from '../../utils/APIs/users'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'
import { setPageTitle } from '../../utils/utilities'

const WelcomeVideo = () => {
  setPageTitle('Welcome')
  const { user, updateUser } = useContext(AuthenticationContext)
  const { [SETTINGS.WELCOME_VIDEO.key]: welcomeVideo } =
    useContext(SiteConfigContext)

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const handleSubmit = async (e) => {
    e.preventDefault()
    const response = await putUser(user._id, 'onboarding.welcome', true)

    if (response && response.status === 200) {
      updateUser(response.data)
      navigate(from, { replace: true })
    }
  }

  return (
    <main className="ios-safari-hack bg-custom-gradient flex flex-col h-screen overflow-y-auto">
      <div className="mx-auto md:my-auto">
        <div className="h-8 md:h-12 my-4">
          <Logo />
        </div>
      </div>
      <div className="mb-auto">
        <div className="container w-full mx-auto p-2">
          <div className="flex flex-col justify-between items-center gap-4">
            <div className="flex flex-col w-full justify-between items-center gap-2 bg-white dark:bg-neutral-900 px-4 py-2 md:rounded-lg">
              <h1 className="text-center font-bold">Welcome!</h1>
              <div className="bg-neutral-100 dark:bg-black px-3 py-2 rounded-md">
                <p className="w-fit max-w-[30em]">{welcomeVideo.blurb}</p>
              </div>
              <div className="flex w-full h-full shadow-lg max-w-[1008px]">
                <div className="w-full h-full">
                  <WistiaPlayer
                    videoId={welcomeVideo.wistiaId}
                    wrapper="welcome-video"
                  />
                </div>
              </div>
              <form
                onSubmit={handleSubmit}
                autoComplete="off"
                className="flex flex-col gap-2"
              >
                <button
                  type="submit"
                  aria-label="Submit message"
                  className="my-2 primary-button"
                >
                  Enter The Stream
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  )
}

export default WelcomeVideo
