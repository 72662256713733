import React, { useState, useEffect, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const Countdown = ({ endDate, endEvent = null }) => {
  const [printDate, setPrintDate] = useState('')

  const timerRef = useRef(0)
  const timerCallback = useCallback(() => {
    const timeRemaining = getTimeRemaining(endDate)
    if (timeRemaining <= -1) {
      if (endEvent) {
        endEvent()
      }
      clearInterval(timerRef.current)
    }
  }, [endDate])

  useEffect(() => {
    timerRef.current = setInterval(timerCallback, 1000)

    return () => {
      clearInterval(timerRef.current)
    }
  }, [endDate])

  useEffect(() => {
    const timeRemaining = getTimeRemaining(endDate)
    if (timeRemaining <= -1) {
      if (endEvent) {
        endEvent()
      }
      clearInterval(timerRef.current)
    }
  }, [endDate])

  const getTimeRemaining = (date) => {
    setPrintDate(testString(date))
    const seconds = moment(date).diff(moment(), 'seconds')
    if (seconds > 60) {
      const minutes = moment(date).diff(moment(), 'minutes')
      if (minutes > 60) {
        const hours = moment(date).diff(moment(), 'hours')
        return hours
      } else {
        return minutes
      }
    } else {
      return seconds
    }
  }

  const testString = (date) => {
    const now = moment()
    const end = moment(date)

    const duration = moment.duration(end.diff(now))

    // Get Days and subtract from duration
    const days = duration.days()
    duration.subtract(days, 'days')

    // Get hours and subtract from duration
    const hours = duration.hours()
    duration.subtract(hours, 'hours')

    // Get Minutes and subtract from duration
    const minutes = duration.minutes()
    duration.subtract(minutes, 'minutes')

    // Get seconds
    const seconds = duration.seconds()

    let built = ''
    built += days > 0 ? `${days}d ` : ''
    built += days > 0 || hours > 0 ? `${('0' + hours).slice(-2)}h ` : ''
    built +=
      days > 0 || hours > 0 || minutes > 0
        ? `${('0' + minutes).slice(-2)}m `
        : ''
    built +=
      days > 0 || hours > 0 || minutes > 0 || seconds > 0
        ? `${('0' + seconds).slice(-2)}s`
        : ''

    if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
      built = 'Times Up!'
    }

    return built
  }

  return <p className="text-sm">{printDate}</p>
}

Countdown.propTypes = {
  endDate: PropTypes.string,
  endEvent: PropTypes.func
}

export default Countdown
