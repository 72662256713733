import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
  faTrashCan,
  faPencil
} from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../../../constants/settings'
import { HeadquartersButton } from '../../../Buttons/HeadquartersButton'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import IconPicker from '../../../Utilities/IconPicker'
import { toast } from 'react-toastify'
import { setPageTitle } from '../../../../utils/utilities'

const Headquarters = () => {
  setPageTitle('Configuration | Headquarters')
  const {
    isLoading,
    [SETTINGS.HEADQUARTERS.key]: headquarters,
    updateSetting
  } = useContext(SiteConfigContext)
  const [currentEdit, setCurrentEdit] = useState()

  const handleAddButton = async (data) => {
    if (currentEdit >= 0) {
      const copy = headquarters
      copy.splice(currentEdit, 1, data)
      await updateSetting(SETTINGS.HEADQUARTERS, copy)
      setCurrentEdit(undefined)
      toast.success('Updated HQ Button!')
    } else {
      await updateSetting(SETTINGS.HEADQUARTERS, [...headquarters, data])
      toast.success('Created HQ Button!')
    }
  }

  const handleEditButton = (index) => {
    setCurrentEdit(index)
  }

  const handleDeleteButton = async (index) => {
    const copy = headquarters
    copy.splice(index, 1)
    await updateSetting(SETTINGS.HEADQUARTERS, copy)
    toast.success('Deleted HQ Button!')
  }

  if (isLoading) {
    return (
      <h1 className="flex items-center">
        Loading...{' '}
        <FontAwesomeIcon
          icon={faSpinner}
          className="h-4 w-4 ml-2 animate-spin"
        />
      </h1>
    )
  }

  return (
    <div className="flex flex-col gap-4">
      <RenderHQs
        headquarters={headquarters}
        handleEdit={handleEditButton}
        handleDelete={handleDeleteButton}
        updateSetting={updateSetting}
      />
      <AddHQButtonForm
        handleAddButton={handleAddButton}
        prefill={headquarters[currentEdit]}
      />
    </div>
  )
}

const RenderHQs = ({
  headquarters,
  handleEdit,
  handleDelete,
  updateSetting
}) => {
  const [placements, setPlacements] = useState([])
  const [draggingIndex, setDraggingIndex] = useState(null)

  useEffect(() => {
    setPlacements([...headquarters])
  }, [headquarters])

  const handleDragStart = (index) => {
    setDraggingIndex(index)
  }

  const handleDragOver = (index) => {
    if (draggingIndex === null || draggingIndex === index) return
    const updatedPlacements = [...placements]
    const draggedItem = updatedPlacements.splice(draggingIndex, 1)[0]
    updatedPlacements.splice(index, 0, draggedItem)
    setDraggingIndex(index)
    setPlacements(updatedPlacements)
  }

  const handleDragEnd = () => {
    setDraggingIndex(null)
    updateSetting(SETTINGS.HEADQUARTERS, placements)
  }

  return (
    <div className="cards max-w-full gap-2 flex md:grid flex-col md:grid-cols-3 items-stretch">
      {placements.map((button, index) => {
        return (
          <div
            key={index}
            draggable={true}
            onDragStart={() => handleDragStart(index)}
            onDragOver={(e) => e.preventDefault()}
            onDrop={() => handleDragOver(index)}
            onDragEnd={handleDragEnd}
            className={`flex mb-2  ${
              button.primary ? 'md:col-span-3' : 'md:col-span-1'
            }`}
          >
            <HeadquartersButton
              title={button.title}
              link={button.link}
              content={button.content}
              icon={button.icon || null}
              primary={button.primary}
            />
            <div className="flex flex-col gap-2">
              <EditButton handleEdit={() => handleEdit(index)} />
              <DeleteButton handleDelete={() => handleDelete(index)} />
            </div>
          </div>
        )
      })}
    </div>
  )
}

RenderHQs.propTypes = {
  headquarters: PropTypes.array,
  handleEdit: PropTypes.func,
  handleDelete: PropTypes.func,
  updateSetting: PropTypes.func.isRequired
}

const AddHQButtonForm = ({ handleAddButton, prefill }) => {
  const [title, setTitle] = useState('')
  const [link, setLink] = useState('')
  const [content, setContent] = useState('')
  const [primary, setPrimary] = useState(false)
  const [icon, setIcon] = useState(null)
  const [showIcon, setShowIcon] = useState(false)

  useEffect(() => {
    setTitle(prefill?.title || '')
    setLink(prefill?.link || '')
    setContent(prefill?.content || '')
    setPrimary(prefill?.primary || false)
    setIcon(prefill?.icon || null)
    setShowIcon(prefill?.showIcon || false)
  }, [prefill])

  const handleSubmit = async (e) => {
    e.preventDefault()
    handleAddButton({
      title,
      link,
      content,
      icon,
      primary,
      showIcon
    })
    setTitle('')
    setLink('')
    setContent('')
    setPrimary(false)
    setShowIcon(false)
    setIcon(null)
  }

  return (
    <div className="w-full flex flex-col md:flex-row gap-4 bg-neutral-100 dark:bg-neutral-900 p-4 border dark:border-neutral-800">
      <div className="md:w-1/2 shrink-0">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="w-full flex flex-col gap-4">
            <h2>Add a New Headquarters Button</h2>
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">Title</label>
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">Link</label>
              <input
                value={link}
                onChange={(e) => setLink(e.target.value)}
                className="input"
                type="url"
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">Content</label>
              <input
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="input"
                required
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="dark:text-neutral-100">Full-width</label>
              <input
                type="checkbox"
                checked={primary}
                onChange={() => setPrimary(!primary)}
              />
            </div>
            <div className="flex items-center gap-2">
              <label className="dark:text-neutral-100">Show Icon</label>
              <input
                type="checkbox"
                checked={showIcon}
                onChange={() => {
                  if (showIcon) {
                    // We are currently showing the icon
                    // We should set the icon back to null
                    setIcon(null)
                  }
                  setShowIcon(!showIcon)
                }}
              />
            </div>
            {showIcon && (
              <div className="mb-4 flex gap-2">
                <IconPicker
                  value={icon}
                  title="Card/Button Icon"
                  renderSelected
                  onChange={setIcon}
                />
              </div>
            )}
            <div className="flex flex-col">
              <input type="submit" className="primary-button" value="Submit" />
            </div>
          </div>
        </form>
      </div>
      <div className={`overflow-y-auto ${primary ? 'md:w-1/2' : 'md:w-1/3'}`}>
        <h2 className="mb-4">Preview</h2>
        <div>
          <HeadquartersButton
            title={title}
            link={link}
            content={content}
            icon={icon}
            primary={primary}
          />
        </div>
      </div>
    </div>
  )
}

AddHQButtonForm.propTypes = {
  handleAddButton: PropTypes.func,
  prefill: PropTypes.object
}

const DeleteButton = ({ handleDelete }) => {
  return (
    <button
      className="flex items-center p-2 rounded-full bg-red-500 dark:bg-red-700 text-white w-8 ml-2"
      type="button"
      onClick={handleDelete}
    >
      <FontAwesomeIcon icon={faTrashCan} className="w-4 h-4" />
    </button>
  )
}

DeleteButton.propTypes = {
  handleDelete: PropTypes.func
}

const EditButton = ({ handleEdit }) => {
  return (
    <button
      className="flex items-center p-2 rounded-full bg-blue-500 dark:bg-blue-700 text-white w-8 ml-2"
      type="button"
      onClick={handleEdit}
    >
      <FontAwesomeIcon icon={faPencil} className="w-4 h-4" />
    </button>
  )
}

EditButton.propTypes = {
  handleEdit: PropTypes.func
}

export default Headquarters
