import axios from 'axios'
import { ANNOUNCEMENTS } from '../ENV'

/*
  ANNOUNCEMENTS
*/

export const getLatestAnnouncement = async () => {
  try {
    const response = await axios.get(
      `${ANNOUNCEMENTS}/announcements/latest?itemNumber=${process.env.REACT_APP_PRODUCT_CODE}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getUnreadCount = async ({ latest = false }) => {
  try {
    const response = await axios.get(
      `${ANNOUNCEMENTS}/status/unread/count?latest=${latest}&itemNumber=${process.env.REACT_APP_PRODUCT_CODE}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const markAnnouncementAsRead = async (announcementId) => {
  try {
    const response = await axios.post(
      `${ANNOUNCEMENTS}/status/read/${announcementId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const markAllAnnouncementsAsRead = async () => {
  try {
    const response = await axios.post(
      `${ANNOUNCEMENTS}/status/read-all`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const postAnnouncement = async (data) => {
  try {
    const response = await axios.post(`${ANNOUNCEMENTS}/announcements`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export const editAnnouncement = async (data) => {
  try {
    const response = await axios.put(
      `${ANNOUNCEMENTS}/announcements/${data._id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export const deleteAnnouncement = async (id) => {
  try {
    const response = await axios.delete(
      `${ANNOUNCEMENTS}/announcements/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}

export const getAnnouncements = async ({ loadMore, limit }) => {
  try {
    const response = await axios.get(
      `${ANNOUNCEMENTS}/announcements?itemNumber=${
        process.env.REACT_APP_PRODUCT_CODE
      }${loadMore ? `&loadMore=${loadMore}` : ''}${
        limit ? `&limit=${limit}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.error(error)
    return null
  }
}
