import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGhost,
  faPenToSquare,
  faTrash,
  faPaperPlane,
  faHammer
} from '@fortawesome/pro-solid-svg-icons'

import { TRADES, MEMBERS } from '../../../constants/chatRooms'
import { hasStaffPrivilege } from '../../../utils/RequireRole'
import { AuthenticationContext } from '../../../context/authenticationContext'
import EditModal from './EditModal'
import DeleteModal from './DeleteModal'
import ResendModal from './ResendModal'
import ModModal from './ModModal'

const MessageWrapper = ({
  message,
  channel,
  index,
  modView = false,
  children,
  dispatch = null
}) => {
  const [highlight, setHighlight] = useState(false)

  const handleHighlight = (shouldHighlight) => {
    setHighlight(shouldHighlight)
  }

  if (!modView) {
    return children
  }

  return (
    <div className="flex gap-2 w-full max-w-2xl items-start break-words">
      {channel === MEMBERS ? (
        <MembersMessage highlight={highlight} message={message}>
          {children}
        </MembersMessage>
      ) : (
        children
      )}
      <ModControls
        handleHighlight={handleHighlight}
        message={message}
        channel={channel}
        index={index}
        dispatch={dispatch}
      />
    </div>
  )
}

const MembersMessage = ({ highlight, message, children }) => {
  return (
    <div
      className={`flex flex-1 overflow-auto border-x-2 border-transparent w-full ${
        highlight
          ? 'bg-neutral-100 dark:bg-neutral-800 border-neutral-400 dark:border-neutral-700'
          : ''
      }`}
    >
      {message.user.moderation.shadowbanned && (
        <div className="flex items-center justify-around bg-neutral-50 dark:bg-neutral-800 border border-neutral-200 dark:border-neutral-700 p-1 my-1 ml-2 text-white uppercase font-bold rounded">
          <FontAwesomeIcon
            className="h-3.5 text-black dark:text-white"
            icon={faGhost}
          />
        </div>
      )}
      {children}
    </div>
  )
}

const ModControls = ({
  handleHighlight,
  message,
  channel,
  index,
  dispatch
}) => {
  const { user } = useContext(AuthenticationContext)

  const [editModal, setEditModal] = useState(false)
  const [resendModal, setResendModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [modModal, setModModal] = useState(false)

  return (
    <div
      className="flex my-auto gap-2"
      onFocus={() => handleHighlight(true)}
      onBlur={() => handleHighlight(false)}
    >
      {(channel === TRADES || hasStaffPrivilege(user)) && (
        <>
          <button
            tabIndex={index * (channel === TRADES ? 3 : 2) + 1}
            className="mod-button"
            onClick={() => setEditModal(true)}
            title="Edit Message"
            onMouseEnter={(e) => e.target.focus()}
            onMouseLeave={(e) => e.target.blur()}
          >
            <FontAwesomeIcon
              className="h-3.5 cursor-pointer text-green-600 dark:text-green-400"
              icon={faPenToSquare}
            />
          </button>
          <EditModal
            open={editModal}
            setOpen={setEditModal}
            message={message}
            channel={channel}
            dispatch={dispatch}
          />
        </>
      )}
      {channel === TRADES && (
        <>
          <button
            tabIndex={index * 3 + 2}
            className="mod-button"
            onClick={() => setResendModal(true)}
            title="Resend Notification"
            onMouseEnter={(e) => e.target.focus()}
            onMouseLeave={(e) => e.target.blur()}
          >
            <FontAwesomeIcon
              className="h-3.5 w-3.5 cursor-pointer text-yellow-600 dark:text-yellow-400"
              icon={faPaperPlane}
            />
          </button>
          <ResendModal
            open={resendModal}
            setOpen={setResendModal}
            message={message}
          />
        </>
      )}
      <>
        <button
          tabIndex={
            index * (channel === TRADES ? 3 : 2) + (channel === TRADES ? 3 : 2)
          }
          className="mod-button"
          onClick={() => setDeleteModal(true)}
          title="Delete Message"
          onMouseEnter={(e) => e.target.focus()}
          onMouseLeave={(e) => e.target.blur()}
        >
          <FontAwesomeIcon
            className="h-3.5 w-3.5 cursor-pointer text-red-600 dark:text-red-400"
            icon={faTrash}
          />
        </button>
        <DeleteModal
          open={deleteModal}
          setOpen={setDeleteModal}
          message={message}
          channel={channel}
          dispatch={dispatch}
        />
      </>
      {channel === MEMBERS && hasStaffPrivilege(user) && (
        <>
          <button
            tabIndex={
              index * (channel === TRADES ? 3 : 2) +
              (channel === TRADES ? 3 : 2)
            }
            className="mod-button"
            onClick={() => setModModal(true)}
            title="Mod User"
            onMouseEnter={(e) => e.target.focus()}
            onMouseLeave={(e) => e.target.blur()}
          >
            <FontAwesomeIcon
              className="h-3.5 w-3.5 cursor-pointer text-purple-600 dark:text-purple-400"
              icon={faHammer}
            />
          </button>
          <ModModal open={modModal} setOpen={setModModal} message={message} />
        </>
      )}
    </div>
  )
}

MessageWrapper.propTypes = {
  message: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  index: PropTypes.number.isRequired,
  modView: PropTypes.bool
}

export default MessageWrapper
