import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamationCircle,
  faPaintbrush
} from '@fortawesome/pro-solid-svg-icons'
import Countdown from '../../Utilities/Countdown'
import ToggleSwitch from '../../Forms/common/ToggleSwitch'
import ChangeColorForm from '../../Forms/ChangeColorForm'
import ChangeRoleForm from '../../Forms/ChangeRoleForm'
import { AuthenticationContext } from '../../../context/authenticationContext'
import * as ROLES from '../../../constants/roles'
import Oxforce from '../../Forms/Oxforce'
import Accordion from '../Accordion/Accordion'
import { putUser } from '../../../utils/APIs/users'

export default function UserBio({ user, setUser, roles, handleRoleChange }) {
  const { user: staff } = useContext(AuthenticationContext)
  const [editColor, setEditcolor] = useState(false)
  const vipRole = roles.filter((role) => role.role === ROLES.VIP)[0] || null
  const userHasLoggedIn = user._id && user._id.length > 0
  // TODO: Get siteIsFree from features context. For now it is hard coded
  const siteIsFree = false

  const renderWarningMessage = () => {
    const thisProduct = process.env.REACT_APP_PRODUCT_CODE
    const productName = process.env.REACT_APP_PRODUCT_NAME
    const usersSubs =
      user.userService?.subscriptions.map((sub) => sub.itemNumber) ?? []
    const userHasThisProduct = usersSubs.includes(thisProduct)
    let message = ''
    if (
      (userHasThisProduct && !userHasLoggedIn) ||
      (siteIsFree && !userHasLoggedIn)
    ) {
      message = 'This user has never logged into ' + productName
    } else if (!userHasThisProduct) {
      message = "This user doesn't own " + productName
    }

    if (!message.length || siteIsFree) {
      return null
    }

    return (
      <span className="font-bold text-red-500 text-sm">
        <FontAwesomeIcon icon={faExclamationCircle} className="mr-1" />
        {message}
      </span>
    )
  }

  const renderSubscriptions = () => {
    return (
      <>
        {user.userService.subscriptions.map((each) => {
          return (
            <span
              key={each.itemNumber}
              className="rounded bg-neutral-200 dark:bg-neutral-700 px-1.5 py-1"
            >
              {`${each.itemNumber}:${each.subType}`}
            </span>
          )
        })}
      </>
    )
  }

  return (
    <Accordion title={`User: ${user.userService.profile.name}`}>
      <div className="flex flex-col gap-2">
        {renderWarningMessage()}
        <Accordion startOpen title="User Info">
          <ul className="w-fit">
            <li className="text-sm">
              <span className="font-semibold">ID:</span> {user.userService._id}
            </li>
            {staff.role.role === ROLES.ADMIN && (
              <li className="text-sm">
                <span className="font-semibold">
                  {process.env.REACT_APP_PRODUCT_CODE} ID:
                </span>{' '}
                {user._id}
              </li>
            )}
            <li className="text-sm">
              <span className="font-semibold">NAME:</span>{' '}
              {user.userService.profile.name}
            </li>
            <li className="text-sm">
              <span className="font-semibold">EMAIL:</span>{' '}
              {user.userService.email}
            </li>
            <li className="text-sm">
              <span className="font-semibold">CUSTOMER NUMBER:</span>{' '}
              {user.userService.customerNumber}
            </li>
            <li className="text-sm">
              <span className="font-semibold">ROLE:</span> {user?.role?.role}
            </li>
            <li className="text-sm">
              <span className="font-semibold">SUBCRIPTIONS:</span>
              <div className="flex flex-wrap my-1 gap-1">
                {renderSubscriptions(user.userService.subscriptions)}
              </div>
            </li>
          </ul>
        </Accordion>
        <Accordion title="Send to Oxforce">
          <Oxforce user={user} />
        </Accordion>
        {userHasLoggedIn && (
          <Accordion title="Mod Controls">
            <div className="flex flex-col gap-2">
              <div className="flex flex-col bg-white dark:bg-neutral-900 gap-2">
                <BanControls user={user} setUser={setUser} />
                <TimeoutControls user={user} setUser={setUser} />
              </div>
              {vipRole && (
                <div className="flex gap-2 items-center">
                  <p className="font-semibold text-sm">VIP Member:</p>
                  <input
                    type="checkbox"
                    onChange={() => {
                      if (user.role._id === vipRole._id) {
                        handleRoleChange(vipRole, 'remove')
                      } else {
                        handleRoleChange(vipRole, 'add')
                      }
                    }}
                    checked={user.role._id === vipRole._id}
                  />
                </div>
              )}
              {staff.role.role === ROLES.ADMIN && (
                <ChangeRoleForm user={user} />
              )}
            </div>
          </Accordion>
        )}
        <Accordion title="Display Name Settings">
          <div className="flex flex-col items-start gap-2">
            <div className="flex items-center gap-2">
              <p className="font-semibold text-sm">Name color:</p>
              <p
                className={`font-bold bg-white dark:bg-neutral-900 text-${user.userService.profile.color}`}
              >
                {user.userService.profile.name}
              </p>
            </div>
            <button
              aria-label="Submit message"
              onClick={() => setEditcolor(!editColor)}
              className="p-1 px-1.5 text-xs border rounded bg-white dark:bg-neutral-700 dark:text-neutral-100 dark:border-none hover:border-neutral-300 dark:hover:bg-neutral-600 flex items-center gap-1"
            >
              <FontAwesomeIcon
                className="cursor-pointer h-4 w-4"
                icon={faPaintbrush}
              />{' '}
              Choose Color
            </button>
            {editColor && (
              <ChangeColorForm user={user._id} callbackFunction={setUser} />
            )}
          </div>
        </Accordion>
      </div>
    </Accordion>
  )
}

UserBio.propTypes = {
  user: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  handleRoleChange: PropTypes.func.isRequired
}

export const BanControls = ({ user, setUser }) => {
  const [banned, setBanned] = useState(user.moderation.banned)
  const [shadowbanned, setShadowbanned] = useState(user.moderation.shadowbanned)

  useEffect(() => {
    setBanned(user.moderation.banned)
    setShadowbanned(user.moderation.shadowbanned)
  }, [user])

  return (
    <>
      <ToggleSwitch
        setting="banned"
        value={banned}
        label="Banned"
        color="green"
        userId={user._id}
        group="moderation"
        callbackFunction={setUser}
      />
      <ToggleSwitch
        setting="shadowbanned"
        value={shadowbanned}
        label="Shadow-banned"
        color="green"
        userId={user._id}
        group="moderation"
        callbackFunction={setUser}
      />
    </>
  )
}

BanControls.propTypes = {
  user: PropTypes.object.isRequired
}

export const TimeoutControls = ({ user, setUser }) => {
  const [enabled, setEnabled] = useState(user.moderation.timeout || false)
  const [timeoutEnd, setTimeoutEnd] = useState(
    user.moderation.timeoutEnd || null
  )

  const ONE_MINUTE = 1
  const FIFTEEN_MINUTES = 15
  const ONE_HOUR = 60
  const ONE_DAY = 24 * 60
  const ONE_WEEK = 24 * 60 * 7

  const DEFAULT_TIMES = [
    {
      time: ONE_MINUTE,
      label: '1 Min'
    },
    {
      time: FIFTEEN_MINUTES,
      label: '15 Min'
    },
    {
      time: ONE_HOUR,
      label: '1 Hour'
    },
    {
      time: ONE_DAY,
      label: '1 Day'
    },
    {
      time: ONE_WEEK,
      label: '1 Week'
    }
  ]

  useEffect(() => {
    setEnabled(user.moderation.timeout || false)
    setTimeoutEnd(setDefaultEndTime(user.moderation.timeoutEnd))
  }, [user])

  const setDefaultEndTime = (endTime) => {
    if (endTime && moment(endTime).utc() > moment().utc()) {
      return endTime
    }
    changeEnd(ONE_HOUR)
    return moment().utc().add(ONE_HOUR, 'minutes').format()
  }

  const changeEnd = async (value) => {
    const response = await putUser(
      user._id,
      'timeoutEnd',
      moment().utc().add(value, 'minutes').format(),
      'moderation'
    )
    if (response && response.status === 200) {
      setUser(response.data)
    }
  }

  const endTimeOut = async () => {
    const response = await putUser(user._id, 'timeout', false, 'moderation')
    if (response && response.status === 200) {
      setUser(response.data)
    }
  }

  return (
    <>
      <ToggleSwitch
        setting="timeout"
        value={enabled}
        label="Timeout"
        color="green"
        userId={user._id}
        group="moderation"
        callbackFunction={setUser}
      />
      {enabled && (
        <div className="flex justify-between gap-1">
          {DEFAULT_TIMES.map((each) => {
            return (
              <button
                key={each.time}
                className="w-fit text-xs bg-amber-500 dark:bg-amber-700 hover:bg-amber-700 hover:dark:bg-amber-600 text-white font-semibold py-1 px-2 rounded"
                onClick={() => changeEnd(each.time)}
              >
                {each.label}
              </button>
            )
          })}
        </div>
      )}
      {enabled && timeoutEnd && (
        <div>
          <div className="flex items-center">
            <div className="mr-4 dark:text-neutral-100">
              Remaining:{' '}
              <Countdown endDate={timeoutEnd} endEvent={endTimeOut} />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

TimeoutControls.propTypes = {
  user: PropTypes.object.isRequired
}
