import axios from 'axios'
import { PRODUCTS } from '../ENV'

export const getProductsAggregate = async () => {
  try {
    const response = await axios.get(`${PRODUCTS}/aggregate`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    })

    return response
  } catch (error) {
    return error.response
  }
}

export const putProduct = async (data) => {
  try {
    const response = await axios.put(`${PRODUCTS}/product`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
