import React from 'react'
import { setPageTitle } from '../../../../utils/utilities.js'
import Features from './Features.js'
import OneSignalAppId from './OneSignalAppId.js'

const General = () => {
  setPageTitle('Configuration | General')
  return (
    <div className="flex flex-col gap-2 mb-5">
      <Features />
      <OneSignalAppId />
    </div>
  )
}

export default General
