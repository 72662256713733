import {
  R_ERROR,
  R_LOADING,
  R_RESET,
  R_SOCKET
} from '../constants/actions/socketActions'

export const initialState = {
  error: null,
  loading: true,
  socket: null,
  privateMessagesSocket: null,
  tradesSocket: null
}

export function socketReducer(state, action) {
  switch (action.type) {
    case R_RESET:
      return initialState

    case R_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case R_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case R_SOCKET:
      return {
        ...state,
        [action.payload.type]: action.payload.socket
      }

    default:
      return state
  }
}
