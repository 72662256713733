import React, { useContext } from 'react'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import './guide.style.css'

const Guide = () => {
  const { [SETTINGS.PORTFOLIO_GUIDE.key]: theGuide } =
    useContext(SiteConfigContext)

  if (theGuide === null || theGuide === '' || theGuide === '<p><br></p>') {
    return null
  }

  return (
    <div
      className="portfolio-guide flex flex-col gap-2"
      dangerouslySetInnerHTML={{ __html: theGuide }}
    />
  )
}

export default Guide
