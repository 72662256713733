import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { AuthenticationContext } from '../../context/authenticationContext'
import Copywrite from '../../components/Utilities/Copywrite'
import Logo from '../../graphics/Logo'
import LoginCommandCenter from '../../components/Forms/LoginCommandCenter'
import { setPageTitle } from '../../utils/utilities'

const Login = () => {
  setPageTitle('Sign In')
  const { user, loggedOut } = useContext(AuthenticationContext)
  const [showMessage, setShowMessage] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (user) {
      const from = location.state?.from?.pathname || '/'
      navigate(from, { replace: true })
    }
  }, [user])

  useEffect(() => {
    setShowMessage(true)
    const timeout = setTimeout(() => {
      setShowMessage(false)
    }, 10000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <main className="ios-safari-hack bg-custom-gradient flex h-screen max-h-screen flex-col transition-all ease-in-out duration-300">
      <div className="m-auto flex h-fit w-fit flex-col justify-around">
        {showMessage && loggedOut && (
          <div className="m-2 flex flex-col border border-amber-300 bg-amber-500 dark:border-amber-700 dark:bg-amber-900">
            <div className="p-4">
              <p className="font-medium text-sm text-center">{loggedOut}</p>
            </div>
          </div>
        )}
        <div className="my-auto">
          <div className="splash-container py-16 px-4 gap-3 sm:p-10 sm:gap-8 flex flex-col">
            <Logo />
            <LoginCommandCenter />
          </div>
        </div>
        <div className="flex items-center justify-center mt-8">
          <Copywrite />
        </div>
      </div>
    </main>
  )
}

export default Login
