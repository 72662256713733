import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import MessageList from '../../Messages/MessageList'
import { MODERATORS } from '../../../constants/chatRooms'
import { putUser, getUserByUserServiceId } from '../../../utils/APIs/users'
import { getRoles } from '../../../utils/APIs/roles'
import SearchButton from '../../Buttons/SearchButton'
import { toast } from 'react-toastify'
import * as ROLES from '../../../constants/roles'
import UserBio from '../../Utilities/UserBio/UserBio'
import { SocketContext } from '../../../context/socketContext'
import { SOCKET_EVENTS } from '../../../constants/sockets'

const Users = () => {
  const { socket } = useContext(SocketContext)
  const { userId, privateMessageId } = useParams()
  const [loading, setLoading] = useState({
    user: true,
    roles: true
  })
  const [error, setError] = useState(false)
  const [user, setUser] = useState()
  const [roles, setRoles] = useState()
  const [search, setSearch] = useState(null)

  useEffect(() => {
    if (socket) {
      socket.on(SOCKET_EVENTS.PUT_USER, handlePutUser)
    }
    return () => {
      if (socket) {
        socket.off(SOCKET_EVENTS.PUT_USER, handlePutUser)
      }
    }
  }, [socket, user])

  // useEffect to load roles
  useEffect(() => {
    const fetchRoles = async () => {
      setLoading((old) => ({ ...old, roles: true }))
      const response = await getRoles()
      if (response && response.status === 200) {
        setRoles(response.data)
      } else {
        setError(true)
      }
      setLoading((old) => ({ ...old, roles: false }))
    }

    fetchRoles()
  }, [])

  useEffect(() => {
    getUser(userId)
    setSearch(null)
  }, [userId])

  const getUser = async (userId) => {
    setLoading((old) => ({ ...old, user: true }))
    const response = await getUserByUserServiceId(userId, true)
    if (response && response.status === 200 && response.data != null) {
      setUser(response.data)
    } else {
      setError(true)
    }
    setLoading((old) => ({ ...old, user: false }))
  }

  const handlePutUser = async (data) => {
    if (user && data.id === user._id) {
      const response = await getUserByUserServiceId(userId, true)
      if (response && response.status === 200 && response.data != null) {
        setUser((old) => ({ ...old, ...response.data }))
      }
    }
  }

  const handleRoleChange = async (role, action) => {
    const roleId =
      action === 'add'
        ? role._id
        : roles.filter((role) => role.role === ROLES.SUBSCRIBER)[0]._id ||
          undefined
    if (roleId) {
      setLoading(true)
      const response = await putUser(user._id, 'role', roleId)
      if (response && response.status === 200) {
        setUser(response.data)
        toast.success('VIP set for user.')
      } else {
        setError(true)
        toast.error('Error setting VIP.')
      }
      setLoading(false)
    }
  }

  if (loading.user || loading.roles) {
    return <div>loading</div>
  }

  if (error) {
    return <div>error</div>
  }

  const handleSearch = (e, value) => {
    e.preventDefault()
    setSearch(value)
  }

  return (
    <div className="flex h-full w-full overflow-hidden">
      <div className="flex flex-col w-full">
        <UserBio
          user={user}
          setUser={setUser}
          roles={roles}
          handleRoleChange={handleRoleChange}
        />
        <Search handleSearch={handleSearch} />
        <UserChatHistory user={privateMessageId} search={search} />
      </div>
    </div>
  )
}

const UserChatHistory = ({ user, search }) => {
  return (
    <div className="flex flex-1 flex-col overflow-y-auto bg-white dark:bg-neutral-900">
      <MessageList channel={MODERATORS} modView userId={user} search={search} />
    </div>
  )
}

UserChatHistory.propTypes = {
  user: PropTypes.string.isRequired,
  search: PropTypes.string
}

const Search = ({ handleSearch }) => {
  const [text, setText] = useState('')

  const handleSubmit = (e) => {
    handleSearch(e, text)
  }

  return (
    <div>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="relative flex items-stretch gap-2 my-1"
      >
        <div className="flex flex-col w-full">
          <div className="flex flex-row gap-1 border-t dark:border-0 md:border-0 items-end w-full">
            <input
              aria-label="Search messages"
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="grow input"
              placeholder="Search messages..."
            />
            <SearchButton />
          </div>
        </div>
      </form>
    </div>
  )
}

Search.propTypes = {
  handleSearch: PropTypes.func.isRequired
}

export default Users
