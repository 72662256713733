import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../../constants/settings'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { DAYS } from '../../../constants/general'

const Schedule = () => {
  const { [SETTINGS.SCHEDULE.key]: schedule } = useContext(SiteConfigContext)

  return (
    <div className="hidden max-w-full grid-cols-1 gap-2 md:grid md:grid-cols-3">
      <div className="col-span-3 w-full">
        <h3>
          <FontAwesomeIcon icon={faCalendar} className="mr-3 h-6" />
          Schedule
        </h3>
        <h4>Livestream Start Times</h4>
      </div>
      <div className="col-span-3 w-full">
        <ul className="flex justify-between border border-neutral-200 dark:border-neutral-900 divide-x divide-neutral-200 dark:divide-neutral-900">
          {DAYS.map((day) => {
            return (
              schedule[day].length > 0 && (
                <DayAndTimes key={day} day={day} times={schedule[day]} />
              )
            )
          })}
        </ul>
      </div>
    </div>
  )
}

const DayAndTimes = ({ day, times }) => {
  return (
    <li className="flex flex-col justify-start bg-neutral-100 dark:bg-neutral-800 p-4 w-full">
      <p className="font-medium capitalize">{day}</p>
      {times.length >= 1 &&
        times.map((times, index) => {
          return <p key={index}>{times}</p>
        }, this)}
    </li>
  )
}

DayAndTimes.propTypes = {
  day: PropTypes.string.isRequired,
  times: PropTypes.array.isRequired
}

export default Schedule
