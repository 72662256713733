import React from 'react'
import { Switch } from '@headlessui/react'
import PropTypes from 'prop-types'

const ToggleSwitchBasic = ({ value, label, color, onChange }) => {
  return (
    <Switch.Group>
      <div className="flex items-center">
        {label && (
          <Switch.Label className="mr-4 dark:text-neutral-100">
            {label}
          </Switch.Label>
        )}
        <Switch
          checked={value}
          onChange={onChange}
          className={`${value ? `bg-${color}-500` : 'bg-neutral-500'}
          relative ml-auto inline-flex h-2 w-11 items-center rounded-full`}
        >
          {label && <span className="sr-only">{label}</span>}
          <span
            className={`${value ? 'translate-x-6' : 'translate-x-1'}
          inline-flex h-4 w-4 rounded-full border border-neutral-900/40 dark:border-none bg-neutral-100 transition-all justify-center items-center`}
          >
            <span
              className={`${
                value ? `bg-${color}-500` : 'bg-neutral-500'
              } h-2.5 w-2.5 rounded-full z-10`}
            />
          </span>
        </Switch>
      </div>
    </Switch.Group>
  )
}

ToggleSwitchBasic.propTypes = {
  value: PropTypes.bool.isRequired,
  label: PropTypes.string,
  color: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
}

export default ToggleSwitchBasic
