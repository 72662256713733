import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MEMBERS } from '../../../constants/chatRooms'
import MessageList from '../../Messages/MessageList'
import ToggleSwitchLocal from '../../Forms/common/ToggleSwitchLocal'
import { setPageTitle } from '../../../utils/utilities'

const ChatHistory = () => {
  setPageTitle('Chat History')
  const [loading, setLoading] = useState(true)
  const defaultValue =
    JSON.parse(localStorage.getItem('showShadowBanned')) != null
      ? JSON.parse(localStorage.getItem('showShadowBanned'))
      : true
  const [showShadowBanned, setShowShadowBanned] = useState(defaultValue)
  const [search, setSearch] = useState(null)

  const handleSetShadowBan = () => {
    setLoading(true)
    setShowShadowBanned(!showShadowBanned)
  }

  useEffect(() => {
    setLoading(false)
  }, [showShadowBanned])

  const handleSearch = (e, value) => {
    e.preventDefault()
    setSearch(value)
  }

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex flex-col w-full">
        <div className="mb-4">
          <ToggleSwitchLocal
            setting="showShadowBanned"
            value={showShadowBanned}
            label="Show Shadow-Banned"
            color="green"
            setter={handleSetShadowBan}
          />
        </div>
        <div className="mb-4">
          <SearchForm handleSearch={handleSearch} />
        </div>
      </div>
      {loading ? (
        <div />
      ) : (
        <MessageList
          channel={MEMBERS}
          modView
          showShadowBanned={showShadowBanned}
          search={search}
        />
      )}
    </div>
  )
}

const SearchForm = ({ handleSearch }) => {
  const [show, setShow] = useState(false)
  const [text, setText] = useState('')
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const handleToggle = (e) => {
    if (!show === false) {
      handleSearch(e, null)
    }
    setShow(!show)
  }

  return (
    <div className="w-full">
      <button
        onClick={(e) => handleToggle(e)}
        aria-label="Toggle Search"
        className="neutral-button text-xs"
      >
        {!show ? 'Show Search' : 'Hide Search'}
      </button>
      {show && (
        <div className="w-full">
          <form
            onSubmit={(e) =>
              handleSearch(e, {
                text,
                startDate,
                endDate
              })
            }
            autoComplete="off"
            className="relative flex flex-col md:flex-row focus-within:z-10 gap-2 w-full mt-2"
          >
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">Text</label>
              <input
                aria-label="Search messages"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="grow input w-full"
                placeholder="Search messages..."
              />
            </div>
            <div className="flex flex-col">
              <label className="dark:text-neutral-100">Start Date</label>
              <input
                aria-label="Start Date"
                type="date"
                id="startDate"
                name="startDate"
                selected={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="grow input w-full"
              />
            </div>
            <div className=" flex flex-col">
              <label className="dark:text-neutral-100">End Date</label>
              <input
                aria-label="End Date"
                type="date"
                id="endDate"
                name="endDate"
                selected={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="grow input w-full"
              />
            </div>
            <button
              type="submit"
              aria-label="Submit search"
              className="primary-button relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0 md:mt-auto w-fit"
            >
              Search
            </button>
          </form>
        </div>
      )}
    </div>
  )
}

SearchForm.propTypes = {
  handleSearch: PropTypes.func.isRequired
}

export default ChatHistory
