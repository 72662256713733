import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'
import Countdown from '../Countdown'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as SETTINGS from '../../../constants/settings'
import * as PRODUCTS from '../../../constants/products'

const AdBanner = ({ toggleAd }) => {
  const {
    productsService,
    [SETTINGS.FEATURES.key]: enabledFeatures,
    [SETTINGS.BANNER_DATA.key]: bannerData
  } = useContext(SiteConfigContext)
  const { getSubType } = useContext(AuthenticationContext)
  const [subType] = useState(getSubType())

  if (!bannerData) {
    return null
  }

  if (
    bannerData.openHouseOnly &&
    enabledFeatures &&
    productsService[PRODUCTS.PRODUCT].access.openHouse.enabled &&
    productsService[PRODUCTS.PRODUCT].access.openHouse.itemNumber !== subType
  ) {
    return null
  }

  const bgColor = bannerData.bgColor
    ? `bg-${bannerData.bgColor}-400`
    : 'bg-yellow-400'
  const buttonColor = bannerData.buttonColor
    ? `bg-${bannerData.buttonColor}-500`
    : 'bg-green-500'
  const showToggle = toggleAd && bannerData.showCountdown
  const adBodyClassName = `flex flex-col justify-center cursor-pointer ${
    showToggle ? '' : '-mt-5'
  }`

  const handleToggleAd = () => {
    toggleAd(false)
  }

  const handleOpenLink = () => {
    window.open(bannerData.promoLink, '_blank')
  }

  return (
    <div className="flex flex-col w-full hover:saturate-200">
      <div className={`${bgColor} flex flex-col p-2`}>
        <div className="flex flex-row-reverse items-center justify-between">
          <button onClick={handleToggleAd} className="w-fit relative z-10">
            <FontAwesomeIcon
              className="h-5 w-5 transition-all hover:text-red-600 active:text-red-800 text-black"
              icon={faTimesCircle}
            />
          </button>
          {showToggle && (
            <div className="mx-auto">
              <span className="flex items-center font-bold italic text-sm lg:text-base">
                <span className="mr-1">{bannerData.countdownText}</span>
                <Countdown
                  endDate={bannerData.endDateTime}
                  endEvent={handleToggleAd}
                />
              </span>
            </div>
          )}
        </div>
        <div className={adBodyClassName} onClick={handleOpenLink}>
          <p className="text-black text-center text-base lg:text-lg xl:text-xl">
            {bannerData.body}
          </p>
          {bannerData.showButton && (
            <button
              className={`${buttonColor} rounded w-fit mx-auto px-1.5 py-0.5`}
            >
              {bannerData.buttonText}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

AdBanner.propTypes = {
  toggleAd: PropTypes.func
}

export default AdBanner
