import React from 'react'
import moment from 'moment'
import { sanitizeText } from '../../../utils/utilities'
import styles from './AnnouncementsList.module.css'

export default function AnnouncementsList({ announcements = [] }) {
  return (
    <ul className="flex flex-col gap-2 w-full">
      {announcements.map((announcement) => (
        <li key={announcement._id} className="flex flex-row gap-2 items-center">
          <div className="flex flex-col gap-2 grow">
            <h3>{announcement.title}</h3>
            <p className="w-fit rounded-sm border bg-neutral-100 dark:border-neutral-800 dark:bg-neutral-900 px-2 py-1 text-xs">
              Sent at{' '}
              {moment(announcement.createdAt).format('h:mm A, MMMM DD Y')}
            </p>
            <p
              className={styles}
              dangerouslySetInnerHTML={{
                __html: sanitizeText(announcement.message)
              }}
            />
            {announcement.cta && (
              <a
                href={announcement.cta.url}
                target="_blank"
                className="w-fit mr-auto primary-button"
                rel="noreferrer"
              >
                <span className="whitespace-nowrap">
                  {announcement.cta.text || 'Go'}
                </span>
              </a>
            )}
          </div>
        </li>
      ))}
    </ul>
  )
}
