import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Trade from '../../Messages/Trade'
import Progressbar from '../ProgressBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons'

const COUNTER_DEFAULT = 300

const TradeAlert = ({ message, toggleAlert }) => {
  const [counter, setCounter] = useState(COUNTER_DEFAULT)
  const [counterID, setCounterID] = useState(COUNTER_DEFAULT)

  useEffect(() => {
    clearTimeout(counterID)
    setCounter(COUNTER_DEFAULT)
    setCounterID(COUNTER_DEFAULT)
  }, [message])

  useEffect(() => {
    if (counter > 0) {
      const id = setTimeout(() => setCounter(counter - 1), 1000)
      setCounterID(id)
    } else {
      clearTimeout(counterID)
      toggleAlert(false)
    }
  }, [counter])

  return (
    <div className="hidden md:flex flex-col bg-white dark:bg-neutral-900">
      <div className="flex items-center">
        <Progressbar progress={(counter / COUNTER_DEFAULT) * 100} />
        <button
          onClick={() => toggleAlert(false)}
          className="flex items-center"
        >
          <FontAwesomeIcon
            className="mt-1 mr-1 h-5 transition-all hover:text-red-600 active:text-red-800 dark:text-white"
            icon={faTimesCircle}
          />
        </button>
      </div>
      <Trade message={message} truncate={true} />
    </div>
  )
}

TradeAlert.propTypes = {
  message: PropTypes.object.isRequired,
  toggleAlert: PropTypes.func.isRequired
}

export default TradeAlert
