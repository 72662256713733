import { useEffect, useState } from 'react'

const useIsMobileBrowser = () => {
  const [isMobileBrowser, setIsMobileBrowser] = useState(false)

  useEffect(() => {
    const checkMobileBrowser = () => {
      const mobileKeywords = [
        'Android',
        'webOS',
        'iPhone',
        'iPad',
        'iPod',
        'BlackBerry',
        'IEMobile',
        'Opera Mini'
      ]
      const userAgent = navigator.userAgent
      setIsMobileBrowser(
        mobileKeywords.some((keyword) => userAgent.includes(keyword))
      )
    }

    checkMobileBrowser()

    // Add a listener for changes in user agent string
    window.addEventListener('resize', checkMobileBrowser)

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener('resize', checkMobileBrowser)
    }
  }, [])

  return isMobileBrowser
}

export default useIsMobileBrowser
