import React from 'react'
import { setPageTitle } from '../../../utils/utilities'
import PublicMessagesPerDay from './PublicMessagesPerDay'
import PrivateMessagesPerDay from './PrivateMessagesPerDay'
import Users from './Users'
import ViewsPerHour from './ViewsPerHour'
import ViewsPerDay from './ViewsPerDay'

const Stats = () => {
  setPageTitle('Stats')
  return (
    <div className="flex flex-col w-full gap-4 pb-4 md:grid md:grid-cols-2">
      <PublicMessagesPerDay />
      <PrivateMessagesPerDay />
      <ViewsPerHour />
      <ViewsPerDay />
      <Users />
    </div>
  )
}

export default Stats
