import React, { useContext, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import './styles.css'
import { toast } from 'react-toastify'

const HeadquartersFree = () => {
  const {
    freeEnabled,
    [SETTINGS.HEADQUARTERS_FREE.key]: freeContent,
    updateSetting
  } = useContext(SiteConfigContext)
  const [tmpGuide, setTmpGuide] = useState(freeContent)

  const handleSubmit = async (e) => {
    e.preventDefault()
    await updateSetting(SETTINGS.HEADQUARTERS_FREE, tmpGuide)
    toast.success('Headquarters Free content saved!')
  }

  if (!freeEnabled) {
    return (
      <div className="flex flex-col gap-2">
        <h2>Free mode is not enabled</h2>
      </div>
    )
  }

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      className="flex flex-col gap-2"
    >
      <ReactQuill
        id="terms"
        name="terms"
        style={{ height: '420px', width: '100%' }}
        aria-label="Headquarters Free content"
        placeholder="Headquarters Free content"
        defaultValue={freeContent}
        onChange={setTmpGuide}
        modules={{
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean']
          ]
        }}
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'link'
        ]}
      />
      <button
        type="submit"
        aria-label="Submit message"
        className="primary-button w-fit"
      >
        Save
      </button>
    </form>
  )
}

export default HeadquartersFree
