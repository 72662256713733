import React, { useEffect, useContext } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import InitializeSettings from '../../components/Moderation/InitializeSettings'
import { SiteConfigContext } from '../../context/siteConfigContext'
import * as SETTINGS from '../../constants/settings'
import { setPageTitle } from '../../utils/utilities'

const Initialize = () => {
  setPageTitle('Initialize')
  const { isLoading, [SETTINGS.SETUP_COMPLETE.key]: setupComplete } =
    useContext(SiteConfigContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (setupComplete) {
      const from = location.state?.from?.pathname || '/'
      navigate(from, { replace: true })
    }
  }, [setupComplete])

  if (isLoading) {
    return <div>LOADING...</div>
  }

  return (
    <main className="ios-safari-hack flex flex-col h-screen max-h-screen bg-custom-gradient lg:overflow-hidden">
      <div className="flex flex-1 bg-white overflow-y-auto dark:bg-neutral-900 h-full">
        <div className="flex flex-1 flex-col container h-full">
          <section className="h-full bg-white dark:bg-neutral-900 p-4 lg:py-8 lg:pl-72">
            <InitializeSettings />
          </section>
        </div>
      </div>
    </main>
  )
}

export default Initialize
