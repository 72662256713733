import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { AnalyticsProvider } from './context/analyticsContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <AnalyticsProvider>
    <App />
    <ToastContainer
      toastClassName={() =>
        'dark:bg-black bg-white relative flex p-1 my-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer shadow-md'
      }
      bodyClassName={() => 'flex p-3 text-sm text-black dark:text-white'}
      position="top-right"
      autoClose={4000}
    />
  </AnalyticsProvider>
  // </React.StrictMode>
)
