import axios from 'axios'
import { API } from '../ENV'

export const getCache = async (setting) => {
  try {
    const response = await axios.get(`${API}/cache/${setting ? setting : ''}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    })
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const deleteCache = async (setting) => {
  try {
    const response = await axios.delete(
      `${API}/cache/${setting ? setting : ''}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
