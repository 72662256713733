import React, { useContext, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../../constants/settings'
import ToggleSwitch from '../../Forms/common/ToggleSwitch'
import VideoSection from '../../../components/Layout/VideoSection'

import { delay, setPageTitle } from '../../../utils/utilities'
import {
  modSettingsReducer,
  initialState
} from '../../../reducers/modSettingsReducer'
import {
  LOADING,
  LIVE_CONFIRMATION,
  INITIALIZE,
  TITLE,
  RESEND_CONFIRMATION
} from '../../../constants/actions/modSettingsActions'
import { toast } from 'react-toastify'
import ActiveStreamer from './ActiveStreamer'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import { putGoLive } from '../../../utils/APIs/settings'

const TITLE_MAX_LENGTH = 140

const SiteSettings = () => {
  setPageTitle('Site Settings')
  const {
    [SETTINGS.LIVESTREAM_LIVE.key]: livestreamLive,
    [SETTINGS.CHAT_OPEN.key]: chatOpen,
    [SETTINGS.STREAM_TITLE.key]: streamTitle
  } = useContext(SiteConfigContext)
  const [state, dispatch] = useReducer(modSettingsReducer, initialState)

  useEffect(() => {
    dispatch({
      type: INITIALIZE,
      payload: {
        live: livestreamLive,
        open: chatOpen,
        title: streamTitle
      }
    })
  }, [livestreamLive, chatOpen, streamTitle])

  useEffect(() => {
    if (streamTitle) {
      dispatch({ type: TITLE, payload: streamTitle })
    }
  }, [streamTitle])

  return (
    <div className="h-fit w-full max-w-fit flex gap-2">
      <div className="flex flex-col md:flex-row flex-1 gap-4">
        <div className="flex flex-col gap-4">
          <Settings state={state} dispatch={dispatch} />
          <h2>Incoming Feed</h2>
          <div className="w-96 max-w-full border dark:border-neutral-700">
            <VideoSection modView />
          </div>
        </div>
        <ActiveStreamer />
      </div>
    </div>
  )
}

const Settings = ({ state, dispatch }) => {
  const { updateSetting } = useContext(SiteConfigContext)

  const handleTitleChange = (value) => {
    dispatch({ type: TITLE, payload: value })
  }

  const handleLiveConfirmationChange = (value) => {
    dispatch({ type: LIVE_CONFIRMATION, payload: value })
  }

  const handleResendConfirmationChange = (value) => {
    dispatch({ type: RESEND_CONFIRMATION, payload: value })
  }

  const handleTitle = async (e) => {
    e.preventDefault()
    await updateSetting(SETTINGS.STREAM_TITLE, state.title)
    toast.success('Stream Title updated.')
  }

  const handleGoLive = async (status) => {
    dispatch({ type: LOADING, payload: true })
    dispatch({ type: LIVE_CONFIRMATION, payload: false })
    dispatch({ type: RESEND_CONFIRMATION, payload: false })
    const response = await putGoLive(status, state.title)
    if (response && response.status === 200) {
      await delay(5000) // delay so they can't spam it
      dispatch({ type: LOADING, payload: false })
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <ToggleSwitch
        setting={SETTINGS.LIVESTREAM_LIVE}
        value={state.live}
        label="Livestream Live"
        color="green"
      />
      <ToggleSwitch
        setting={SETTINGS.CHAT_OPEN}
        value={state.open}
        label="Chat Open"
        color="green"
      />
      <h4>
        Stream Title
        <span className="ml-2 text-xs">
          <span
            className={
              state.title.length === TITLE_MAX_LENGTH ? 'text-red-500' : ''
            }
          >
            ({state.title.length} / {TITLE_MAX_LENGTH})
          </span>
        </span>
      </h4>
      <form onSubmit={handleTitle} autoComplete="off">
        <div className="relative flex flex-grow items-stretch focus-within:z-10 gap-2">
          <input
            value={state.title}
            onChange={(e) => handleTitleChange(e.target.value)}
            className="input block w-full"
            placeholder="Stream Title"
            maxLength={TITLE_MAX_LENGTH}
          />
          <button
            type="submit"
            aria-label="Submit title"
            className="primary-button relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0"
          >
            Set Title
          </button>
        </div>
      </form>
      <div className="flex">
        {!state.liveConfirmation && (
          <button
            onClick={() => handleLiveConfirmationChange(true)}
            aria-label={!state.live ? 'Go Live' : 'End Stream'}
            className={`${
              !state.live ? 'primary-button' : 'cancel-button'
            } flex-1`}
            disabled={state.loading}
          >
            {!state.live ? 'Go Live' : 'End Stream'}
          </button>
        )}
        {state.liveConfirmation && (
          <div className="flex-1 flex flex-grow items-stretch gap-2">
            <button
              onClick={() => handleGoLive(!state.live)}
              aria-label="Are you sure?"
              className="primary-button w-full"
            >
              Confirm
            </button>
            <button
              onClick={() => handleLiveConfirmationChange(false)}
              aria-label="Cancel"
              className="cancel-button w-full"
            >
              Cancel
            </button>
          </div>
        )}
        {state.loading && (
          <div className="flex items-center bg-green-500/20 border border-green-500 ml-2 p-3 rounded font-medium text-green-500 transition-all text-sm">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}
      </div>
      {state.live && (
        <div className="flex">
          {!state.resendConfirmation && (
            <button
              onClick={() => handleResendConfirmationChange(true)}
              aria-label="Resend Notification"
              className="primary-button flex-1"
              disabled={state.loading}
            >
              Resend Notification
            </button>
          )}
          {state.resendConfirmation && (
            <div className="flex-1 flex flex-grow items-stretch gap-2">
              <button
                onClick={() => handleGoLive(true)}
                aria-label="Are you sure?"
                className="primary-button w-full"
              >
                Confirm
              </button>
              <button
                onClick={() => handleResendConfirmationChange(false)}
                aria-label="Cancel"
                className="cancel-button w-full"
              >
                Cancel
              </button>
            </div>
          )}
          {state.loading && (
            <div className="flex items-center bg-green-500/20 border border-green-500 ml-2 p-3 rounded font-medium text-green-500 transition-all text-sm">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

SiteSettings.propTypes = {
  state: PropTypes.any,
  dispatch: PropTypes.any
}

export default SiteSettings
