import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { getUserByEmail, postUser, putUser } from '../../../../utils/APIs/users'
import { getRoles } from '../../../../utils/APIs/roles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faExclamation,
  faPaintBrush,
  faSpinner
} from '@fortawesome/pro-solid-svg-icons'
import ChangeColorForm from '../../../Forms/ChangeColorForm'
import { toast } from 'react-toastify'
import * as ROLES from '../../../../constants/roles'
import { setPageTitle } from '../../../../utils/utilities'

const COMMAND_BOT = 'Command Bot'
const COMMAND_BOT_EMAIL = 'commandbot@moal.tech'

const CommandBotSetup = () => {
  setPageTitle('Configuration | Command Bot')
  const [loading, setLoading] = useState(true)
  const [commandBot, setCommandBot] = useState()

  useEffect(() => {
    checkCommandBot()
  }, [])

  const checkCommandBot = async () => {
    const response = await getUserByEmail(COMMAND_BOT_EMAIL)
    if (response && response.status === 200 && response.data) {
      setCommandBot(response.data)
    }
    setLoading(false)
  }

  const createCommandBot = async () => {
    const response = await postUser(COMMAND_BOT_EMAIL)
    if (response && response.status === 201) {
      checkCommandBot()
      toast.success('Command Bot created.')
    }
  }

  if (loading) {
    return <div>loading</div>
  }

  return (
    <div className="flex h-full w-full">
      <div className="flex h-full w-full">
        {commandBot ? (
          <CommandBotSettings
            prefill={commandBot}
            setCommandBot={setCommandBot}
          />
        ) : (
          <div className="h-fit">
            <button className="primary-button" onClick={createCommandBot}>
              Create {COMMAND_BOT}
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

const CommandBotSettings = ({ prefill, setCommandBot }) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [name, setName] = useState('')
  const [editColor, setEditcolor] = useState(false)
  const [botRoleId, setBotRoleId] = useState('')

  useEffect(() => {
    setName(prefill.userService.profile.name)
    if (prefill.role.role !== ROLES.BOT) {
      loadRoles()
    }
  }, [prefill])

  const loadRoles = async () => {
    const response = await getRoles()
    if (response && response.status === 200) {
      const find = response.data.find((r) => r.role === ROLES.BOT)
      setBotRoleId(find._id)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (name !== '' && name !== prefill.name) {
      setLoading(true)
      const response = await putUser(
        prefill._id,
        'userService.profile.name',
        name
      )
      if (response && response.status === 200) {
        toast.success('Command bot name updated.')
      } else {
        setError(true)
        toast.error('Error updating command bot name.')
      }
      setLoading(false)
    }
  }

  const fixRole = async () => {
    setLoading(true)
    const response = await putUser(prefill._id, 'role', botRoleId)
    if (!response || response.status !== 200) {
      setError(true)
    }
    setLoading(false)
  }

  return (
    <div className="flex flex-col gap-2">
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="relative flex items-stretch z-20"
      >
        <div className="relative flex flex-grow items-stretch focus-within:z-10 gap-2">
          <span className="sr-only">Set Display Name</span>
          <input
            aria-label="Rename Form"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input block w-full"
            placeholder="Display Name"
            required
          />
          <button
            type="submit"
            aria-label="Set bot name"
            className="primary-button relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0"
          >
            Set Name
          </button>
        </div>
      </form>
      <div className="flex items-center">
        <p className="">
          Name color:{' '}
          <span
            className={`ml-1 font-bold px-1.5 py-1 bg-white dark:bg-neutral-900 text-${prefill.userService.profile.color}`}
          >
            {name}
          </span>
        </p>
        <button
          aria-label="Submit message"
          onClick={() => setEditcolor(!editColor)}
          className="p-1 px-1.5 ml-2 text-xs border rounded bg-white dark:bg-neutral-700 dark:text-neutral-100 dark:border-none hover:border-neutral-300 dark:hover:bg-neutral-600 flex items-center gap-1"
        >
          <FontAwesomeIcon
            className="cursor-pointer h-4 w-4"
            icon={faPaintBrush}
          />{' '}
          Choose Color
        </button>
      </div>
      {editColor && (
        <ChangeColorForm user={prefill._id} callbackFunction={setCommandBot} />
      )}
      <div className="flex items-center">
        {prefill.role.role !== ROLES.BOT && botRoleId && (
          <div>
            <button
              onClick={fixRole}
              aria-label="Fix Role"
              className="primary-button relative inline-flex items-center gap-x-1.5 rounded-r px-3 py-2 flex-shrink-0"
            >
              Fix Role
            </button>
          </div>
        )}
      </div>
      {loading && (
        <div className="flex items-center whitespace-nowrap bg-green-500 px-4 py-2 font-bold text-white transition-all hover:bg-green-600 active:bg-green-700">
          <FontAwesomeIcon
            className="mr-2 h-5 cursor-pointer"
            icon={faSpinner}
            spin
          />
        </div>
      )}
      {error && (
        <div className="flex items-center whitespace-nowrap bg-red-500 px-4 py-2 font-bold text-white transition-all hover:bg-red-600 active:bg-red-700">
          <FontAwesomeIcon
            className="mr-2 h-5 cursor-pointer"
            icon={faExclamation}
          />
        </div>
      )}
    </div>
  )
}

CommandBotSettings.propTypes = {
  prefill: PropTypes.object
}

export default CommandBotSetup
