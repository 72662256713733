import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'

const LoadingRow = () => {
  return (
    <div className="flex w-full justify-between items-center ">
      <p className="flex items-center whitespace-nowrap p-2 font-bold">
        <FontAwesomeIcon
          className="mr-2 h-5 cursor-pointer"
          icon={faSpinner}
          spin
        />
        LOADING
      </p>
    </div>
  )
}

export default LoadingRow
