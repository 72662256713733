import React, { useContext, useState } from 'react'
import ReactQuill from 'react-quill'
import * as SETTINGS from '../../../../constants/settings'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import './styles.css'
import { setPageTitle } from '../../../../utils/utilities'

const Portfolio = () => {
  setPageTitle('Content | Portfolio')
  return (
    <>
      <PortfolioID />
      <PortfolioColumns />
      <PortfolioGuide />
    </>
  )
}

const PortfolioID = () => {
  const { [SETTINGS.PORTFOLIO_ID.key]: id, updateSetting } =
    useContext(SiteConfigContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = formData.get('id')
    await updateSetting(SETTINGS.PORTFOLIO_ID, data)
    toast.success('Portfolio ID saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Portfolio ID</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <input
          id="id"
          name="id"
          defaultValue={id}
          className="input block w-full"
          placeholder="Portfolio ID"
        />
        <button type="submit" className="primary-button w-fit">
          Save
        </button>
      </form>
    </div>
  )
}

const PortfolioGuide = () => {
  const { [SETTINGS.PORTFOLIO_GUIDE.key]: theGuide, updateSetting } =
    useContext(SiteConfigContext)
  const [tmpGuide, setTmpGuide] = useState(theGuide)

  const handleSubmit = async (e) => {
    e.preventDefault()
    await updateSetting(SETTINGS.PORTFOLIO_GUIDE, tmpGuide)
    toast.success('Portfolio Guide content saved!')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Portfolio Guide</h2>
      <form
        onSubmit={handleSubmit}
        autoComplete="off"
        className="flex flex-col gap-2"
      >
        <ReactQuill
          id="terms"
          name="terms"
          style={{ height: '420px', width: '100%' }}
          aria-label="Portfolio Guide content"
          placeholder="Portfolio Guide content"
          defaultValue={theGuide}
          onChange={setTmpGuide}
          modules={{
            toolbar: [
              [{ header: [1, 2, false] }],
              ['bold', 'italic', 'underline', 'strike', 'blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              ['link'],
              ['clean']
            ]
          }}
          formats={[
            'header',
            'bold',
            'italic',
            'underline',
            'strike',
            'blockquote',
            'list',
            'bullet',
            'link'
          ]}
        />
        <button
          type="submit"
          aria-label="Submit message"
          className="primary-button w-fit"
        >
          Save
        </button>
      </form>
    </div>
  )
}

const PortfolioColumns = () => {
  const { [SETTINGS.PORTFOLIO.key]: pSettings, updateSetting } =
    useContext(SiteConfigContext)

  const titleCase = (str) => {
    return str.toLowerCase().replace(/(^|\s)\w/g, function (match) {
      return match.toUpperCase()
    })
  }

  const handleFeatureChange = async (key, value) => {
    const data = { ...pSettings, [key]: value }
    updateSetting(SETTINGS.PORTFOLIO, data)
    toast.success(`${titleCase(key)} ${value ? 'Enabled' : 'Disabled'}`)
  }

  function sortAlphabetically(array, property, ascending = true) {
    return array.sort((item1, item2) => {
      // Helper function to handle comparison logic
      function getComparisonValue(item, property) {
        if (!item[property]) return ascending ? 1 : -1 // Handle undefined for sorting

        return typeof item[property] === 'string'
          ? item[property].localeCompare(item2[property]) // Compare strings
          : item[property] - item2[property] // Compare numbers
      }

      const comparisonResult = getComparisonValue(item1, property)
      return ascending ? comparisonResult : -comparisonResult
    })
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Portfolio Columns</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
        {pSettings &&
          sortAlphabetically(Object.entries(pSettings), 0).map(
            ([key, value]) => {
              return (
                <label
                  tabIndex={0}
                  htmlFor={key}
                  key={key}
                  className={`cursor-pointer flex w-full items-center justify-between px-2 py-1 gap-1.5 rounded border transition-all ${
                    value
                      ? 'bg-green-500/15 hover:bg-green-500/25 border-green-500/50'
                      : 'bg-red-500/15 hover:bg-red-500/25 border-red-500/50'
                  }`}
                >
                  <p className="select-none">{key.toUpperCase()}</p>
                  <input
                    id={key}
                    name={key}
                    type="checkbox"
                    checked={value}
                    onChange={() => handleFeatureChange(key, !value)}
                    className="hidden"
                  />
                  <p className="sr-only">{value ? `on` : `off`}</p>
                  <FontAwesomeIcon
                    icon={value ? faCircleCheck : faCircleXmark}
                    className={`${value ? 'text-green-500' : 'text-red-500'}`}
                  />
                </label>
              )
            }
          )}
      </div>
    </div>
  )
}

export default Portfolio
