import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MouseTooltip from 'react-sticky-mouse-tooltip'

function CustomBadge({ badge, size, additionalClasses = '' }) {
  const { icon, color, title } = badge
  const [tooltipVisibility, setTooltipVisibility] = useState(false)

  const handleTooltip = () => {
    setTooltipVisibility(!tooltipVisibility)
  }

  const handleMouseLeave = () => {
    setTooltipVisibility(false)
  }

  switch (size) {
    case '1x':
      size = 'fa-1x'
      break
    case '2x':
      size = 'fa-2x'
      break
    case '3x':
      size = 'fa-3x'
      break
    case '4x':
      size = 'fa-4x'
      break
  }

  return (
    <>
      <FontAwesomeIcon
        onMouseEnter={handleTooltip}
        onMouseLeave={handleMouseLeave}
        icon={icon}
        className={`${size} mr-1.5 text-${color}-500 ${additionalClasses}`}
      />
      <MouseTooltip
        visible={tooltipVisibility}
        offsetX={4}
        offsetY={-35}
        className="z-[9999] shadow-md px-2 py-1 rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800"
      >
        <span className="text-neutral-800 dark:text-neutral-200 text-sm font-light">
          {title}
        </span>
      </MouseTooltip>
    </>
  )
}

CustomBadge.propTypes = {
  badge: PropTypes.object.isRequired,
  size: PropTypes.string,
  additionalClasses: PropTypes.string
}

export default CustomBadge
