import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'
import SignUpForm from '../../components/Forms/SignUpForm'

const Free = () => {
  const { [SETTINGS.HEADQUARTERS_FREE.key]: copy } =
    useContext(SiteConfigContext)

  return (
    <>
      <h1 className="flex items-center gap-4">
        <FontAwesomeIcon icon={faHouse} />
        Welcome
      </h1>
      <div className="grid max-w-full grid-cols-1 gap-4">
        <div
          className="flex flex-col"
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      </div>
      <SignUpForm navigate={false} />
    </>
  )
}

export default Free
