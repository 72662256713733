import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

const RegistrationDate = ({ message }) => {
  return (
    <p className="text-sm">
      Joined {moment(message.user.registrationDate).format('MMM DD YYYY')}
    </p>
  )
}

RegistrationDate.propTypes = {
  message: PropTypes.object.isRequired
}

export default RegistrationDate
