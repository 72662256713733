import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { AuthenticationContext } from '../../context/authenticationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import RenameForm from '../../components/Forms/RenameForm'
import ChangeColorForm from '../../components/Forms/ChangeColorForm'
import ToggleSwitch from '../../components/Forms/common/ToggleSwitch'

import {
  faUserGear,
  faUsers,
  faPaintbrush,
  faComments
} from '@fortawesome/pro-solid-svg-icons'
import { hasFeature } from '../../utils/utilities'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'

const UserSettings = () => {
  const { [SETTINGS.MEMBERSHIPS.key]: memberships } =
    useContext(SiteConfigContext)
  const { user, getSubType, updateUser } = useContext(AuthenticationContext)
  const [subType] = useState(getSubType(user))

  return (
    <>
      <h1 className="flex items-center gap-4">
        <FontAwesomeIcon icon={faUserGear} />
        Account Settings
      </h1>
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center gap-2">
          <FontAwesomeIcon icon={faComments} />
          Chat
        </h2>
        <ShowTimestampsForm user={user} updateUser={updateUser} />
        <ShowEmphasizeStaffMessagesForm user={user} updateUser={updateUser} />
      </div>
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center gap-2">
          <FontAwesomeIcon icon={faUsers} />
          Display Name
        </h2>
        <RenameForm user={user} />
      </div>
      <div className="flex flex-col gap-2">
        <h2 className="flex items-center gap-2">
          <FontAwesomeIcon icon={faPaintbrush} />
          Name Color
        </h2>
        <div className="rounded border dark:border-neutral-700 px-1.5 py-1 w-fit bg-white dark:bg-neutral-900">
          <p className={`m-2 font-bold text-${user.userService.profile.color}`}>
            {user.userService.profile.name}
          </p>
          {memberships.main.map((bdg) => {
            return (
              subType === bdg.subType &&
              hasFeature(bdg.features, 'Choose Name Color') && (
                <ChangeColorForm
                  user={user._id}
                  callbackFunction={updateUser}
                />
              )
            )
          })}
        </div>
      </div>
    </>
  )
}

const ShowTimestampsForm = ({ user, updateUser }) => {
  return (
    <div className="flex items-center w-fit">
      <ToggleSwitch
        setting="showTimestamps"
        value={user.userService.settings.showTimestamps || false}
        label="Show Timestamps"
        color="green"
        userId={user._id}
        group="userService.settings"
        callbackFunction={updateUser}
      />
    </div>
  )
}

ShowTimestampsForm.propTypes = {
  user: PropTypes.object.isRequired
}

const ShowEmphasizeStaffMessagesForm = ({ user, updateUser }) => {
  return (
    <div className="flex items-center w-fit">
      <ToggleSwitch
        setting="emphasizeStaffMessages"
        value={user.userService.settings.emphasizeStaffMessages || false}
        label="Emphasize Staff Messages"
        color="green"
        userId={user._id}
        group="userService.settings"
        callbackFunction={updateUser}
      />
    </div>
  )
}

ShowEmphasizeStaffMessagesForm.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserSettings
