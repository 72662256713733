import React from 'react'
import { setPageTitle } from '../../../utils/utilities'

import PostTradeForm from '../../Forms/PostTradeForm'

const PostTrade = () => {
  setPageTitle('Post Trade')

  return (
    <div className="flex h-full w-full">
      <PostTradeForm />
    </div>
  )
}

export default PostTrade
