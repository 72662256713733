import React, { useContext, useEffect, useState } from 'react'
import { SocketContext } from '../../../context/socketContext'
import { ANNOUNCEMENT_EVENTS } from '../../../constants/sockets'
import { NotificationBadge } from '../NotificationBadge'
import { getUnreadCount } from '../../../utils/APIs/announcements'

const UnreadCountBadge = () => {
  const { announcementsSocket } = useContext(SocketContext)
  const [unreadCount, setUnreadCount] = useState(0)

  useEffect(() => {
    if (announcementsSocket) {
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.POST, fetchUnreadCount)
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.EDIT, fetchUnreadCount)
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.DELETE, fetchUnreadCount)
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.READ, fetchUnreadCount)
      announcementsSocket.on(ANNOUNCEMENT_EVENTS.READ_ALL, resetCount)
    }

    return () => {
      if (announcementsSocket) {
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.POST, fetchUnreadCount)
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.EDIT, fetchUnreadCount)
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.DELETE, fetchUnreadCount)
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.READ, fetchUnreadCount)
        announcementsSocket.off(ANNOUNCEMENT_EVENTS.READ_ALL, resetCount)
      }
    }
  }, [announcementsSocket])

  useEffect(() => {
    fetchUnreadCount()
  }, [])

  const resetCount = () => {
    setUnreadCount(0)
  }

  const fetchUnreadCount = async () => {
    try {
      const response = await getUnreadCount({ latest: true })
      setUnreadCount(response.data.count)
    } catch (error) {
      console.error(error)
    }
  }

  return <NotificationBadge count={unreadCount} />
}

export default UnreadCountBadge
