import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import SignUpForm from '../SignUpForm'
import LoginCommandCenter from '../LoginCommandCenter'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-solid-svg-icons'

const JoinTheConvo = () => {
  const [isTurnstileOpen, setIsTurnstileOpen] = useState(true)
  const { user } = useContext(AuthenticationContext)

  const toggleTurnstile = () => {
    setIsTurnstileOpen(!isTurnstileOpen)
  }

  return (
    <>
      <div id="chat-form" className="relative flex items-stretch z-20 gap-2">
        {!isTurnstileOpen && (
          <div className="flex flex-col w-full">
            <div className="flex flex-row border-t bg-brand-secondary-100 dark:bg-brand-secondary-900 p-2 dark:border-0 md:border-0 items-end w-full">
              <button
                type="submit"
                aria-label="Submit message"
                className="primary-button relative rounded-r w-full text-center"
                onClick={toggleTurnstile}
              >
                Join the Conversation!
              </button>
            </div>
          </div>
        )}
      </div>
      {isTurnstileOpen && (
        <div className="flex flex-col relative bg-brand-secondary-100 dark:bg-brand-secondary-900">
          <button
            className="absolute top-2 right-2 text-brand-secondary-inverse-100 dark:text-brand-secondary-inverse-900"
            onClick={toggleTurnstile}
          >
            <FontAwesomeIcon
              icon={faTimes}
              className="w-5 h-5 hover:text-red-500 focus:text-red-500 active:text-red-600"
            />
          </button>
          <div className="p-2">
            <SignUpForm />
          </div>
          {!user && (
            <>
              <p className="text-center -my-1 uppercase text-sm tracking-widest">
                – or –
              </p>
              <LoginCommandCenter turnstile={true} />
            </>
          )}
        </div>
      )}
    </>
  )
}

JoinTheConvo.propTypes = {
  channel: PropTypes.string
}

export default JoinTheConvo
