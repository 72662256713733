import React from 'react'
import PropTypes from 'prop-types'

const ForgotPasswordLink = ({ passwordReset }) => {
  return (
    <>
      <a
        className="text-orange-600 dark:text-orange-400 underline"
        href={passwordReset}
      >
        Forgot your password?
      </a>
    </>
  )
}

ForgotPasswordLink.propTypes = {
  passwordReset: PropTypes.string
}

export default ForgotPasswordLink
