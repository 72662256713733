import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNote, faTicket, faImage } from '@fortawesome/pro-solid-svg-icons'
import TextWrapper from '../../Utilities/TextWrapper'
import Lightbox from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import PostDate from '../../Utilities/PostDate'
import Modal from '../../Utilities/Modal'

const Trade = ({ message, truncate = false }) => {
  const [open, setOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [extraData, setExtraData] = useState({})
  const [imageSrc, setImageSrc] = useState({})
  const [tradeModal, setTradeModal] = useState(false)

  useEffect(() => {
    if (message) {
      const option = message?.extra?.find((obj) => {
        return obj.key === 'option'
      })
      const images = message?.extra?.find((obj) => {
        return obj.key === 'images'
      })
      const foundBySAM = message?.extra?.find((obj) => {
        return obj.key === 'foundBySAM'
      })

      setExtraData({
        option: option?.value || null,
        images: images?.value || null,
        foundBySAM: foundBySAM?.value || false
      })

      const newSrcs =
        images &&
        images.value.map((img) => {
          return { src: img, width: 800 }
        })
      setImageSrc(newSrcs)
    }
  }, [message])

  const handleLightbox = (index) => {
    setOpen(true)
    setSelectedIndex(index)
  }

  let colors
  switch (message.type && message.type.toLowerCase()) {
    case 'open':
      colors = 'bg-green-400 dark:bg-green-700'
      break
    case 'update':
      colors = 'bg-cyan-400 dark:bg-cyan-700'
      break
    default:
      colors = 'bg-red-400 dark:bg-red-700'
  }

  return (
    <div className="flex w-full p-1">
      <div
        className={`relative w-full rounded p-2 ${
          extraData.foundBySAM ? colors : colors
        }`}
        key={message._id}
      >
        <div className="flex w-full justify-between mb-1 mt-0.5 align-middle">
          <PostDate date={message.createdAt} />
          {extraData.foundBySAM && (
            <div className="flex justify-between bg-neutral-100 dark:bg-neutral-900 rounded-xl py-1 px-2">
              <span className="text-[.7rem] leading-4 text-neutral-700 dark:text-neutral-300">
                <FoundBySAM />
              </span>
            </div>
          )}
        </div>
        <div className="flex w-full items-center justify-between">
          <h3 className="leading-5">{message.headline}</h3>
        </div>
        <div className="mt-1 text-sm text-neutral-900 dark:text-neutral-100">
          {extraData.option && (
            <span className="flex items-start border-b border-neutral-900/20 py-1">
              <FontAwesomeIcon
                className="mr-2 mt-1 fa-fw"
                icon={faTicket}
                title="Option"
              />
              <span className="text-lg font-bold leading-5">
                {extraData.option}
              </span>
            </span>
          )}
          <span className="flex items-start py-1">
            <FontAwesomeIcon
              className="mr-2 mt-1 fa-fw"
              icon={faNote}
              title="Notes"
            />
            <span className="leading-4">
              {message.body && (
                <TextWrapper
                  renderLinks={true}
                  text={message.body}
                  truncate={truncate}
                />
              )}
            </span>
          </span>
          {extraData.images && extraData.images.length > 0 && (
            <div className="border-t border-neutral-900/20">
              <div className="flex items-start py-1">
                <FontAwesomeIcon
                  className="mr-2 mt-1 fa-fw"
                  icon={faImage}
                  title="Images"
                />
                <span className="flex">Images</span>
              </div>
              <div className="grid grid-cols-3 gap-2">
                {extraData.images.map((img, index) => {
                  return (
                    <img
                      key={img}
                      alt="not found"
                      src={img}
                      className="w-32 rounded cursor-pointer shadow-md p-1 bg-white dark:bg-black"
                      onClick={() => handleLightbox(index)}
                    />
                  )
                })}
              </div>
              <Lightbox
                styles={{
                  root: { '--yarl__color_backdrop': '#000b' }
                }}
                open={open}
                close={() => setOpen(false)}
                slides={imageSrc}
                index={selectedIndex}
              />
            </div>
          )}
        </div>
        {truncate && message.body.replace(/(<([^>]+)>)/gi, '').length > 500 && (
          <>
            <div className="flex w-full justify-between mt-2">
              <button
                onClick={() => setTradeModal(true)}
                className="w-full primary-button text-sm"
              >
                Continue Reading
              </button>
            </div>
            <TradeModal
              open={tradeModal}
              setOpen={setTradeModal}
              message={message}
            />
          </>
        )}

        {message.edited && (
          <div className="flex w-full justify-between mt-2">
            <div className="rounded bg-neutral-200/75 dark:bg-neutral-800/75 py-0.5 px-2">
              <p className="text-neutral-700 dark:text-neutral-300 italic text-xs">
                Revised
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Trade.propTypes = {
  message: PropTypes.object.isRequired
}

export default Trade

const TradeModal = ({ open, setOpen, message }) => {
  return (
    <Modal
      title="View Trade"
      open={open}
      setOpen={setOpen}
      cancelText={'Dismiss'}
    >
      <Trade message={message} truncate={false} />
    </Modal>
  )
}

const FoundBySAM = () => {
  return (
    <span className="flex items-center">
      Found by S.A.M.
      <img
        src="https://s3.amazonaws.com/assets.dailyprofitslive.com/images/dps-icon.svg"
        alt="Found by S.A.M."
        className="ml-1 h-4 w-4"
      />
    </span>
  )
}
