import React, { useEffect, useState, useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import { useSortBy, useExpanded, useTable, useGroupBy } from 'react-table'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronRight,
  faChevronDown,
  faSpinner,
  faChevronUp
} from '@fortawesome/pro-solid-svg-icons'
import './table.style.css'
import { getPortfolio } from '../../../utils/APIs/miscellaneous'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as SETTINGS from '../../../constants/settings'

const TABLE_ICON_CLASSES =
  'w-5 h-5 md:w-3.5 md:h-3.5 ml-2 text-brand-primary-500 dark:text-brand-primary-400 hover:text-brand-primary-700 dark:hover:text-brand-primary-300'

const PortfolioTable = ({ portfolioId }) => {
  const { [SETTINGS.PORTFOLIO.key]: pSettings } = useContext(SiteConfigContext)
  const [portfolio, setPortfolio] = useState(null)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)

  const expandIcon = (
    <FontAwesomeIcon icon={faChevronRight} className={TABLE_ICON_CLASSES} />
  )
  const condenseIcon = (
    <FontAwesomeIcon icon={faChevronDown} className={TABLE_ICON_CLASSES} />
  )

  useEffect(() => {
    async function fetchData() {
      const response = await getPortfolio(portfolioId)
      if (response && response.status === 200) {
        setPortfolio(response.data)
      } else {
        setError(true)
      }
      setLoading(false)
    }

    fetchData()
  }, [])

  // use memoize functions for react-tables
  // useTable expects "data"
  const data = useMemo(() => portfolio || [], [portfolio])
  const hiddenColumns = useMemo(() =>
    Object.keys(pSettings).filter((set) => {
      if (pSettings[set] !== true) {
        return set
      }
    })
  )

  // set column headers and map to data (accessor)
  const columns = useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        className: 'w-8',
        Header: '',
        AltHeader: 'Expand Subtrades',
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? condenseIcon : expandIcon}
            </span>
          ) : null,
        propTypes: {
          getToggleAllRowsExpandedProps: PropTypes.any,
          isAllRowsExpanded: PropTypes.any,
          row: PropTypes.any
        } // tbh i have no clue but it stops proptypes from yelling at me
      },
      {
        Header: 'Group',
        accessor: 'group',
        headerClassName: 'md:w-32 lg:w-48 py-3',
        className: 'md:w-32 lg:w-48 py-3'
      },
      {
        Header: 'Company',
        accessor: 'name',
        headerClassName: 'md:w-32 lg:w-48 py-3',
        className: 'md:w-32 lg:w-48 py-3'
      },
      {
        Header: 'Symbol',
        accessor: 'symbol',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums'
      },
      {
        Header: 'Exp. Date',
        accessor: 'expirationDate',
        headerClassName: 'md:w-32',
        className: 'md:w-32 tabular-nums',
        Cell: ({ cell: { value } }) => createDateCell(value)
      },
      {
        Header: 'Fill Date',
        accessor: 'openDate',
        headerClassName: 'md:w-32',
        className: 'md:w-32 tabular-nums',
        Cell: ({ cell: { value } }) => createDateCell(value)
      },
      {
        Header: 'Open Price',
        accessor: 'openPrice',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums',
        Cell: ({ cell: { value } }) => createCurrencyCell(value)
      },
      {
        Header: 'Current Price',
        accessor: 'currentPrice',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums',
        Cell: ({ cell: { value } }) => createCurrencyCell(value)
      },
      {
        Header: 'Stop Price',
        accessor: 'stopPrice',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums',
        Cell: ({ cell: { value } }) => createCurrencyCell(value)
      },
      {
        Header: 'Gain',
        accessor: 'gain',
        headerClassName: 'md:w-48',
        className: 'md:w-48 tabular-nums',
        Cell: ({ cell: { value } }) => createPercentageCell(value)
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        headerClassName: 'md:w-32 lg:w-48',
        className: 'md:w-32 lg:w-48'
      }
    ],
    []
  )

  const createDateCell = (value) => {
    if (value) {
      return moment(value).format('MM/DD/YYYY')
    } else {
      return ''
    }
  }

  const createCurrencyCell = (value) => {
    if (typeof value !== 'number') {
      return ''
    }

    const neg = value < 0
    const absTotal = Math.abs(value)
    const formattedTotal = absTotal
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')

    return (neg ? '-$' : '$') + formattedTotal
  }

  const createPercentageCell = (value) => {
    if (typeof value !== 'number') {
      return null
    }

    const neg = value < 0
    const absTotal = Math.abs(value * 100)
    const formattedTotal = absTotal.toFixed(1)

    return (neg ? '-' : '') + formattedTotal + '%'
  }

  const getRowClasses = (row, lastRow) => {
    let classes =
      'even:bg-neutral-100 dark:even:bg-neutral-800 odd:bg-neutral-50 dark:odd:bg-neutral-900'
    classes += row.depth === 0 && row.subRows.length === 0 ? ' no-sub-rows' : ''
    classes += row.depth === 0 && row.isExpanded ? getExpandedRowBorder() : ''
    classes += row.depth !== 0 ? getSubRowBorder(lastRow) : ''
    return classes
  }

  const getCellClasses = (row, cell, index) => {
    let classes = 'text-left text-sm py-2 pl-4 md:pl-0'
    classes += ` ${cell.column.className}`
    classes += row.depth !== 0 ? ' sub-row-cell' : ''
    classes += index !== 0 && !cell.value ? ' cell-no-data' : ''
    return classes
  }

  const getExpandedRowBorder = () => {
    return ' bg-gradient-to-r from-brand-primary-300/15 to-brand-primary-300/15 dark:from-brand-primary-800/10 dark:to-brand-primary-800/10 border-t-2 border-x-brand-primary-500 border-t-brand-primary-500 sub-row-parent'
  }

  const getSubRowBorder = (lastRow) => {
    return lastRow
      ? ' bg-gradient-to-r from-brand-primary-300/15 to-brand-primary-300/15 dark:from-brand-primary-800/10 dark:to-brand-primary-800/10 border-b-2 border-x-brand-primary-500 border-b-brand-primary-500 sub-row'
      : ' bg-gradient-to-r from-brand-primary-300/15 to-brand-primary-300/15 dark:from-brand-primary-800/10 dark:to-brand-primary-800/10 border-x-brand-primary-500 sub-row'
  }

  const getDataHeader = (row, cell) => {
    if (row.depth > 0) {
      return cell.column.AltHeader
        ? `Subtrade of ${row.original.parent}`
        : cell.column.Header
    } else {
      return cell.column.Header || cell.column.AltHeader
    }
  }

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy
  } = useTable(
    {
      columns,
      data,
      initialState: {
        // initial sort
        sortBy: [
          {
            id: 'group',
            desc: false
          },
          {
            id: 'name',
            desc: false
          }
        ],
        hiddenColumns
      }
    },
    useGroupBy,
    useSortBy,
    useExpanded
  )

  if (loading) {
    return (
      <h1 className="flex items-center">
        Loading...{' '}
        <FontAwesomeIcon
          icon={faSpinner}
          className="h-4 w-4 ml-2 animate-spin"
        />
      </h1>
    )
  }

  if (!portfolio) {
    return <h1>Error loading data</h1>
  }

  if (error) {
    return (
      <div>
        failed to load <code>{JSON.stringify(error, null, 4)}</code>
      </div>
    )
  }

  const resetSort = () => {
    setSortBy([
      {
        id: 'group',
        desc: false
      },
      {
        id: 'name',
        desc: false
      }
    ])
  }

  let subRowLength = 0
  let counter = 0

  return (
    <div className="container mx-auto">
      <table
        className="tradeTable table-auto w-full bg-white dark:bg-neutral-900 text-black dark:text-neutral-100 drop-shadow-lg"
        {...getTableProps()}
      >
        <thead className="bg-neutral-300 dark:bg-neutral-700 text-left">
          {headerGroups.map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className="block md:table-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps([
                    {
                      className: column.headerClassName
                    }
                  ])}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="md:flex md:items-center">
                    {column.render('Header')}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon
                            icon={faChevronDown}
                            className={TABLE_ICON_CLASSES}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faChevronUp}
                            className={TABLE_ICON_CLASSES}
                          />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, index, array) => {
            prepareRow(row)

            if (subRowLength === counter) {
              subRowLength = 0
              counter = 0
            }

            if (row.subRows.length > 0 && row.isExpanded) {
              subRowLength = row.subRows.length
            } else if (subRowLength !== 0) {
              counter++
            }

            return (
              <React.Fragment key={`${row.original.group}-${row.id}`}>
                <GroupRow
                  row={row}
                  pastRow={array[index - 1]}
                  resetSort={resetSort}
                />
                <tr
                  key={row.id}
                  {...row.getRowProps()}
                  className={getRowClasses(row, subRowLength === counter)}
                >
                  {row.cells.map((cell, index) => (
                    <td
                      key={cell.id}
                      {...cell.getCellProps([
                        {
                          className: getCellClasses(row, cell, index),
                          style: cell.column.style,
                          'data-header': getDataHeader(row, cell)
                        }
                      ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

PortfolioTable.propTypes = {
  portfolioId: PropTypes.string.isRequired
}

const GroupRow = ({ row, pastRow, resetSort }) => {
  const isFirstRowInGroup =
    (!pastRow || row.original.group !== pastRow.original.group) &&
    row.original.group &&
    (!pastRow || (pastRow && pastRow.depth === 0))

  if (isFirstRowInGroup) {
    return (
      <tr
        className="bg-custom-gradient dark:bg-custom-gradient cursor-pointer group-row"
        role="row"
      >
        <td
          colSpan="10"
          role="cell"
          onClick={resetSort}
          className="md:pl-8 py-2"
        >
          <h3 className="font-bold">{row.original.group}</h3>
        </td>
      </tr>
    )
  }

  return null
}

export default PortfolioTable
