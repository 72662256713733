import React from 'react'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const SearchButton = () => {
  return (
    <button
      type="submit"
      aria-label="Submit search"
      className="relative inline-flex items-center h-full gap-x-1.5 rounded px-3 py-2 flex-shrink-0 border border-sky-400 bg-sky-200/70 hover:bg-sky-300 dark:border-sky-700 dark:bg-sky-800 dark:text-sky-300 dark:hover:bg-sky-700"
    >
      <FontAwesomeIcon icon={faMagnifyingGlass} />
    </button>
  )
}

export default SearchButton
