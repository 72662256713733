import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getAllSettings, putSetting, postSetting } from '../utils/APIs/settings'
import * as SETTINGS from '../constants/settings'
import * as PRODUCTS from '../constants/products'
import { getBotMessages } from '../utils/APIs/commandBot'
import { getActivePoll } from '../utils/APIs/polls'
import { getProductsAggregate } from '../utils/APIs/products'

function buildSettingsMap(fetchedSettings = []) {
  const settingsMap = {}

  const isObjectButNotArray = (setting) => {
    return (
      setting &&
      setting.value instanceof Object &&
      !Array.isArray(setting.value)
    )
  }

  SETTINGS.ALL_SETTINGS.forEach(({ key, default: defaultValue }) => {
    // we are iterating over each setting defined. For each one
    // we try to find it in the returned array of settings. If we do
    // we use the found value, otherwise we return the defined default
    const setting = fetchedSettings.find(({ setting }) => setting === key)
    settingsMap[key] = setting
      ? isObjectButNotArray(setting)
        ? { ...defaultValue, ...setting.value } // need to spread the saved object over the default one
        : setting.value
      : defaultValue
  })

  return settingsMap
}

// We define a default value for the context on creation
// We will want to have status for isLoading, isError, and settings
// By default we will assume isLoading to be true and isError and settings to be false/null
export const SiteConfigContext = React.createContext({
  isLoading: true,
  isError: false,
  settings: [],
  settingsMap: {},
  updateSetting: async () => {}
})

export const SiteConfigProvider = ({ children }) => {
  const [productsService, setProductService] = useState()
  const [state, setState] = useState({
    isLoading: true,
    isError: false,
    settings: [],
    settingsMap: buildSettingsMap()
  })

  const [activePoll, setActivePoll] = useState()
  const [botMessages, setBotMessages] = useState()
  const [replyTo, setReplyTo] = useState()

  useEffect(() => {
    loadSettings()
    loadBotMessages()
    loadActivePoll()
  }, [])

  const loadProduct = async () => {
    const response = await getProductsAggregate()

    if (response && response.status === 200) {
      const bundles = response.data['bundles'] || []
      const funnels = response.data['funnels'] || []
      const hub = response.data['hub'] || null
      const org = response.data['org'] || null
      const products = response.data['products'] || []

      const data = {}
      data[PRODUCTS.ORG] = org
      data[PRODUCTS.HUB] = hub
      data[PRODUCTS.BUNDLE] = bundles
      data[PRODUCTS.FUNNEL] = funnels
      data[PRODUCTS.PRODUCT] = products.find(
        (each) => each.itemNumber === process.env.REACT_APP_PRODUCT_CODE
      )
      data[PRODUCTS.OTHER_PRODUCTS] = products.filter(
        (each) => each.itemNumber !== process.env.REACT_APP_PRODUCT_CODE
      )

      setProductService(data)
    }
  }

  const loadSettings = async () => {
    // Set our state to isLoading
    setState((prevState) => ({ ...prevState, isLoading: true }))
    // get our settings
    const response = await getAllSettings()
    if (response && response.status === 200) {
      // update our state with settings
      // we will keep the array of settings as is
      // but also create a key value map of all settings
      const settings = [...response.data]
      const settingsMap = buildSettingsMap(settings)
      await loadProduct()
      setState({ isLoading: false, isError: false, settings, settingsMap })
    } else {
      setState({
        isLoading: false,
        isError: true,
        settings: [],
        settingsMap: {}
      })
    }
  }

  // This function will take a setting const and the new value
  // and make the update API call.
  const updateSetting = async ({ key }, value) => {
    // First we will find the setting in the settings array
    const settingsArray = [...state.settings]
    const settingIndex = settingsArray.findIndex(
      ({ setting }) => setting === key
    )
    if (settingIndex !== -1) {
      // we found the setting, so let's make the update call
      const settingId = settingsArray[settingIndex]._id
      const response = await putSetting(settingId, value)
      if (response && response.status === 200) {
        // we successfully updated the setting
        // update the setting in the array
        settingsArray[settingIndex] = response.data
      }
    } else {
      // no such setting was found, let's create it
      const response = await postSetting(key, value)
      if (response && response.status === 201) {
        // we successfully created the setting
        // push the new setting into our array
        settingsArray.push(response.data)
      }
    }
    // After either updating or creating the setting, we want
    // to update our state
    const updatedSettingsMap = buildSettingsMap(settingsArray)
    setState((old) => ({
      ...old,
      settings: settingsArray,
      settingsMap: updatedSettingsMap
    }))
  }

  const setSettings = (data) => {
    setState({
      ...state,
      settings: data,
      settingsMap: buildSettingsMap(data)
    })
  }

  const loadBotMessages = async () => {
    const response = await getBotMessages()
    if (response && response.status === 200) {
      setBotMessages(response.data)
    }
  }

  const updateActivePoll = (data) => {
    switch (data.status) {
      case 'POST':
        setActivePoll(data.poll)
        break
      case 'VOTE':
      case 'CLOSE':
        if (activePoll && data.poll._id === activePoll._id) {
          setActivePoll(data.poll)
        }
        break
      case 'ARCHIVE':
      case 'DELETE':
        if (activePoll && data.poll._id === activePoll._id) {
          setActivePoll(null)
        }
        break
    }
  }

  const loadActivePoll = async () => {
    const response = await getActivePoll()
    if (response && response.status === 200) {
      setActivePoll(response.data)
    }
  }

  const getActiveStreamer = () => {
    if (state.settingsMap[SETTINGS.STREAMERS.key]) {
      const found = state.settingsMap[SETTINGS.STREAMERS.key].find(
        (streamer) => streamer.active === true
      )
      if (found) {
        return found
      }
    }
    return null
  }

  const freeEnabled = () => {
    if (productsService) {
      return productsService?.[PRODUCTS.PRODUCT]?.access?.free?.enabled
    }
    return false
  }

  return (
    <SiteConfigContext.Provider
      value={{
        isLoading: state.isLoading,
        isError: state.isError,
        productsService,
        freeEnabled: freeEnabled(),
        updateSetting,
        setSettings,
        activePoll,
        updateActivePoll,
        botMessages,
        setBotMessages,
        replyTo,
        setReplyTo,
        activeStreamer: getActiveStreamer(),
        ...state.settingsMap
      }}
    >
      {children}
    </SiteConfigContext.Provider>
  )
}

SiteConfigProvider.propTypes = {
  children: PropTypes.any
}
