export function postAnnouncementValidation(limitsState, cta) {
  // Validate limits
  if (
    limitsState.title.exceeded ||
    limitsState.message.exceeded ||
    limitsState.ctaText.exceeded
  ) {
    return {
      error: true,
      message: 'One or more fields exceed the character limit.'
    }
  }

  // Validate CTA fields
  if ((cta.text && !cta.url) || (!cta.text && cta.url)) {
    return {
      error: true,
      message: 'Both CTA text and URL must be provided if either is present.'
    }
  }

  return { error: false, message: '' }
}
