import React, { useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import { analyticsReducer, initialState } from '../reducers/analyticsReducer'
import useIsPortraitReady from '../hooks/useIsPortraitReady'
import useExternalScript from '../hooks/useExternalScript'
import { PORTRAIT_READY } from '../constants/actions/analyticsActions'

export const AnalyticsContext = React.createContext({})

export const AnalyticsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(analyticsReducer, initialState)
  const externalScriptStatus = useExternalScript(
    'https://portrait-tracker.s3.amazonaws.com/all.js'
  )
  const isPortraitReady = useIsPortraitReady()

  useEffect(() => {
    if (isPortraitReady) {
      dispatch({ type: PORTRAIT_READY, payload: isPortraitReady })
    }
  }, [isPortraitReady, externalScriptStatus])

  // Update identity and store the value
  const updateIdentity = (email) => {
    if (state.portraitIsReady) {
      const { Portrait } = window
      Portrait.store('promo_email', { email }, () => {
        // Stash in Data Layer as well
        Portrait.dataLayer.identity.email = email
        return true
      })
    } else return false
  }

  const updatePageMeta = (metaObject) => {
    if (state.portraitIsReady) {
      const { Portrait } = window
      const {
        dataLayer: { page }
      } = Portrait
      Portrait.dataLayer.page = { ...page, ...metaObject }
    }
  }

  const pushEvent = (category, action, label) => {
    if (state.portraitIsReady) {
      const { dataLayer } = window
      dataLayer.push({
        event: 'event_triggered',
        event_category: category,
        event_action: action,
        event_label: label
        // anythingelse: 'moredata'
      })
    }
  }

  const values = {
    portraitIsReady: state.portraitIsReady,
    portraitInstance: state.portraitInstance,
    updateIdentity,
    updatePageMeta,
    pushEvent
  }

  return (
    <AnalyticsContext.Provider value={values}>
      {children}
    </AnalyticsContext.Provider>
  )
}

AnalyticsProvider.propTypes = {
  children: PropTypes.any
}
