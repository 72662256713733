import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faScrewdriverWrench,
  faGavel,
  faChessKing,
  faSealQuestion,
  faRobot,
  faLeaf,
  faMicrophoneLines
} from '@fortawesome/pro-solid-svg-icons'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import * as ROLES from '../../constants/roles'

const Badge = ({ badge, size }) => {
  const [tooltipVisibility, setTooltipVisibility] = useState(false)

  let color, icon, title

  switch (badge) {
    case 'new':
      color = 'green'
      icon = faLeaf
      title = 'New Member'
      break
    case ROLES.ADMIN:
      color = 'violet'
      icon = faScrewdriverWrench
      title = 'Administrator'
      break
    case ROLES.HOST:
      color = 'red'
      icon = faMicrophoneLines
      title = 'Host'
      break
    case ROLES.MODERATOR:
      color = 'orange'
      icon = faGavel
      title = 'Moderator'
      break
    case ROLES.VIP:
      color = 'yellow'
      icon = faChessKing
      title = 'VIP'
      break
    case ROLES.BOT:
      color = 'lime'
      icon = faRobot
      title = 'BOT'
      break
    default:
      color = 'neutral'
      icon = faSealQuestion
      title = 'Unknown Badge'
      break
  }

  switch (size) {
    case '1x':
      size = 'fa-1x'
      break
    case '2x':
      size = 'fa-2x'
      break
    case '3x':
      size = 'fa-3x'
      break
    case '4x':
      size = 'fa-4x'
      break
  }

  const handleTooltip = () => {
    setTooltipVisibility(!tooltipVisibility)
  }

  const handleMouseLeave = () => {
    setTooltipVisibility(false)
  }

  return (
    <>
      <FontAwesomeIcon
        onMouseEnter={handleTooltip}
        onMouseLeave={handleMouseLeave}
        icon={icon}
        className={`${size} mr-1.5 text-${color}-500`}
      />
      <MouseTooltip
        visible={tooltipVisibility}
        offsetX={4}
        offsetY={-35}
        className="z-[9999] shadow-md px-2 py-1 rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-100 dark:bg-neutral-800"
      >
        <span className="text-neutral-800 dark:text-neutral-200 text-sm font-light">
          {title}
        </span>
      </MouseTooltip>
    </>
  )
}

Badge.propTypes = {
  badge: PropTypes.string.isRequired,
  size: PropTypes.string
}

export default Badge
