import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { getBadges } from '../../../../utils/APIs/badges'
import * as SETTINGS from '../../../../constants/settings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAdd, faTrashCan } from '@fortawesome/pro-solid-svg-icons'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import BadgeBuilder from './BadgeBuilder'
import { toast } from 'react-toastify'
import { setPageTitle } from '../../../../utils/utilities'

const Memberships = () => {
  setPageTitle('Configuration | Memberships')
  const { [SETTINGS.MEMBERSHIPS.key]: memberships, updateSetting } =
    useContext(SiteConfigContext)
  const [badges, setBadges] = useState({
    badges: [],
    isLoading: true,
    isError: false
  })

  useEffect(() => {
    fetchBadges()
  }, [])

  const fetchBadges = async () => {
    setBadges({ badges: [], isLoading: true, isError: false })
    const response = await getBadges()
    if (response && response.status === 200) {
      setBadges({ badges: response.data, isLoading: false, isError: false })
    } else {
      setBadges({ badges: [], isLoading: false, isError: true })
    }
  }

  const handleAdd = async (type, newBadge) => {
    const copy = memberships
    copy[type].push(newBadge)
    await updateSetting(SETTINGS.MEMBERSHIPS, copy)
    toast.success('Membership added!')
  }
  const handleDelete = async (type, index) => {
    const copy = memberships
    copy[type].splice(index, 1)
    await updateSetting(SETTINGS.MEMBERSHIPS, copy)
    toast.success('Membership deleted!')
  }

  return (
    <div className="flex flex-col gap-2">
      <h1>Membership Configuration</h1>
      <MembershipsByType
        type="main"
        memberships={memberships.main}
        processAdd={handleAdd}
        processDelete={handleDelete}
        badgesObj={badges}
      />
      <MembershipsByType
        type="additional"
        memberships={memberships.additional}
        processAdd={handleAdd}
        processDelete={handleDelete}
        badgesObj={badges}
      />
      <BadgeBuilder badgesObj={badges} fetchBadges={fetchBadges} />
    </div>
  )
}

const MembershipsByType = ({
  type,
  memberships,
  processAdd,
  processDelete,
  badgesObj
}) => {
  const [subType, setSubType] = useState('')
  const [title, setTitle] = useState('')
  const [badge, setBadge] = useState('')

  const [chooseNameColor, setChooseNameColor] = useState(false)

  const { badges, isLoading, isError } = badgesObj

  const handleAdd = () => {
    if (subType !== '' && badge !== '') {
      const selectedBadge = badges.find((b) => b.name === badge)
      processAdd(type, {
        subType: subType.toUpperCase(),
        title,
        badge: selectedBadge,
        features: [
          {
            label: 'Choose Name Color',
            value: chooseNameColor
          }
        ]
      })
      setSubType('')
      setTitle('')
      setBadge('')
      setChooseNameColor(false)
    }
  }

  const handleDelete = (index) => {
    processDelete(type, index)
  }

  return (
    <div className="h-fit mb-4">
      <h2 className="mb-1">{type.toUpperCase()}</h2>
      <div className="flex flex-col gap-2 border-l-2 border-blue-600">
        {!isLoading &&
          !isError &&
          memberships.map((membership, index) => (
            <div className="flex pl-4" key={index}>
              <div className="flex flex-col w-full">
                <div className="flex flex-col md:flex-row md:items-center gap-2">
                  <div className="grow input">{membership.subType}</div>
                  <div className="grow input">{membership.title}</div>
                  <div className="grow input flex items-center">
                    <FontAwesomeIcon
                      className={`text-${membership.badge.color}-500`}
                      icon={membership.badge.icon}
                    />
                    <p className="ml-2 pl-2 border-l border-l-neutral-300 dark:border-neutral-700">
                      {membership.badge.title}
                    </p>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex items-center mr-4">
                    {membership.features &&
                      membership.features.map((ftr, index) => {
                        return (
                          <div key={index}>
                            <input
                              type="checkbox"
                              checked={ftr.value}
                              disabled
                            />
                            <label className="ml-2 text-sm font-medium text-neutral-900 dark:text-neutral-300">
                              {ftr.label}
                            </label>
                          </div>
                        )
                      })}
                  </div>
                </div>
              </div>
              <button
                className="flex items-center p-2 rounded-full bg-red-500 dark:bg-red-700 text-white w-8 h-8 ml-2"
                type="button"
                onClick={() => handleDelete(index)}
              >
                <FontAwesomeIcon icon={faTrashCan} className="w-4 h-4" />
              </button>
            </div>
          ))}
        <div className="flex pl-4">
          <div className="flex flex-col w-full">
            <div className="flex flex-col md:flex-row md:items-center gap-2">
              <input
                value={subType}
                onChange={(e) => setSubType(e.target.value)}
                className="input w-full"
                placeholder="Subtype"
              />
              <input
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="input w-full"
                placeholder="Title"
              />
              {isLoading ? (
                <p>Loading...</p>
              ) : isError ? (
                <p>Error</p>
              ) : (
                <select
                  className="input w-full"
                  value={badge}
                  onChange={(e) => setBadge(e.target.value)}
                  placeholder="Badge"
                >
                  <option key="placeholder" value="PLACEHOLDER">
                    Please select a badge
                  </option>
                  {badges.map((badge) => (
                    <option key={badge._id} value={badge.name}>
                      {badge.title}
                    </option>
                  ))}
                </select>
              )}
            </div>

            <div className="flex">
              <div className="flex items-center mr-4">
                <input
                  type="checkbox"
                  checked={chooseNameColor}
                  onChange={() => setChooseNameColor(!chooseNameColor)}
                  required
                />
                <label className="ml-2 text-sm font-medium text-neutral-900 dark:text-neutral-300">
                  Choose Name Color
                </label>
              </div>
            </div>
          </div>
          <button
            className={`flex items-center p-2 rounded-full ${
              subType === '' || title === '' || badge === ''
                ? 'bg-gray-500/50 dark:bg-gray-700/50'
                : 'bg-green-500 dark:bg-green-700'
            } text-white w-8 h-8 ml-2`}
            type="button"
            onClick={handleAdd}
            disabled={subType === '' || title === '' || badge === ''}
          >
            <FontAwesomeIcon icon={faAdd} className="w-4 h-4" />
          </button>
        </div>
      </div>
    </div>
  )
}

MembershipsByType.propTypes = {
  type: PropTypes.string,
  memberships: PropTypes.array,
  processAdd: PropTypes.func,
  processDelete: PropTypes.func,
  badgesObj: PropTypes.object
}

export default Memberships
