import React, { useState, useEffect, useContext } from 'react'
import { SocketContext } from '../../../context/socketContext'
import {
  getModChatIsOpen,
  toggleModChatIsOpen
} from '../../../utils/APIs/privateMessages'
import { PRIVATE_MESSAGE_EVENTS } from '../../../constants/sockets'

export const CommandCenterControls = () => {
  const [isChecked, setIsChecked] = useState(false)
  const { privateMessagesSocket } = useContext(SocketContext)

  useEffect(() => {
    const getCommandCenterModChat = async () => {
      const response = await getModChatIsOpen()
      if (response && response.status === 200) {
        setIsChecked(response.data.isOpen)
      }
    }
    getCommandCenterModChat()
  }, [])

  useEffect(() => {
    if (privateMessagesSocket) {
      privateMessagesSocket.on(
        PRIVATE_MESSAGE_EVENTS.TOGGLE_MOD_CHAT,
        handleToggle
      )
    }

    return () => {
      if (privateMessagesSocket) {
        privateMessagesSocket.off(
          PRIVATE_MESSAGE_EVENTS.TOGGLE_MOD_CHAT,
          handleToggle
        )
      }
    }
  }, [privateMessagesSocket])

  const handleToggle = async ({ isOpen = null }) => {
    if (typeof isOpen === 'boolean') {
      setIsChecked(isOpen)
      return
    } else {
      const response = await toggleModChatIsOpen()
      if (response && response.status === 200) {
        setIsChecked(response.data.isOpen)
      }
    }
  }

  return (
    <div>
      <input type="checkbox" checked={isChecked} onChange={handleToggle} />
      <label>
        <p className="inline ml-2">
          {isChecked
            ? 'Command Center Mod Chat is open'
            : 'Command Center Mod Chat is closed'}
        </p>
      </label>
    </div>
  )
}

export default CommandCenterControls
