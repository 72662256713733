import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { SiteConfigContext } from '../../context/siteConfigContext'
import * as PRODUCTS from '../../constants/products'

const LogoIcon = ({ product, size, displayName = false }) => {
  const { productsService } = useContext(SiteConfigContext)

  // see if the source is the product they are in or the hub
  let productIcon = [PRODUCTS.PRODUCT, PRODUCTS.HUB]
    .map((key) => productsService[key])
    .find((item) => item.itemNumber.toUpperCase() === product.toUpperCase())

  // if not check other products
  if (!productIcon) {
    productIcon = productsService[PRODUCTS.OTHER_PRODUCTS].find(
      (item) => item.itemNumber.toUpperCase() === product.toUpperCase()
    )
  }

  if (!productIcon) {
    return null
  }

  const classes = () => {
    switch (size) {
      case 'sm':
        return 'h-4 w-4'
      default:
        return 'h-8 w-8'
    }
  }

  return (
    <>
      <img
        className={`${classes()} inline ${
          product.toUpperCase() === 'COMMAND' &&
          'bg-neutral-500 dark:bg-transparent rounded-sm'
        }`}
        src={productIcon.graphics.icon}
        alt={productIcon.name}
      />
      {displayName && <span className="ml-1">{productIcon.name}</span>}
    </>
  )
}

LogoIcon.propTypes = {
  product: PropTypes.string.isRequired,
  size: PropTypes.string,
  displayName: PropTypes.bool
}

export default LogoIcon
