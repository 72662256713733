import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation } from '@fortawesome/pro-solid-svg-icons'

const ErrorRow = () => {
  return (
    <div className="flex w-full justify-between items-center ">
      <p className="flex items-center whitespace-nowrap p-2 font-bold text-red-500">
        <FontAwesomeIcon
          className="mr-2 h-5 cursor-pointer"
          icon={faExclamation}
        />
        ERROR
      </p>
    </div>
  )
}

export default ErrorRow
