import React, { Fragment, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import Copywrite from '../../Utilities/Copywrite'
import styles from './Footer.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons'
import { SiteConfigContext } from '../../../context/siteConfigContext'
import * as PRODUCTS from '../../../constants/products'

const Footer = () => {
  const { freeEnabled, productsService } = useContext(SiteConfigContext)

  const hubURL = productsService[PRODUCTS.HUB].urls.website

  return (
    <div className="flex flex-col gap-1 xl:gap-2 xl:flex-row text-xs mx-auto justify-center items-center">
      <Copywrite />
      {freeEnabled && (
        <Fragment>
          <Link
            to={`${hubURL}/privacy-policy`}
            target="_blank"
            className={styles.footerItem}
          >
            Privacy Policy
            <FontAwesomeIcon
              icon={faExternalLink}
              className="w-2 h-2 text-black text-opacity-50 dark:text-white dark:text-opacity-50"
            />
          </Link>
          <Link
            to={`${hubURL}/privacy-policy`}
            target="_blank"
            className={styles.footerItem}
          >
            Do Not Sell or Share My Personal Information
            <FontAwesomeIcon
              icon={faExternalLink}
              className="w-2 h-2 text-black text-opacity-50 dark:text-white dark:text-opacity-50"
            />
          </Link>
        </Fragment>
      )}
    </div>
  )
}

export default Footer
