import React, { useContext } from 'react'
import { SiteConfigContext } from '../../../../context/siteConfigContext'
import { toast } from 'react-toastify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../../../constants/settings'

const Features = () => {
  const { [SETTINGS.FEATURES.key]: features, updateSetting } =
    useContext(SiteConfigContext)

  const titleCase = (str) => {
    return str.toLowerCase().replace(/(^|\s)\w/g, function (match) {
      return match.toUpperCase()
    })
  }

  const handleFeatureChange = async (key, value) => {
    const data = { ...features, [key]: value }
    updateSetting(SETTINGS.FEATURES, data)
    toast.success(`${titleCase(key)} ${value ? 'Enabled' : 'Disabled'}`)
  }

  function processFeatures(array, property, ascending = true) {
    const clean = array.filter(
      (each) => SETTINGS.FEATURES_DEFAULT[each[0]] !== undefined
    ) // this way if we remove a feature it doesn't show up in the menu anymore

    return clean.sort((item1, item2) => {
      // Helper function to handle comparison logic
      function getComparisonValue(item, property) {
        if (!item[property]) return ascending ? 1 : -1 // Handle undefined for sorting

        return typeof item[property] === 'string'
          ? item[property].localeCompare(item2[property]) // Compare strings
          : item[property] - item2[property] // Compare numbers
      }

      const comparisonResult = getComparisonValue(item1, property)
      return ascending ? comparisonResult : -comparisonResult
    })
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Features</h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
        {features &&
          processFeatures(Object.entries(features), 0).map(([key, value]) => {
            return (
              <label
                tabIndex={0}
                htmlFor={key}
                key={key}
                className={`cursor-pointer flex w-full items-center justify-between px-2 py-1 gap-1.5 rounded border transition-all ${
                  value
                    ? 'bg-green-500/15 hover:bg-green-500/25 border-green-500/50'
                    : 'bg-red-500/15 hover:bg-red-500/25 border-red-500/50'
                }`}
              >
                <p className="select-none">{key.toUpperCase()}</p>
                <input
                  id={key}
                  name={key}
                  type="checkbox"
                  checked={value}
                  onChange={() => handleFeatureChange(key, !value)}
                  className="hidden"
                />
                <p className="sr-only">{value ? `on` : `off`}</p>
                <FontAwesomeIcon
                  icon={value ? faCircleCheck : faCircleXmark}
                  className={`${value ? 'text-green-500' : 'text-red-500'}`}
                />
              </label>
            )
          })}
      </div>
    </div>
  )
}

export default Features

function titleCase(str) {
  return str.toLowerCase().replace(/(^|\s)\w/g, function (match) {
    return match.toUpperCase()
  })
}

const PortfolioID = () => {
  const { [SETTINGS.PORTFOLIO_ID.key]: id, updateSetting } =
    useContext(SiteConfigContext)

  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = formData.get('id')
    await updateSetting(SETTINGS.PORTFOLIO_ID, data)
    toast.success('Portfolio ID saved.')
  }

  return (
    <div className="flex flex-col gap-2">
      <h2>Portfolio ID</h2>
      <form
        onSubmit={(e) => handleSubmit(e)}
        autoComplete="off"
        className="flex gap-2"
      >
        <input
          id="id"
          name="id"
          defaultValue={id}
          className="input block w-full"
          placeholder="Portfolio ID"
        />
        <button type="submit" className="primary-button w-fit">
          Save
        </button>
      </form>
    </div>
  )
}
