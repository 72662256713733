import React from 'react'
import PropTypes from 'prop-types'

import ChatForm from '../../Forms/ChatForm'
import PostTradeForm from '../../Forms/PostTradeForm'

import { EDIT } from '../../../constants/chatForms'
import { TRADES, MEMBERS } from '../../../constants/chatRooms'
import Modal from '../../Utilities/Modal'

const EditModal = ({ open, setOpen, message, channel, dispatch }) => {
  const title = channel === TRADES ? 'Edit Trade' : 'Edit Message'
  return (
    <Modal
      title={title}
      open={open}
      setOpen={setOpen}
      size={`${channel === MEMBERS ? 'md' : null}`}
    >
      {channel === TRADES ? (
        <PostTradeForm
          edit
          closeModal={setOpen}
          prefill={message}
          dispatch={dispatch}
        />
      ) : (
        <ChatForm
          channel={channel}
          type={EDIT}
          id={message._id}
          prefill={message.message}
          handleCancel={() => setOpen(false)}
          toUser={message?.toUser?._id}
          dispatch={dispatch}
        />
      )}
    </Modal>
  )
}

EditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.object.isRequired,
  channel: PropTypes.string.isRequired
}

export default EditModal
