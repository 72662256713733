import axios from 'axios'
import { TRADES } from '../ENV'

export const postTrade = async ({ user, data }) => {
  try {
    const response = await axios
      .post(`${TRADES}/`, formatData(user, data))
      .then(async function (response) {
        return response
      })
      .catch(function (error) {
        console.log('postTrade Service', error.response.statusText)
        return error.response.data
      })
    return response
  } catch (error) {
    console.log(error.response)
  }
}

export const getTradesByItemNumber = async ({ itemNumber, loadMore }) => {
  const addLoadMore = loadMore ? `&loadMore=${loadMore}` : ''
  try {
    const response = await axios.get(
      `${TRADES}/itemNumber/${itemNumber}/?${addLoadMore}`
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getTradesBySearch = async ({ search, itemNumber, loadMore }) => {
  const addLoadMore = loadMore ? `&loadMore=${loadMore}` : ''
  try {
    const response = await axios.get(
      `${TRADES}/itemNumber/${itemNumber}/search/${search}/?${addLoadMore}`
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const putTrade = async ({ user, data }) => {
  try {
    const response = await axios
      .put(`${TRADES}/${data._id}`, formatData(user, data))
      .then(async function (response) {
        return response
      })
      .catch(function (error) {
        return error.response.data
      })
    return response
  } catch (error) {
    console.log(error.response)
  }
}

export const deleteTrade = async ({ id }) => {
  try {
    const response = await axios
      .delete(`${TRADES}/${id}`)
      .then(async function (response) {
        return response
      })
      .catch(function (error) {
        return error.response.data
      })
    return response
  } catch (error) {
    console.log(error.response)
  }
}

export const alertTrade = async ({ id }) => {
  try {
    const response = await axios
      .post(`${TRADES}/alert/${id}`)
      .then(async function (response) {
        return response
      })
      .catch(function (error) {
        return error.response.data
      })
    return response
  } catch (error) {
    console.log(error.response)
  }
}

const formatData = (user, data) => {
  const newTrade = {
    itemNumber:
      data.PMK && data.PMK === true
        ? 'PMK'
        : process.env.REACT_APP_PRODUCT_CODE,
    type: data.type,
    headline: data.headline,
    body: data.body,
    extra: [],
    user: user.userService._id
  }

  if (data.option) {
    newTrade.extra.push({
      key: 'option',
      type: 'plain-text',
      value: data.option
    })
  }

  if (data.images) {
    newTrade.extra.push({
      key: 'images',
      type: 'images',
      value: data.images
    })
  }

  if (data.foundBySAM) {
    newTrade.extra.push({
      key: 'foundBySAM',
      type: 'boolean',
      value: data.foundBySAM
    })
  }

  return newTrade
}
