import {
  ERROR,
  LOADING,
  RESET,
  INITIALIZE,
  TITLE,
  LIVE_CONFIRMATION,
  RESEND_CONFIRMATION
} from '../constants/actions/modSettingsActions'

export const initialState = {
  error: false,
  loading: false,
  live: false,
  open: false,
  title: '',
  liveConfirmation: false,
  resendConfirmation: false
}

export function modSettingsReducer(state, action) {
  switch (action.type) {
    case RESET:
      return initialState

    case ERROR:
      return {
        ...state,
        error: action.payload
      }

    case LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case INITIALIZE:
      return {
        ...state,
        live: action.payload.live,
        open: action.payload.open,
        title: action.payload.title
      }

    case TITLE:
      return {
        ...state,
        title: action.payload
      }

    case LIVE_CONFIRMATION:
      return {
        ...state,
        liveConfirmation: action.payload
      }

    case RESEND_CONFIRMATION:
      return {
        ...state,
        resendConfirmation: action.payload
      }

    default:
      return state
  }
}
