import React from 'react'
import { toast } from 'react-toastify'
import { putProduct } from '../../../../utils/APIs/products'
import ToggleSwitchBasic from '../../../Forms/common/ToggleSwitchBasic'

const Free = ({ product, updateState }) => {
  const handleToggle = async () => {
    const data = {
      ...product,
      access: {
        ...product.access,
        free: {
          ...product.access.free,
          enabled: !product.access.free.enabled
        }
      }
    }

    await putProduct(data)
    updateState(data)
    toast.success('Open House toggled.')
  }

  return (
    <div className="flex flex-col gap-2">
      <div className="flex gap-2 justify-start">
        <h2>Free</h2>
        <ToggleSwitchBasic
          value={product.access.free.enabled}
          label=""
          color="green"
          onChange={handleToggle}
        />
      </div>
      {product.access.free.enabled && (
        <>
          <SignUpList product={product} updateState={updateState} />
        </>
      )}
    </div>
  )
}

const SignUpList = ({ product, updateState }) => {
  const handleSubmit = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const data = {
      ...product,
      access: {
        ...product.access,
        free: {
          ...product.access.free,
          listCodes: formData.get('listCodes'),
          sourceId: formData.get('sourceId'),
          emailOversightId: formData.get('emailOversightId')
        }
      }
    }

    await putProduct(data)
    updateState(data)
    toast.success('Free Sign Up List saved.')
  }

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-2">
        <h3>Free Sign Up List</h3>
        <form
          onSubmit={(e) => handleSubmit(e)}
          autoComplete="off"
          className="flex gap-2"
        >
          <label className="block w-full" htmlFor="listCodes">
            <h4>List Code</h4>
            <input
              id="listCodes"
              name="listCodes"
              placeholder="List Code"
              defaultValue={product.access.free.listCodes}
              className="input block w-full"
            />
          </label>
          <label className="block w-full" htmlFor="sourceId">
            <h4>Source ID</h4>
            <input
              id="sourceId"
              name="sourceId"
              placeholder="Source ID"
              defaultValue={product.access.free.sourceId}
              className="input block w-full"
            />
          </label>
          <label className="block w-full" htmlFor="sourceId">
            <h4>Email Oversight ID</h4>
            <input
              id="emailOversightId"
              name="emailOversightId"
              placeholder="Email Oversight ID"
              defaultValue={product.access.free.emailOversightId}
              className="input block w-full"
            />
          </label>
          <button type="submit" className="primary-button w-fit mt-auto">
            Save
          </button>
        </form>
      </div>
      <div className="flex">
        <p className="text-sm text-red-500">
          <strong>NOTE:</strong> Please put the primary list code first.
        </p>
      </div>
    </div>
  )
}

export default Free
