import React from 'react'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faUser,
  faCheck,
  faTimes,
  faThumbTack
} from '@fortawesome/pro-solid-svg-icons'

const UserRow = ({ user, removeFromList, togglePinned, allUsers }) => {
  const awaitingReply = allUsers ? true : user.awaitingReply
  const { privateMessageId: activeUser } = useParams()

  const userLinkClasses = `flex w-full rounded items-center whitespace-nowrap px-2 py-1 transition-all hover:bg-neutral-100 dark:hover:bg-neutral-800 ${
    user._id === activeUser
      ? 'bg-green-200 dark:bg-neutral-700'
      : 'bg-neutral-200 dark:bg-neutral-900'
  } ${
    awaitingReply
      ? 'text-black dark:text-white'
      : 'text-green-600 dark:text-green-400'
  }`

  const pinBtnColors = user.pinned
    ? 'bg-green-500 hover:bg-green-600 active:bg-green-700 -rotate-45'
    : 'bg-neutral-400 hover:bg-neutral-500 active:bg-neutral-600'

  return (
    <div className="flex justify-between items-center w-full">
      <Link
        className={userLinkClasses}
        title={user.userService.profile.name}
        to={`us/${user.userService._id}/pm/${user._id}`}
      >
        {awaitingReply ? (
          <FontAwesomeIcon className="mr-2 h-5 cursor-pointer" icon={faUser} />
        ) : (
          <FontAwesomeIcon className="mr-2 h-5 cursor-pointer" icon={faCheck} />
        )}
        {user.userService.profile.name}
      </Link>
      {removeFromList && (
        <div className="p-1">
          <button
            className="flex justify-around text-white rounded-full items-center bg-red-500 hover:bg-red-600 active:bg-red-700 h-6 w-6 mx-1"
            onClick={() => removeFromList(user._id)}
          >
            <FontAwesomeIcon className="h-3.5 w-3.5" icon={faTimes} />
          </button>
        </div>
      )}
      {togglePinned && (
        <div className="p-1">
          <button
            className={`flex justify-around text-white rounded-full items-center h-6 w-6 mx-1 ${pinBtnColors}`}
            onClick={() => togglePinned(user._id)}
          >
            <FontAwesomeIcon
              className="h-3.5 w-3.5"
              icon={faThumbTack}
              rotate={45}
            />
          </button>
        </div>
      )}
    </div>
  )
}

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  removeFromList: PropTypes.func,
  togglePinned: PropTypes.func,
  allUsers: PropTypes.bool
}

export default UserRow
