import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../context/authenticationContext'
import RenameForm from '../../components/Forms/RenameForm'
import Copywrite from '../../components/Utilities/Copywrite'
import Logo from '../../graphics/Logo'
import { replaceDefaultColor } from '../../utils/colors'
import { setPageTitle } from '../../utils/utilities'

const Register = () => {
  setPageTitle('Register')
  const { user, updateUser } = useContext(AuthenticationContext)
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      replaceDefaultColor(user, updateUser)
    }
  }, [user])

  useEffect(() => {
    if (user && user.userService.profile.name !== '') {
      navigate('/', { replace: true })
    }
  }, [user])

  return (
    <main className="ios-safari-hack bg-custom-gradient flex h-screen max-h-screen flex-col overflow-hidden transition-all ease-in-out duration-300">
      <div className="m-auto flex h-full w-fit flex-col justify-around">
        <div className="mx-auto my-auto">
          <div className="h-8 md:h-12 my-4">
            <Logo />
          </div>
        </div>
        <div className="mb-auto">
          <div className="splash-container">
            <h1 className="my-2 md:my-6 text-center font-bold tracking-wide">
              Register Display Name
            </h1>
            <div className="flex w-fit justify-center">
              <div className="flex flex-col gap-2 px-2 mb-4">
                <RenameForm user={user} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-auto flex items-center justify-center">
          <Copywrite />
        </div>
      </div>
    </main>
  )
}

export default Register
