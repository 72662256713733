import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExternalLinkIcon from '../../../graphics/ExternalLinkIcon'

const HeadquartersButton = ({ title, link, content, icon, primary }) => {
  return (
    <Link
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      className={`w-full  ${primary ? 'col-span-3' : 'col-span-1'}`}
    >
      <div
        className={`h-full overflow-hidden ${
          primary ? 'primary-button' : 'secondary-button'
        }`}
      >
        <div className="px-1 py-2 sm:px-2">
          <ExternalLinkIcon />
          <h3 className="flex items-center font-semibold gap-2">
            {title}
            {icon && <FontAwesomeIcon icon={icon} className="h-5 w-5" />}
          </h3>
          <p className="mt-1.5 max-w-2xl text-sm font-medium">{content}</p>
        </div>
      </div>
    </Link>
  )
}

HeadquartersButton.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  icon: PropTypes.object,
  primary: PropTypes.bool
}

export default HeadquartersButton
