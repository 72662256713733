import React, { Fragment, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faChevronLeft,
  faGears,
  faTimes,
  faGear,
  faTypewriter,
  faComments,
  faCommentsDollar,
  faCommentsQuestion,
  faMemory,
  faRobot,
  faAd,
  faSquarePollHorizontal,
  faFileCsv,
  faCommand,
  faChartLine,
  faBook,
  faBellExclamation
} from '@fortawesome/pro-solid-svg-icons'
import { AuthenticationContext } from '../../../context/authenticationContext'
import * as SETTINGS from '../../../constants/settings'
import * as FEATURES from '../../../constants/features'
import * as ROLES from '../../../constants/roles'
import { SiteConfigContext } from '../../../context/siteConfigContext'

const accessLevel = (roles) => {
  const access = {}
  roles.forEach((role) => {
    access[role] = true
  })
  return access
}

const navOptionsDefault = [
  {
    title: 'settings',
    icon: faGear,
    label: 'Stream Settings',
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST, ROLES.VIP])
  },
  {
    title: 'post-trade',
    icon: faTypewriter,
    label: 'Post a New Trade',
    feature: FEATURES.TRADES,
    enabled: false,
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST])
  },
  {
    title: 'post-announcement',
    icon: faBellExclamation,
    label: 'Send Announcement',
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST])
  },
  {
    title: 'chat-history',
    icon: faComments,
    label: 'Chat History',
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST])
  },
  {
    title: 'trade-history',
    icon: faCommentsDollar,
    label: 'Trade History',
    feature: FEATURES.TRADES,
    enabled: false,
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST])
  },
  {
    title: 'private-messages',
    icon: faCommentsQuestion,
    label: 'Private Messages',
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST])
  },
  {
    title: 'command-bot',
    icon: faRobot,
    label: 'Command Bot',
    feature: FEATURES.COMMAND_BOT,
    enabled: false,
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST, ROLES.VIP])
  },
  {
    title: 'polls',
    icon: faSquarePollHorizontal,
    label: 'Polls',
    feature: FEATURES.POLLS,
    enabled: false,
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST, ROLES.VIP])
  },
  {
    title: 'stats',
    icon: faChartLine,
    label: 'Stats',
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST, ROLES.VIP])
  },
  {
    title: 'ad-builder',
    icon: faAd,
    label: 'Ad Builder',
    feature: FEATURES.AD_BUILDER,
    enabled: false,
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST])
  },
  {
    title: 'user-dump',
    icon: faFileCsv,
    label: 'User Dump',
    access: accessLevel([ROLES.ADMIN])
  },
  {
    title: 'cache',
    icon: faMemory,
    label: 'Cache',
    access: accessLevel([ROLES.ADMIN])
  },
  {
    title: 'content',
    icon: faBook,
    label: 'Content',
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR])
  },
  {
    title: 'configuration',
    icon: faGears,
    label: 'Configuration',
    access: accessLevel([ROLES.ADMIN])
  },
  {
    title: 'post-pmk-trade',
    icon: faTypewriter,
    label: 'Post a PMK Trade',
    feature: FEATURES.TRADES,
    enabled: false,
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST, ROLES.VIP])
  },
  {
    title: 'command-center-admin',
    icon: faCommand,
    label: 'Command Center Admin',
    feature: FEATURES.HUB,
    enabled: false,
    access: accessLevel([ROLES.ADMIN, ROLES.MODERATOR, ROLES.HOST, ROLES.VIP])
  }
]

const Nav = () => {
  const { [SETTINGS.FEATURES.key]: enabledFeatures } =
    useContext(SiteConfigContext)
  const [navOptions, setNavOptions] = useState(navOptionsDefault)

  const [active, setActive] = useState()
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const update = [...navOptions]
    update.forEach(async (obj) => {
      if (obj.feature) {
        obj.enabled = enabledFeatures && enabledFeatures[obj.feature]
      }
    })
    setNavOptions(update)
  }, [enabledFeatures])

  useEffect(() => {
    const locationSplit = location.pathname.split('/')
    const current = navOptions.find((e) => e.title === locationSplit[2])
    if (current) setActive(current)
  }, [])

  const goToParent = () => {
    const locationSplit = location.pathname.split('/')
    // 2 to skip the /mod/ part
    navigate(locationSplit[2], { replace: true })
  }

  const handleNavClick = (option) => {
    setActive(option)
    setSidebarOpen(false)
  }

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-neutral-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="w-5 h-5 text-white hover:text-red-500"
                      />
                    </button>
                  </div>
                </Transition.Child>

                {/* mobile sidebar */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto p-6 pb-4 ring-1 ring-white/10 bg-white dark:bg-neutral-900">
                  <NavList
                    navOptions={navOptions}
                    active={active}
                    setActive={handleNavClick}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* desktop sidebar */}
      <div className="hidden lg:absolute lg:top-[3.9rem] lg:flex lg:w-72 lg:flex-col h-fit">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-neutral-900 px-6 py-4">
          <NavList
            navOptions={navOptions}
            active={active}
            setActive={handleNavClick}
          />
        </div>
      </div>

      <div className="flex lg:hidden h-16 shrink-0 items-center justify-between gap-x-4 border-b border-neutral-200 bg-white dark:border-neutral-700 dark:bg-neutral-900 px-4 shadow-sm sm:gap-x-6 sm:px-6">
        <div className="flex justify-start items-center gap-x-4">
          <button
            type="button"
            className="flex items-center -m-2.5 p-2.5 text-neutral-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <FontAwesomeIcon
              icon={faBars}
              className="h-6 w-6"
              aria-hidden="true"
            />
          </button>
          {active && (
            <h2>
              <FontAwesomeIcon
                icon={active.icon}
                className="mr-3"
                aria-hidden="true"
              />
              {active.label}
            </h2>
          )}
        </div>
        {location.pathname.split('/').length >= 5 && (
          <button
            onClick={goToParent}
            className="flex items-center text-neutral-500"
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="h-6 w-6"
              aria-hidden="true"
            />
          </button>
        )}
      </div>
    </>
  )
}

const NavList = ({ navOptions, active, setActive }) => {
  const { user } = useContext(AuthenticationContext)
  const [options, setOptions] = useState(navOptions)
  const navigate = useNavigate()

  const handleClick = (option) => {
    setActive(option)
    navigate(option.title)
  }

  useEffect(() => {
    setOptions(navOptions)
  }, [navOptions])

  return (
    <nav className="flex h-full w-full flex-col gap-2">
      {options.map((opt) => {
        return (
          (opt.enabled === undefined || opt.enabled === true) &&
          opt.access[user.role.role] === true && (
            <button
              key={opt.title}
              className={`flex items-center whitespace-nowrap p-2 font-bold text-neutral-700 dark:text-white transition-all hover:bg-neutral-200 active:bg-neutral-300 dark:hover:bg-neutral-700 dark:active:bg-neutral-600 rounded ${
                active &&
                active.title === opt.title &&
                'bg-neutral-300 dark:bg-neutral-600'
              }`}
              onClick={() => handleClick(opt)}
            >
              <FontAwesomeIcon className="mr-3 w-5 h-5" icon={opt.icon} />
              {opt.label}
            </button>
          )
        )
      })}
    </nav>
  )
}

NavList.propTypes = {
  navOptions: PropTypes.array,
  active: PropTypes.object,
  setActive: PropTypes.func
}

export default Nav
