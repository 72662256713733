import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthenticationContext } from '../../../context/authenticationContext'
import { putUser } from '../../../utils/APIs/users'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons'
import TermsAndConditionsText from '../../Utilities/TermsAndConditionsText'

const TermsAndConditionsForm = ({
  disable,
  turnstile,
  turnstileModal,
  setTurnstileModal,
  shouldNavigate = true
}) => {
  const { user, updateUser } = useContext(AuthenticationContext)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({
    state: false,
    message: ''
  })
  const [agree, setAgree] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()
  const from = location.state?.from?.pathname || '/'

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    setError({
      state: false,
      message: ''
    })

    const response = await putUser(user._id, 'onboarding.terms', agree)

    if (response && response.status === 200) {
      updateUser(response.data)
      if (shouldNavigate) {
        navigate(from, { replace: true })
      }
    } else {
      setError({
        state: true,
        message: response.data
      })
    }
    setLoading(false)
  }

  const handleTurnstileModal = () => {
    setTurnstileModal(!turnstileModal)
  }

  return (
    <>
      <form onSubmit={handleSubmit} autoComplete="off">
        {!turnstile && (
          <h1 className="mt-2 mb-4 text-center font-bold tracking-wide">
            Terms and Conditions
          </h1>
        )}
        <div>
          {!turnstile && (
            <div className="h-96 border dark:border-neutral-700 p-4 flex overflow-y-scroll">
              <TermsAndConditionsText />
            </div>
          )}
          {!disable && (
            <>
              <div
                className={`flex gap-2 items-start ${
                  turnstile ? 'mb-2' : 'my-4'
                }`}
              >
                <label className="font-medium flex items-start">
                  <input
                    className="mr-2 mt-1.5"
                    type="checkbox"
                    checked={agree}
                    onChange={() => setAgree(!agree)}
                    required
                  />
                  <p>
                    I have read and agree to the{' '}
                    {turnstile ? (
                      <a
                        onClick={() => handleTurnstileModal()}
                        className="link"
                      >
                        <strong>
                          Terms and Conditions
                          <FontAwesomeIcon
                            icon={faArrowUpRightFromSquare}
                            className="ml-1 w-3 h-3"
                          />
                        </strong>
                      </a>
                    ) : (
                      <strong>Terms and Conditions</strong>
                    )}
                  </p>
                </label>
              </div>
              <div className="flex flex-col gap-2">
                {error.state && (
                  <p className="text-red-700 dark:text-red-400">
                    {error.message}
                  </p>
                )}
                <input
                  type="submit"
                  className="primary-button"
                  value={loading ? 'Validating...' : 'Submit'}
                />
              </div>
            </>
          )}
        </div>
      </form>
    </>
  )
}

TermsAndConditionsForm.propTypes = {
  disable: PropTypes.bool
}

export default TermsAndConditionsForm
