import {
  ERROR,
  LOADING,
  RESET,
  INITIAL_LOAD,
  LOAD_MORE,
  ADD_MESSAGE,
  EDIT_MESSAGE,
  DELETE_MESSAGE,
  CHANGE_CHANNEL
} from '../constants/actions/messageListActions'
import { MEMBERS } from '../constants/chatRooms'

export const initialState = {
  error: false,
  loading: true,
  messages: [],
  lastPost: null,
  hasMore: false,
  channel: MEMBERS
}

const handleSetMessages = (state, payload, initialLoad) => {
  if (payload.length === 0) {
    return {
      ...state,
      error: false,
      loading: false,
      hasMore: false
    }
  } else {
    return {
      ...state,
      error: false,
      loading: false,
      lastPost:
        payload[payload.length - 1].postdate || // public + trades
        payload[payload.length - 1].postDate, // private
      messages: initialLoad ? [...payload] : [...state.messages, ...payload],
      hasMore: payload.length > 0
    }
  }
}

export function messageListReducer(state, action) {
  switch (action.type) {
    case RESET:
      return initialState

    case ERROR:
      return {
        ...state,
        error: action.payload
      }

    case LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case CHANGE_CHANNEL:
      return { ...initialState, channel: action.payload }

    case INITIAL_LOAD:
      return handleSetMessages(state, action.payload, true)

    case LOAD_MORE:
      return handleSetMessages(state, action.payload, false)

    case ADD_MESSAGE:
      return {
        ...state,
        messages: [action.payload, ...state.messages]
      }

    case DELETE_MESSAGE:
      return {
        ...state,
        messages: [
          ...state.messages.filter((mes) => mes._id !== action.payload._id)
        ]
      }

    case EDIT_MESSAGE:
      return {
        ...state,
        messages: state.messages.map(function (mes) {
          return mes._id === action.payload._id ? action.payload : mes
        })
      }

    default:
      return state
  }
}
