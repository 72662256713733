import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-solid-svg-icons'

const Accordion = ({ title, children, startOpen }) => {
  const [isOpen, setIsOpen] = useState(startOpen)

  return (
    <div className="max-h-[50%] overflow-y-auto">
      <button
        className={`${
          isOpen
            ? 'rounded-t-md text-brand-primary-600 bg-neutral-200 dark:text-brand-primary-400 dark:bg-neutral-800'
            : 'rounded-md text-neutral-800 bg-neutral-100 dark:text-neutral-50 dark:bg-neutral-800'
        } ' flex items-center justify-between w-full px-2 py-1 text-left hover:bg-neutral-700'`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-sm font-semibold">{title}</span>
        <FontAwesomeIcon
          icon={isOpen ? faChevronDown : faChevronRight}
          className="w-5 h-5"
        />
      </button>
      {isOpen && (
        <div className="p-2 bg-white border dark:bg-neutral-900 rounded-b-md dark:border-neutral-800">
          {children}
        </div>
      )}
    </div>
  )
}

export default Accordion
