import axios from 'axios'
import { API } from '../ENV'

export const postSetting = async (setting, value) => {
  try {
    const response = await axios.post(
      `${API}/settings`,
      {
        setting,
        value
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getSetting = async (id) => {
  try {
    const response = await axios.get(`${API}/settings/${id}`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const getAllSettings = async () => {
  try {
    const response = await axios.get(`${API}/settings`)
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const putSetting = async (id, value) => {
  try {
    const response = await axios.put(
      `${API}/settings/${id}`,
      {
        value
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}

export const putGoLive = async (status, title) => {
  try {
    const response = await axios.put(
      `${API}/settings/go-live`,
      {
        status,
        title
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`
        }
      }
    )
    return response
  } catch (error) {
    console.log(error.response)
    return error.response
  }
}
