import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HeadquartersButton } from '../../components/Buttons/HeadquartersButton'
import { faBuilding } from '@fortawesome/pro-solid-svg-icons'
import * as SETTINGS from '../../constants/settings'
import { SiteConfigContext } from '../../context/siteConfigContext'

const Information = () => {
  const { [SETTINGS.HEADQUARTERS.key]: headquarters } =
    useContext(SiteConfigContext)

  return (
    <>
      <h1 className="flex items-center gap-4">
        <FontAwesomeIcon icon={faBuilding} />
        Headquarters
      </h1>
      <div className="flex flex-col max-w-full gap-4">
        {headquarters.map((HQ, index) => {
          return (
            <HeadquartersButton
              key={index}
              title={HQ.title}
              link={HQ.link}
              content={HQ.content}
              icon={HQ.icon}
              primary={HQ.primary}
            />
          )
        })}
        <HeadquartersButton
          title={'Terms & Conditions'}
          link={'/terms-and-conditions'}
          content={'Review the user agreement.'}
          icon={null}
          primary={false}
        />
      </div>
    </>
  )
}

export default Information
