import { useContext, useEffect, useReducer } from 'react'
import {
  getAnnouncements,
  markAnnouncementAsRead
} from '../../utils/APIs/announcements'
import { SocketContext } from '../../context/socketContext'
import { ANNOUNCEMENT_EVENTS } from '../../constants/sockets'
import {
  initialState,
  reducer
} from '../../reducers/announcementsProviderReducer'

export default function AnnouncementProvider({ limit, children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { announcementsSocket: socket } = useContext(SocketContext)
  // const { user } = useContext(AuthenticationContext)

  useEffect(() => {
    if (!socket) return
    socket.on(ANNOUNCEMENT_EVENTS.POST, handlePostAnnouncement)
    socket.on(ANNOUNCEMENT_EVENTS.EDIT, handlePutAnnouncement)
    socket.on(ANNOUNCEMENT_EVENTS.DELETE, handleDeleteAnnouncement)
    socket.on(ANNOUNCEMENT_EVENTS.READ, handleReadAnnouncement)
    socket.on(ANNOUNCEMENT_EVENTS.READ_ALL, handleReadAllAnnouncements)
    return () => {
      socket.off(ANNOUNCEMENT_EVENTS.POST, handlePostAnnouncement)
      socket.off(ANNOUNCEMENT_EVENTS.EDIT, handlePutAnnouncement)
      socket.off(ANNOUNCEMENT_EVENTS.DELETE, handleDeleteAnnouncement)
      socket.off(ANNOUNCEMENT_EVENTS.READ, handleReadAnnouncement)
      socket.off(ANNOUNCEMENT_EVENTS.READ_ALL, handleReadAllAnnouncements)
    }
  }, [socket])

  useEffect(() => {
    dispatch({ type: 'LOADING' })
    fetchAnnouncements()
  }, [])

  const fetchAnnouncements = async () => {
    if (!state.hasMore) return
    try {
      const response = await getAnnouncements({
        loadMore: state.loadMore,
        limit: limit || null
      })
      if (response.status !== 200) {
        throw new Error(
          response.data.errors[0].message || 'Error fetching announcements'
        )
      }
      dispatch({
        type: 'FETCH_ANNOUNCEMENT_EVENTS',
        payload: response.data.announcements
      })
    } catch (error) {
      dispatch({ type: 'ERROR', payload: error.message })
    }
  }

  const markRead = async (announcement) => {
    const response = await markAnnouncementAsRead(announcement._id)
    if (!response.status === 200) {
      console.error('Error marking announcement as read')
    }
  }

  const handlePostAnnouncement = (data) => {
    dispatch({ type: 'ANNOUNCEMENT_POST', payload: data })
  }

  const handlePutAnnouncement = (data) => {
    dispatch({ type: 'ANNOUNCEMENT_PUT', payload: data })
  }

  const handleReadAnnouncement = (data) => {
    dispatch({ type: 'ANNOUNCEMENT_PUT', payload: data })
  }

  const handleReadAllAnnouncements = (data) => {
    dispatch({ type: 'ALL_READ', payload: data })
  }

  const handleDeleteAnnouncement = (data) => {
    console.log('handleDeleteAnnouncement', data)
    dispatch({ type: 'ANNOUNCEMENT_DELETE', payload: data })
  }

  return children({ ...state, fetchAnnouncements, markRead })
}
