import React from 'react'

const LoginCommandCenter = (turnstile) => {
  const handleOnClick = () => {
    const { REACT_APP_LOGIN_PORTAL_URL, REACT_APP_PRODUCT_CODE } = process.env
    window.location.href = `${REACT_APP_LOGIN_PORTAL_URL}?from=${REACT_APP_PRODUCT_CODE}`
  }

  return (
    <div>
      <div className={turnstile ? 'p-2' : 'p-2 md:p-4'}>
        <div className="flex flex-col">
          <button className="primary-button text-xl" onClick={handleOnClick}>
            Sign In
          </button>
        </div>
      </div>
    </div>
  )
}

export default LoginCommandCenter
